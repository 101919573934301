import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, FieldArray, FormikErrors, FormikTouched } from "formik";
import { object, string, boolean, array } from "yup";
import { Listbox } from "@headlessui/react";

import Http from "services/http";
import Category from "services/category";
import Language from "services/language";
import Response from "services/response";
import Structures from "services/structures";
import Image from "components/image";
import Spinner from "components/spinner";
import { toast } from "react-toastify";

import UString from "utilities/string";
import UFile from "utilities/file";
import useCache from "services/useCache";

import { get } from "lodash";
import { useTranslation } from 'react-i18next';

interface ILocale {
  lang: string;
  title: string;
}

export default function () {
  const { id } = useParams();
  const navigate = useNavigate();

  const [language, setLanguage] = useState<any>();
  const { t } = useTranslation();
  const [lang, setLang] = useState<any>({
    locale: [
      {
        lang: "tr", // TODO: must be branch's default language
        title: "",
      },
    ],
  });

  const [category, setCategory] = useState<any>(
    !id
      ? {
          title: "",
          image: "",
          is_sub_category: false,
          parent_category: "",
        }
      : undefined
  );

  const [formFileError, setFormFileError] = useState<any>(null);

  const [imageUrl, setImageUrl] = useState<any>();

  const findLanguage = () => {
    let lang = language?.items?.find((item: any) => item?.itemId == id);
    return lang;
  };
  const CategoryService = new Category(useContext(Http.Context)!);
  const Fetch = new Response();
  const Save = new Response();
  const CFetch = useCache(CategoryService);

  const LService = new Language(useContext(Http.Context)!);
  const LFetch = new Response<any[]>();

  const Form = useRef<any>();

  const fileInputChange: any = (event: any) => {
    if (event.target.files[0].size > 75000) return setFormFileError(true);
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function () {
      setImageUrl(reader.result)
      setCategory({ ...category, image: reader.result })
    };
  }

  let fileInputRef = useRef<any>();


  useEffect(() => {
    if (formFileError) {
      fileInputRef.current.value = null
      setFormFileError(false);
      setImageUrl(null)
      toast.error(t('max-40-kb'));
    };
  }, [formFileError]);

  useEffect(
    () => (!LFetch.data && LFetch.handle(LService.get()), undefined),
    [LFetch.data]
  );

  useEffect(() => Save.data, [Save.data]);

  useEffect(() => id && Fetch.data && setCategory(Fetch.data), [Fetch.data]);
  useEffect(
    () => (id && Fetch.handle(CategoryService.getById(id!)), undefined),
    [id]
  );
  useEffect(() => LFetch.data && setLanguage(LFetch.data), [LFetch.data]);
  useEffect(() => id && language && setLang(findLanguage()), [language]);

  useEffect(() => {
    if (Save.data && !Save.error) {
      toast.success(`${t('category')} ${id ? t('editing') : t('adding')} ${t('success')}.`);
      navigate("/category");
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      toast.error(
        `${t('category')} ${id ? t('editing') : t('adding')} ${t('failure')}. (${
          Save.error.message
        })`
      );
    }
  }, [Save.error]);

  return (
    <React.Fragment>
      <div className="header-top">
        <h2 className="text-xl font-medium pl-2">
          {t('category')} {id ? t('edit') : t('add')}
        </h2>
      </div>
      <div id="content">
        {category && (
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              category:
                category ||
                Object({
                  title: category?.title || String(),
                  image: category?.image || String(),
                  is_sub_category: category?.is_sub_category || Boolean(),
                  parent_category: category?.parent_category || String(),
                }),
              lang:
                lang ||
                Object({
                  locale: [
                    {
                      lang: "tr", // TODO: must be branch's default language
                      title: category.title,
                    },
                  ],
                }),
            }}
            validationSchema={object({
              category: object({
                title: string().required(t('required')!),
                image: string(),
                is_sub_category: boolean(),
              }).required(),
              lang: object({
                locale: array().of(
                  object({
                    lang: string().required(t('required')!),
                    title: string().required(t('required')!),
                  })
                ),
              }).required(),
            })}
            onSubmit={(values) => {
              const filtered = {
                ...values,
                category: Object.fromEntries(
                  Object.entries(values.category).filter(
                    ([key, value]) => value
                  )
                ),
              };
              Save.handle(
                id && category
                  ? CategoryService.update(id, filtered)
                  : CategoryService.create(filtered)
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form className="action" onSubmit={handleSubmit}>
                <div className="wrapper">
                  <div className="item">
                    <div className="w-full">
                      <div className="flex items-center space-x-6">
                        <div className="shrink-0 rounded-full border border-gray-200 p-0.5">
                          <Image
                            className="h-16 w-16 object-cover rounded-full"
                            src={imageUrl ||values?.category?.image}
                          />
                        </div>
                        <label className="block">
                          <span className="sr-only">{t('selected-category-picture')}</span>
                          <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            multiple={false}
                            onChange={fileInputChange}
                            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-ebony file:text-white hover:file:bg-opacity-90"
                            
                          />
                        </label>
                      </div>
                    </div>
                    <div className="w-full">
                      <FieldArray
                        name="lang.locale"
                        render={({ insert, remove }) => (
                          <>
                            <div className="flex flex-col space-y-4">
                              {values?.lang?.locale?.map(
                                (language: any, index: number) => {
                                  return (
                                    <div className="lang" key={index}>
                                      <label
                                        htmlFor={`lang.locale.[${index}].lang`}
                                      >
                                        Dil
                                      </label>
                                      <Listbox
                                        value={language.lang}
                                        onChange={(value) =>
                                          setFieldValue(
                                            `lang.locale.[${index}].lang`,
                                            value
                                          )
                                        }
                                      >
                                        <div className="relative mt-1">
                                          <Listbox.Button className="listbox-btn">
                                            <span className="listbox-title">
                                              {
                                                Structures.types.lang.find(
                                                  (role) =>
                                                    language.lang === role.value
                                                )?.title
                                              }
                                            </span>
                                            <span className="listbox-selector-icon">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-5 h-5 text-gray-400"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                            </span>
                                          </Listbox.Button>
                                          <Listbox.Options className="listbox-options">
                                            {Structures.types.lang.map(
                                              (value) => (
                                                <Listbox.Option
                                                  className={UString.concat_class_name(
                                                    language.lang ===
                                                      value.value
                                                      ? "bg-ebony text-white"
                                                      : "hover:bg-ebony hover:text-white",
                                                    "flex items-center cursor-pointer px-6 py-2"
                                                  )}
                                                  key={value.value}
                                                  value={value.value}
                                                >
                                                  <span>{value.title}</span>
                                                </Listbox.Option>
                                              )
                                            )}
                                          </Listbox.Options>
                                        </div>
                                      </Listbox>

                                      <div className="w-full mt-2">
                                        <label
                                          htmlFor={`lang.locale.[${index}].title`}
                                        >
                                          {t('category-title')}
                                        </label>
                                        <input
                                          type="text"
                                          name={`lang.locale.[${index}].title`}
                                          onChange={(value) => {
                                            if (index == 0) {
                                              setFieldValue(
                                                `category.title`,
                                                value.target.value
                                              );
                                            }
                                            handleChange(value);
                                          }}
                                          onBlur={handleBlur}
                                          className={UString.concat_class_name(
                                            get(
                                              errors,
                                              `lang.locale.[${index}].title`
                                            ) &&
                                              get(
                                                touched,
                                                `lang.locale.[${index}].title`
                                              )
                                              ? "!border-red-500 !ring-red-500"
                                              : undefined,
                                            "flex mt-1"
                                          )}
                                          placeholder="İçecekler"
                                          value={language?.title || String()}
                                        />
                                        {get(
                                          errors,
                                          `lang.locale.[${index}].title`
                                        ) &&
                                        get(
                                          touched,
                                          `lang.locale.[${index}].title`
                                        ) ? (
                                          <span className="error">
                                            *{" "}
                                            {get(
                                              errors,
                                              `lang.locale.[${index}].title`
                                            )}
                                          </span>
                                        ) : null}
                                      </div>

                                      {index != 0 && (
                                        <div className="w-full mt-2">
                                          <button
                                            className="classic-btn justify-center red w-full "
                                            type="button"
                                            onClick={() => {
                                              values.lang.locale.length > 1 &&
                                                remove(index);
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="h-5 w-5"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke="currentColor"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={1.5}
                                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  );
                                }
                              )}
                              <div className="w-full pt-4 border-t border-gray-200">
                                <button
                                  className="w-full xl:ml-auto classic-btn justify-center"
                                  type="button"
                                  onClick={insert.bind(
                                    null,
                                    values?.lang?.locale?.length + 1,
                                    {
                                      lang: "en",
                                      title: String(),
                                    } as ILocale
                                  )}
                                >
                                  Dil Ekle
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      />
                    </div>
                    <div className="relative w-full">
                      <label>{t('parent-category')}</label>
                      {CFetch.data ? (
                        <Listbox
                          value={values?.category?.parent_category}
                          onChange={(value) => (
                            setFieldValue("category.parent_category", value),
                            setFieldValue(
                              "category.is_sub_category",
                              Boolean(value)
                            )
                          )}
                        >
                          <div className="relative mt-1">
                            <Listbox.Button className="listbox-btn">
                              <span className="listbox-title">
                                {CFetch.data.find(
                                  (category: any) =>
                                    category.id ===
                                    values?.category?.parent_category
                                )?.title || "Yok"}
                              </span>
                              <span className="listbox-selector-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5 text-gray-400"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            </Listbox.Button>
                            <Listbox.Options className="listbox-options">
                              <Listbox.Option
                                className={UString.concat_class_name(
                                  values?.category?.parent_category === null
                                    ? "bg-ebony text-white"
                                    : "hover:bg-ebony hover:text-white",
                                  "flex items-center cursor-pointer px-6 py-2"
                                )}
                                value={null}
                              >
                                <span>{t('none')}</span>
                              </Listbox.Option>
                              {CFetch.data &&
                                CFetch.data.map((category: any) => (

                                  id!==
                                  category.id?
                                
                                  <Listbox.Option
                                    className={UString.concat_class_name(
                                      values?.category?.parent_category ===
                                        category.id
                                        ? "bg-ebony text-white"
                                        : "hover:bg-ebony hover:text-white",
                                      "flex items-center cursor-pointer px-6 py-2"
                                    )}
                                    key={category.id}
                                    value={category.id}
                                  >
                                    <span>{category.title}</span>
                                  </Listbox.Option>:null
                                ))}
                            </Listbox.Options>
                          </div>
                        </Listbox>
                      ) : (
                        <div className="classic-btn w-full min-h-[38px] mt-1">
                          <Spinner className="w-5 m-auto" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
      <div className="header-bottom">
        <button
          type="submit"
          disabled={Save.loading}
          className="ml-auto classic-btn"
          onClick={() => Form.current.handleSubmit()}
        >
          {Save.loading ? <Spinner className="h-5 m-auto" /> : t('save')}
        </button>
      </div>
    </React.Fragment>
  );
}
