import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Turkish from '../libary/languages/tr.json';
import English from '../libary/languages/en.json';
import Arabia from '../libary/languages/ar.json';
i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'tr',
        saveMissing: false,
        resources: {
            tr: {
                translation: Turkish
            },
            en: {
                translation: English
            },
            ar: {
                translation: Arabia
            }
        }
    })