import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import { Transition } from "@headlessui/react";

const ArrowIcon = ({ rotate }: any): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${rotate} transition duration-300 select-none h-5 w-5`}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export interface IItem {
  label: string;
  url: string;
  icon: JSX.Element;
  subMenu?: IItem[];
}

export default function MenuItem({ data }: { data: IItem }) {
  const [isOpen, setIsOpen] = useState(false);
  let i = data.subMenu?.length!;

  return (
    <li>
      <div className="flex items-center">
        <NavLink className="menu-item" to={data.url}>
          {data.icon} <span>{data.label}</span>
        </NavLink>
        {data.subMenu && (
          <div
            onClick={() => setIsOpen(!isOpen)}
            className={`
                                ${isOpen ? "bg-white bg-opacity-5" : ""}
                                ml-2 px-1 py-3 rounded-md cursor-pointer hover:bg-white hover:bg-opacity-5
                            `}
          >
            <ArrowIcon rotate={isOpen ? "rotate-0" : "rotate-180"} />
          </div>
        )}
      </div>

      {data.subMenu && (
        <ul
          className="pl-6 space-y-1 overflow-hidden"
          style={{
            paddingTop: isOpen ? "0.25rem" : "0",
            transition: "all 300ms linear",
            maxHeight: isOpen ? i * 2.75 + (i - 1) / 4 + 1 + "rem" : "0",
            opacity: isOpen ? "100%" : "0",
          }}
        >
          {data.subMenu?.map((item: IItem) => {
            return <MenuItem data={item} key={item.url} />;
          })}
        </ul>
      )}
    </li>
  );
}
