import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import Http from "services/http";
import Product from "services/product";
import Response from "services/response";
import Category from "services/category";

import Table from "components/table";
import Image from "components/image";
import Modal from "components/modal";
import Loading from "components/loading";
import NetworkError from "components/network-error";
import Spinner from "components/spinner";
import { Switch } from "@headlessui/react";
import useCache from "services/useCache";
import { parse } from "json2csv";
import fileDownload from "js-file-download";
import Papa from "papaparse";
import { useTranslation } from "react-i18next";
export interface QrEnabled {
  id: string;
  enabledStatus: boolean;
}

export interface Price {
  order_type: number[];
  _id: string;
  price_name: string;
  currency: string;
  amount: number;
  vat_rate: number;
  price: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IProduct {
  _id: string;
  active_list: number[];
  options: any[];
  favorite: boolean;
  opportunity: boolean;
  title: string;
  description: string;
  category: string;
  image: string;
  start_time: string;
  end_time: string;
  stock_code: string;
  sale_type: number;
  prices: Price[];
  branch: string;
  createdAt: Date;
  updatedAt: Date;
  slug: string;
  __v: number;
  id: string;
}

import { toast } from "react-toastify";

import { useSWRConfig } from "swr";
import Swal from "sweetalert2";
import axios from "axios";
import { Stack } from "@mui/material";

export default function () {
  const ProductService = new Product(useContext(Http.Context)!);
  //const Products = new Response();
  const UpdateProductResponse = new Response();
  const Delete = new Response();
  const CService = new Category(useContext(Http.Context)!);
  const CFetch = useCache(CService);
  const { t } = useTranslation();

  const [modal, setModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [product, setProduct] = useState<any | null>(null);
  const [query, setQuery] = useState(localStorage.getItem("query") ?? "");
  const [category, setCategory] = useState(
    localStorage.getItem("category") ?? "All"
  );
  const [enabled, setEnabled] = useState<QrEnabled[]>([
    { id: "", enabledStatus: false },
  ]);
  const fileInputRef: any = useRef();
  const { mutate } = useSWRConfig();
  const products = useCache(ProductService);
  const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
  const refreshToken = localStorage.getItem(
    process.env.REACT_APP_REFRESH_TOKEN_NAME!
  );
  const [permission, setPermission] = useState(false);
  let [categoryList1, setcategoryList1] = useState(new Array());

  useEffect(() => {
    let getBranch = async () => {
      let branch: any = await axios.get("/manager/branches", {
        baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
        headers: {
          Authorization: `Bearer ${token}`,
          "x-refresh": refreshToken!,
        },
      });
      if (branch.data[0].productPermission) setPermission(true);
    };
    getBranch();
  }, []);

  useEffect(() => {
    categoryList1 = [];
    CFetch?.data?.map((categories: any) =>
      category === categories.parent_category
        ? categoryList1.push(categories)
        : null
    );
    setcategoryList1(categoryList1);
    return () => {};
  }, []);

  useEffect(() => {
    if (Delete.data && !Delete.error && !Delete.loading) {
      let newData = products.data.filter((d: any) => d.id !== product.id);
      mutate(ProductService.endpoint, newData, {
        optimisticData: newData,
        rollbackOnError: true,
      });
      setModal(false);
    } else if (Delete.error && !Delete.loading) {
      toast.error(Delete.error?.response?.data);
      setModal(false);
    }
  }, [Delete.data, Delete.loading]);

  useEffect(() => setModal(Boolean(product)), [product]);
  useEffect(
    () => (
      !modal && setTimeout(() => (setProduct(null), (Delete.data = null)), 200),
      undefined
    ),
    [modal]
  );

  const isEnabled = (product: IProduct) => {
    let found = product.active_list.find((value: number) => value == 2);
    if (found == 2) {
      return true;
    } else {
      return false;
    }
  };
  const handleChecked = (product: IProduct): QrEnabled => {
    let newItem: QrEnabled = {
      id: product.id,
      enabledStatus: isEnabled(product),
    };
    let existItem = enabled.find((value) => value.id == newItem.id);
    if (existItem) {
      return existItem;
    } else {
      setEnabled((enabled) => [...enabled, newItem]);
      return newItem;
    }
  };

  const handleChangeEnabled = (product: IProduct) => {
    setEnabled(
      enabled.map((value) => {
        if (value.id == product.id) {
          value.enabledStatus = !value.enabledStatus;
          return value;
        } else {
          return value;
        }
      })
    );
  };

  const updateProduct = (product: IProduct) => {
    UpdateProductResponse.handle(
      ProductService.updateQr(product.id, !isEnabled(product))
    );
  };

  const exportProducts = () => {
    let dontShowInfo = localStorage.getItem("exportProductInfo");
    if (dontShowInfo !== "true") {
      Swal.fire({
        title: `<strong>${t("information")}</strong>`,
        icon: "info",
        html: `${t("export-product-text")} <br /> ${t(
          "export-product-text2"
        )} <br/> <i>[{name:"price 1",price:150,amount:1},{name: "price 2", price:200, amount: 2}]</i> <br/>`,
        showConfirmButton: true,
        confirmButtonText: ` ${t("understand")}`,
        input: "checkbox",
        inputValue: 0,
        inputPlaceholder: `${t("dont-show-again")}`,
        inputValidator: (result: any) => {
          if (result) {
            localStorage.setItem("exportProductInfo", "true");
            return null;
          } else {
            return null;
          }
        },
      }).then((result: any) => {
        if (result.isConfirmed) {
          let product: any[] = products.data;
          product.map(
            (p: any) =>
              (p.prices = p.prices.map((price: any) => {
                return {
                  name: price.priceName,
                  price: price.price,
                  amount: price.amount,
                };
              }))
          );
          let fields = [
            "id",
            "title",
            "prices",
            "description",
            "favorite",
            "stock_code",
          ];
          let opts = { fields, withBOM: true };
          const csv = parse(product, opts);
          fileDownload(csv, "products.csv");
          return;
        }
      });
    } else {
      let product: any[] = products.data;
      product.map(
        (p: any) =>
          (p.prices = p.prices.map((price: any) => {
            return {
              priceName: price.priceName,
              price: price.price,
              amount: price.amount,
              order_type: price.order_type,
              currency: price.currency,
            };
          }))
      );
      let fields = [
        "id",
        "title",
        "prices",
        "description",
        "favorite",
        "stock_code",
      ];
      let opts = { fields, withBOM: true };
      const csv = parse(product, opts);
      fileDownload(csv, "products.csv");
    }
  };

  const importProducts = (event: any) => {
    const csvFile = event.target.files[0];
    if (csvFile) {
      Papa.parse(csvFile, {
        complete: function (results) {
          results.data = results.data.filter((p: any) => p.id);
          results.data.map(
            (product: any) => (product.prices = JSON.parse(product.prices))
          );
          ProductService.importProducts(results.data);
        },
        header: true,
      });
    }
  };

  return (
    <React.Fragment>
      <Modal
        onClose={() => !Delete.loading && setModal(false)}
        state={[modal, setModal]}
      >
        <React.Fragment>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 p-0.5">
                <Image
                  src={product?.image}
                  className="w-full h-full rounded-full object-cover"
                />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {product?.title}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {t("delete-product-question")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-actions">
            <button
              disabled={Delete.data || Delete.loading}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => Delete.handle(ProductService.delete(product.id))}
            >
              {Delete.data ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : Delete.loading ? (
                <Spinner className="h-5 m-auto" />
              ) : (
                <span>{t("delete")}</span>
              )}
            </button>
            <button
              disabled={Delete.loading}
              type="button"
              className="mt-3 w-full classic-btn justify-center sm:mt-0 sm:ml-3 sm:w-auto"
              onClick={setModal.bind(null, false)}
            >
              {t("cancel")}
            </button>
          </div>
        </React.Fragment>
      </Modal>

      <div className="header-top">
        {!permission ? null : (
          <button
            className="to-gray-btn"
            onClick={() => fileInputRef.current.click()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 xl:mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="hidden xl:block">{t("import")}</span>
            <input
              type="file"
              accept=".csv"
              onChange={(e: any) => importProducts(e)}
              multiple={false}
              ref={fileInputRef}
              hidden
            />
          </button>
        )}
        <button className="to-gray-btn" onClick={() => exportProducts()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 xl:mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
          <span className="hidden xl:block">{t("export")}</span>
        </button>
        <div className="relative text-gray-600 px-10">
          <input
            type="search"
            name="search"
            className="border-2 border-gray-400 bg-white h-10 pr-8 rounded-lg text-sm focus:border-ebony focus:bg-white focus:ring-0"
            placeholder={t("search")!}
            value={query}
            onChange={(event) => {
              setQuery(event.target.value);
              localStorage.setItem("query", event.target.value);
            }}
          />
        </div>
        <Stack direction="row" spacing={1}>
          <div className="relative">
            <select
              style={{ width: "10vw" }}
              value={category}
              onChange={(e) => {
                categoryList1 = [];
                CFetch?.data?.map((categories: any) =>
                  e.target.value === categories.parent_category
                    ? categoryList1.push(categories)
                    : null
                );
                setcategoryList1(categoryList1);
                setCategory(e.target.value);
                localStorage.setItem("category", e.target.value);
              }}
              className="
              w-full
              mt-1
              rounded
              bg-gray
              focus:border-ebony focus:bg-white focus:ring-0"
              aria-label="Filter Product By Category"
            >
              <option value="All" className="hover:bg-ebony hover:text-white">
                {t("categories")}
              </option>
              {CFetch?.data?.map((categories: any) => (
                <option
                  key={categories.id}
                  className="hover:bg-ebony hover:text-white"
                  value={categories.id}
                >
                  {categories.title}
                </option>
              ))}
            </select>
          </div>
          {!permission ? null : (
            <Link
              to="/product/action"
              className="flex items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium ml-auto"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
              <span>{t("add-product")}</span>
            </Link>
          )}
          <Link
            className="flex items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium ml-auto"
            to="/product/rank"
          >
            <svg
              style={{ marginLeft: 3, marginRight: 3 }}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#ffffff"
              stroke-width="2"
              stroke-linecap="square"
              stroke-linejoin="arcs"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
            <span>{t("edit-rank")}</span>
          </Link>
        </Stack>
      </div>
      <div id="content">
        <Loading done={Boolean(products?.data)} />
        {!products?.error ? (
          <NetworkError
            error={products?.error}
            retry={() => {
              mutate(ProductService.endpoint);
            }}
          />
        ) : null}
        {products?.data && !products?.error && (
          <Table
            data={{
              head: [
                { element: t("product"), props: { scope: "col" } },
                { element: t("price"), props: { scope: "col" } },
                { element: t("created-at"), props: { scope: "col" } },
                {
                  element: t("actions"),
                  props: { scope: "col" },
                  screenReader: true,
                },
              ],

              body: products.data
                .filter((product: any) => {
                  if (
                    (category === "All" ||
                      product.category === category ||
                      categoryList1.find((i) => i.id === product.category)) &&
                    (query === "" ||
                      product.title
                        .toUpperCase()
                        .includes(query.toUpperCase()) ||
                      product.title.toLowerCase().includes(query.toLowerCase()))
                  ) {
                    return product;
                  }
                })
                .map((product: any) => ({
                  id: product.id,
                  elements: [
                    {
                      element: (
                        <div className="flex">
                          <div className="flex-shrink-0 h-12 w-12 rounded-full border border-gray-300 p-0.5">
                            <Image
                              className="h-full w-full rounded-full object-cover"
                              src={product.image}
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm-left font-medium text-gray-900">
                              {product.title}
                            </div>
                            <div
                              style={{
                                width: "30vw",
                                fontSize: "0.8vw",
                                whiteSpace: "normal",
                              }}
                              className="text-sm-left text-gray-500 text-truncate text-wrap"
                            >
                              <span className="text-truncate">
                                {product.description}
                              </span>
                            </div>
                          </div>
                        </div>
                      ),
                    },
                    {
                      element: `${
                        product.prices[0] != null
                          ? product.prices[0].price
                          : null
                      } ${
                        product.prices[0] != null
                          ? product.prices[0].currency
                          : null
                      }`,
                      props: { className: "text-left text-sm text-gray-900" },
                    },
                    {
                      element: new Date(product.createdAt).toLocaleDateString(
                        "tr-TR", //TODO
                        {
                          minute: "numeric",
                          hour: "numeric",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      ),
                      props: { className: "text-left text-sm text-gray-900" },
                    },
                    {
                      element: (
                        <>
                          <Switch
                            checked={handleChecked(product).enabledStatus}
                            onChange={() => {
                              handleChangeEnabled(product);
                              updateProduct(product);
                            }}
                            className={`${
                              handleChecked(product).enabledStatus
                                ? "bg-indigo-600"
                                : "bg-slate-500"
                            } relative inline-flex items-center h-6 rounded-full w-11`}
                          >
                            <span
                              className={`${
                                handleChecked(product).enabledStatus
                                  ? "translate-x-6"
                                  : "translate-x-1"
                              } inline-block w-4 h-4 transform bg-white rounded-full`}
                            />
                          </Switch>
                          {!permission ? null : (
                            <>
                              <Link
                                to={`/product/action/${product.id}`}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                {t("edit")}
                              </Link>
                              <button
                                onClick={setProduct.bind(null, product)}
                                className="text-red-600 hover:text-red-900"
                              >
                                {t("delete")}
                              </button>
                            </>
                          )}
                        </>
                      ),
                      props: {
                        className: "text-right text-sm font-medium space-x-6",
                      },
                    },
                  ],
                })),
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
