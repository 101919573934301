import Http from "services/http"
import Authenticate, { Service } from "services/authenticate"
import BranchService from "api/branch"
import { SocketContext, socket } from "socket.provider"

export default function ({ children }: { children: JSX.Element }): JSX.Element {
    const setCurrencySettings = async () => {
        let Service = new BranchService();
        let currencySettings = await Service.getCurrencySettings();
        localStorage.setItem("currencySettings", JSON.stringify(currencySettings));
    }

    setCurrencySettings();

    return (
        <Authenticate.Context.Provider value={Authenticate.createInstance(Service.getStoredTokens())}>
            <SocketContext.Provider value={socket}>
                <Authenticate.Context.Consumer>
                    {
                        value =>
                            <Http.Context.Provider value={Http.createInstance(value?.IsAuthenticated ? { Authorization: value.bearerToken() } : undefined)}>
                                {children}
                            </Http.Context.Provider>
                    }
                </Authenticate.Context.Consumer>
            </SocketContext.Provider>
        </Authenticate.Context.Provider>
    )
}
