import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Spinner from "components/spinner";

import Http from "services/http";
import Response from "services/response";
import Product from "services/product";
import Category from "services/category";
import Getir from "services/getir";
import Branch from "services/branch";
// import Trendyol from "services/trendyol"

import NetworkError from "components/network-error";
import Loading from "components/loading";
import Table from "components/table";
import Image from "components/image";
import { toast } from "react-toastify";
import useCache from "services/useCache";
import { useSWRConfig } from "swr";
import { useTranslation } from "react-i18next";

export default function () {
  const { name } = useParams();
  const { mutate } = useSWRConfig();
  const { t } = useTranslation();
  const ProductService = new Product(useContext(Http.Context)!);

  // const TrendyolService = new Trendyol(useContext(Http.Context)!)
  const GetirService = new Getir(useContext(Http.Context)!);
  const BranchService = new Branch(useContext(Http.Context)!);

  const IntegrationCategories = new Response();
  const IntegrationProducts = new Response();
  const MatchedProducts = new Response();
  const GetirToken = new Response();

  // const TrendyolToken = new Response();

  const Save = new Response();

  /* TRENDYOL INTEGRATION NOT YET IMPLEMENTED

    useEffect(() => {

      if(name === "trendyol" && !TrendyolToken.data){
        // TODO
      }
        
    }, [TrendyolToken.data]) 
    
    */

  const fetchIntegrationProducts = () => {
    if (!IntegrationProducts.data) {
      if (name === "getir") {
        IntegrationCategories.handle(GetirService.getCategories());
        IntegrationProducts.handle(GetirService.getProducts());
        MatchedProducts.handle(BranchService.getMatchedGetirProducts());
      }

      if (name === "trendyol") {
        toast.error(t("trendyol-integration-not-ready"));
        /*  TRENDYOL INTEGRATION NOT YET IMPLEMENTED

          IntegrationCategories.handle(TrendyolService.getCategories(TrendyolToken.data))
          IntegrationProducts.handle(TrendyolService.getProducts(TrendyolToken.data))
          MatchedProducts.handle(TrendyolService.getMatchedProducts())

          */
      }
    }
  };

  useEffect(() => {
    if (MatchedProducts.data) {
      let pairsFromDb: string[][] = [];
      let randomColors: string[] = [];

      MatchedProducts.data.map((p: any) => {
        pairsFromDb.push([p._id, p.getirId]);
        randomColors.push(generateLightColorRgb());
      });

      setPairs(pairsFromDb);
      setColors(randomColors);
    }
  }, [MatchedProducts.data]);

  const handleSubmittingPairs = () => {
    const data: Object[] = [];
    pairs.map((pair) => {
      data.push({
        id: pair[0],
        getirId: pair[1],
      });
    });
    Save.handle(
      BranchService.saveMatchedGetirProducts({
        paired: data,
        unpaired: unpaired,
      })
    );
    setUnpaired([]);
    toast.success(t("saved-match-product"));
  };

  // Handle Filter
  const CategoryService = new Category(useContext(Http.Context)!);
  const PanelCategories = useCache(CategoryService);

  const [query, setQuery] = useState("");
  const [category, setCategory] = useState("All");
  const [query2, setQuery2] = useState("");
  const [category2, setCategory2] = useState("All");
  const [unpaired, setUnpaired]: any = useState([]);

  // Fetch panel products
  const PanelProducts = useCache(ProductService);

  const [pair, setPair] = useState<string[]>([]);
  // it should be initially a list of pairs from db
  const [pairs, setPairs] = useState<Array<string>[]>([]);
  const [colors, setColors] = useState<string[]>([]);

  const handleSelect = (productId: string, from: any) => {
    if (unpaired.find((p: any) => p == productId)) {
      setUnpaired((prev: any) => prev.filter((p: any) => p != productId));
    }
    let validLeft = true,
      validRight = true;
    pairs.map((pair) => {
      if (pair[0] == productId) {
        validLeft = false;
      }
      if (pair[1] == productId) {
        validRight = false;
      }
    });

    if (validLeft && from == "left") {
      if (pair[0] == productId) {
        setPair([]);
        let temp = [...colors];
        temp.splice(-1, 1);
        setColors(temp);
      } else {
        if (pair.length == 0) {
          let color = generateLightColorRgb();
          setColors([...colors, color]);
        }

        setPair([productId]);
      }
    }
    if (validRight && pair.length == 1 && from == "right") {
      setPair([...pair, productId]);
    }
  };

  useEffect(() => {
    if (pair.length == 2) {
      setPairs([...pairs, pair]);
      setPair([]);
    }
  }, [pair]);

  const removePair = (productId: string) => {
    let index = checkIfPaired(productId);
    if (index != -1) {
      setUnpaired((prev: any) => [...prev, pairs[index][0]]);
      let newPairs = [...pairs];
      newPairs.splice(index, 1);
      colors.splice(index, 1);
      setPairs(newPairs);
    }
  };

  const checkIfPaired = (productId: string) => {
    let paired = -1;
    let mergedPairs = [...pairs, [pair]];
    mergedPairs.map((pair, index) => {
      if (pair[1] == productId || pair[0] == productId) {
        paired = index;
      }
    });
    return paired;
  };

  function generateLightColorRgb() {
    const red = Math.floor(((1 + Math.random()) * 256) / 2);
    const green = Math.floor(((1 + Math.random()) * 256) / 2);
    const blue = Math.floor(((1 + Math.random()) * 256) / 2);
    return "rgba(" + red + ", " + green + ", " + blue + ", 0.75)";
  }

  return (
    <React.Fragment>
      <div className="header-top">
        <h2 className="text-xl font-medium pl-2">
          <span className="capitalize">
            {name} {t("match-products")}{" "}
          </span>
        </h2>
      </div>
      <div id="content" className="flex justify-between">
        <div className="overflow-y-auto w-1/2 border-r">
          <div className="py-4 px-6 flex gap-x-6 border-b">
            <input
              type="search"
              name="search"
              className="
                  rounded
                  pr-2
                  text-sm
                  focus:border-ebony focus:bg-gray-50 focus:ring-0"
              placeholder={t("search-from-panel")!}
              value={query}
              onChange={(event) => setQuery(event.target.value)}
            />
            <select
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              className="
                  rounded
                  text-sm
                  focus:border-ebony focus:bg-gray-50 focus:ring-0"
              aria-label="Filter Product By Category"
            >
              <option value="All" className="hover:bg-ebony hover:text-white">
                {t("categories")}
              </option>
              {PanelCategories.data?.map((categories: any) => (
                <option
                  key={categories.id}
                  className="hover:bg-ebony hover:text-white"
                  value={categories.id}
                >
                  {categories.title}
                </option>
              ))}
            </select>
          </div>
          <Loading done={PanelProducts.data} />
          {PanelProducts.error ? (
            <NetworkError
              error={PanelProducts.error}
              retry={() => {
                mutate(ProductService.endpoint);
              }}
            />
          ) : null}
          {PanelProducts.data && !PanelProducts.error && (
            <Table
              data={{
                head: [
                  { element: t("products-in-panel"), props: { scope: "col" } },
                  { element: t("price"), props: { scope: "col" } },
                ],

                body: PanelProducts.data
                  .filter((product: any) => {
                    if (
                      (category === "All" || product.category === category) &&
                      query == ""
                    ) {
                      return product;
                    } else if (
                      (category === "All" || product.category === category) &&
                      (product.title
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                        product.description
                          .toLowerCase()
                          .includes(query.toLowerCase()))
                    ) {
                      return product;
                    }
                  })
                  .map((product: any) => ({
                    id: product.id,
                    elements: [
                      {
                        element: (
                          <div
                            style={{
                              backgroundColor:
                                colors[checkIfPaired(product.id)],
                            }}
                            className={`${
                              checkIfPaired(product.id) == -1
                                ? "cursor-pointer"
                                : null
                            } select-none flex items-center -my-4 -mx-6 py-4 px-6`}
                            onClick={() => {
                              handleSelect(product.id, "left");
                            }}
                          >
                            <div className="flex-shrink-0 h-12 w-12 rounded-full border border-gray-300 p-0.5">
                              <Image
                                className="h-full w-full rounded-full object-cover"
                                src={product.image}
                              />
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">
                                {product.title}
                              </div>
                              <div className="text-sm text-gray-500">
                                {product.description}
                              </div>
                            </div>
                          </div>
                        ),
                      },
                      {
                        element: (
                          <div
                            style={{
                              backgroundColor:
                                colors[checkIfPaired(product.id)],
                            }}
                            className="select-none flex items-center -my-4 -mx-6 py-4 px-6"
                          >
                            <div className="h-12 flex items-center">
                              {product.prices[0].price}
                            </div>
                          </div>
                        ),
                      },
                    ],
                  })),
              }}
            />
          )}
        </div>
        <div className="w-1/2 overflow-y-auto">
          {IntegrationProducts.data && (
            <div className="py-4 px-6 flex justify-end gap-x-6 border-b">
              <input
                type="search"
                name="search"
                className="
                    rounded
                    pr-2
                    text-sm
                    placeholder:capitalize
                    focus:border-ebony focus:bg-gray-50 focus:ring-0"
                placeholder={`${name} ${t("search-from-x")}`}
                value={query2}
                onChange={(event) => setQuery2(event.target.value)}
              />
              <select
                value={category2}
                onChange={(e) => {
                  setCategory2(e.target.value);
                }}
                className="
                    rounded
                    text-sm
                    focus:border-ebony focus:bg-gray-50 focus:ring-0"
                aria-label="Filter Product By Category"
              >
                <option value="All" className="hover:bg-ebony hover:text-white">
                  {t("categories")}
                </option>
                {IntegrationCategories?.data?.map((categories: any) => (
                  <option
                    key={categories.id}
                    className="hover:bg-ebony hover:text-white"
                    value={categories.id}
                  >
                    {categories.title}
                  </option>
                ))}
              </select>
            </div>
          )}
          {!IntegrationProducts.data && (
            <div className="grid place-items-center h-full">
              <button
                className="classic-btn capitalize"
                onClick={fetchIntegrationProducts}
              >
                {name} {t("get-products")}
              </button>
            </div>
          )}
          <Loading done={!IntegrationProducts.loading} />
          {!IntegrationProducts.loading ? (
            <NetworkError
              error={IntegrationProducts.error}
              retry={() => {
                fetchIntegrationProducts();
              }}
            />
          ) : null}
          {IntegrationProducts.data &&
            !IntegrationProducts.loading &&
            !IntegrationProducts.error && (
              <Table
                data={{
                  head: [
                    {
                      element: name + " " + t("_products"),
                      props: { scope: "col" },
                    },
                    { element: t("price"), props: { scope: "col" } },
                    { element: t("matched"), props: { scope: "col" } },
                  ],

                  // Getir ürünleri henüz hazır olmadığından dummy data kullanıldı.
                  body: IntegrationProducts.data
                    .filter((product: any) => {
                      if (
                        (category2 === "All" ||
                          product.category === category2) &&
                        query2 == ""
                      ) {
                        return product;
                      } else if (
                        (category2 === "All" ||
                          product.category === category2) &&
                        (product.name
                          .toLowerCase()
                          .includes(query2.toLowerCase()) ||
                          product.description
                            .toLowerCase()
                            .includes(query2.toLowerCase()))
                      ) {
                        return product;
                      }
                    })
                    .map((product: any) => ({
                      id: product.id,
                      elements: [
                        {
                          element: (
                            <div
                              style={{
                                backgroundColor:
                                  colors[checkIfPaired(product.id)],
                              }}
                              className={`${
                                checkIfPaired(product.id) == -1
                                  ? "cursor-pointer"
                                  : null
                              } select-none flex items-center -my-4 -mx-6 py-4 px-6`}
                              onClick={() => {
                                handleSelect(product.id, "right");
                              }}
                            >
                              <div className="flex-shrink-0 h-12 w-12 rounded-full border border-gray-300 p-0.5">
                                <Image
                                  className="h-full w-full rounded-full object-cover"
                                  src={product.image}
                                />
                              </div>
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {product.name}
                                </div>
                                <div className="text-sm text-gray-500">
                                  {product.description}
                                </div>
                              </div>
                            </div>
                          ),
                        },
                        {
                          element: (
                            <div
                              style={{
                                backgroundColor:
                                  colors[checkIfPaired(product.id)],
                              }}
                              className="select-none flex items-center -my-4 -mx-6 py-4 px-6"
                            >
                              <div className="h-12 flex items-center">
                                {product.price}
                              </div>
                            </div>
                          ),
                        },
                        {
                          element: (
                            <div
                              className="-my-4 -mx-6 py-4 px-6"
                              style={{
                                backgroundColor:
                                  colors[checkIfPaired(product.id)],
                              }}
                            >
                              <button
                                disabled={checkIfPaired(product.id) == -1}
                                onClick={() => {
                                  removePair(product.id);
                                }}
                                className={`${
                                  checkIfPaired(product.id) == -1
                                    ? "opacity-0"
                                    : "opacity-1 cursor-pointer"
                                } flex px-4 justify-center rounded-md items-center h-12 text-white bg-orange-500 text-center text-xs font-semibold`}
                              >
                                {t("to-match")}
                                <br /> {t("remove")}
                              </button>
                            </div>
                          ),
                        },
                      ],
                    })),
                }}
              />
            )}
        </div>
      </div>

      <div className="header-bottom">
        <button
          type="submit"
          disabled={Save.loading || pairs.length == 0}
          className="ml-auto classic-btn"
          onClick={handleSubmittingPairs}
        >
          {
            /* Save.loading */ false ? (
              <Spinner className="h-5 m-auto" />
            ) : (
              t("save")
            )
          }
        </button>
      </div>
    </React.Fragment>
  );
}
