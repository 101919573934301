export interface IElement {
  element: React.ReactNode,
  props?: React.ThHTMLAttributes<HTMLTableCellElement>
}

export interface IHead extends IElement {
  //screenReader?: boolean
}

export interface IBody {
  //id: React.Key | null | undefined,
  element: IElement
}

export interface ITable {
  thead: IHead,
  head: IHead[],
  body: IBody[]
}

export default function ({ data }: { data: ITable }) {
  return (
    <div className="flex-col col-span-2 m-2">
      <table className="table border border-gray-200 rounded">
        <thead>
          <th colSpan={2} {...data.thead.props}>
            {data.thead.element}
          </th>
        </thead>
        <tbody>
          {data.head.map((head: IHead, index) => (
            <tr>
              <td {...head.props} key={index}>
                {head.element}
              </td>
              <td>
                {data.body[index]?.element?.element}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}