import i18n, { t } from "i18next";

let permissions = {
  waiter: [
    { id: 401, label: "create-order" },
    { id: 402, label: "add-product-to-order" },
    { id: 403, label: "update-order" },
    { id: 404, label: "delete-order" },
    { id: 405, label: "transfer-table" },
    { id: 406, label: "add-service-fee" },
  ],
  pos: [
    { id: 501, label: "create-order" },
    { id: 502, label: "add-product-to-order" },
    { id: 503, label: "delete-product-from-order" },
    { id: 504, label: "delete-order" },
    { id: 505, label: "apply-discount" },
    { id: 506, label: "create-cover" },
    { id: 507, label: "take-payment" },
    { id: 508, label: "transfer-table" },
    { id: 509, label: "create-expense" },
    { id: 510, label: "close-table" },
    { id: 511, label: "close-case" },
    { id: 512, label: "x-report" },
    { id: 513, label: "move-product" },
    { id: 514, label: "create-catering" },
    { id: 515, label: "tick-operations" },
    { id: 516, label: "analysis-perm" },
    { id: 517, label: "manager-perm" },
    { id: 518, label: "check-perm" },
    { id: 519, label: "custom-table-perm" },
    { id: 520, label: "update-order" },
    { id: 521, label: "update-currencies" },
    { id: 522, label: "scales-access" },
    { id: 523, label: "barcode-settings-perm" },
    { id: 524, label: "service-perm" },
    { id: 525, label: "setting-perm" },
    { id: 526, label: "getir-perm" },
  ],
};
let order = {
  1: "waiter-pos-order",
  2: "qr-code-order",
  3: "home-delivery",
  4: "takeaway",
  5: "case-sale",
  10: "person",
};
let roles = [
  { id: "pos", label: "pos-user" },
  { id: "waiter", label: "waiter" },
  { id: "kitchen", label: "kitchen" },
  { id: "accounting", label: "accounting" },
  { id: "delivery", label: "courier" },
];

let product = {
  1: "quantity",
  2: "kilogram",
  3: "liter",
  4: "meter",
  5: "portion",
};

let option = {
  1: "Tekli seçim",
  2: "Çoklu seçim",
};

function updateTranslations() {
  permissions.waiter.map((perm: any) => {
    perm.label = i18n.t(perm.label);
  });
  permissions.pos.map((perm: any) => {
    perm.label = i18n.t(perm.label);
  });

  roles.map((role: any) => {
    role.label = i18n.t(role.label);
  });

  Object.keys(order).map((o: any) => {
    (order as any)[o] = i18n.t((order as any)[o]);
  });

  Object.keys(product).map((p: any) => {
    (product as any)[p] = i18n.t((product as any)[p]);
  });

  Object.keys(option).map((o: any) => {
    (option as any)[o] = i18n.t((option as any)[o]);
  });
}

if (i18n.isInitialized) {
  updateTranslations();
}

i18n.on("languageChanged", function (lng) {
  updateTranslations();
});

i18n.on("loaded", function (lng) {
  updateTranslations();
});
export default {
  types: {
    lang: [
      {
        value: "tr",
        title: "Türkçe",
      },
      {
        value: "en",
        title: "English",
      },
      {
        value: "ar",
        title: "Arabic",
      },
      {
        value: "de",
        title: "German",
      },
      {
        value: "fr",
        title: "French",
      },
      {
        value: "it",
        title: "Italian",
      },
      {
        value: "nl",
        title: "Dutch",
      },
      {
        value: "pt",
        title: "Portuguese",
      },
      {
        value: "ro",
        title: "Romanian",
      },
      {
        value: "ru",
        title: "Russian",
      },
      {
        value: "es",
        title: "Spanish",
      },
      {
        value: "zh",
        title: "Chinese",
      },
    ],
    currencies: [
      { value: "TL", title: "Turkish lira" },
      { value: "USD", title: "United States dollar" },
      { value: "EUR", title: "Euro" },
      //{ value: "RUB", title: "Russian ruble" },
      //{ value: "KWD", title: "Kuwaiti dinar" },
      //{ value: "SAR", title: "Saudi riyal" },
      { value: "GBP", title: "British pound sterling" },
    ],
    product: product,
    option: option,
    order: order,
    allergens: {
      1: "Alerjen 1",
      2: "Test alerjen 2",
      3: "alergen title 3",
      4: "alg test 4",
      5: "test alg 5",
    },
    roles: roles,
    permissions: permissions,
  },
};
