export interface ITablesReportResult {
    table: string,
    quantity: number,
}

export interface ITablesReport {
    label: string,
    result: ITablesReportResult[]
}

export interface Report {
    title: string;
    data: ITablesReport[];
}

export default interface IResponseTableReport {
    success: boolean,
    report: Report[]
}

export const PaymentTypes: any = {
    1: "pos_bank",
    2: "pos_cash",
    3: "app_payment",
    6: "tick",
    7: "sodexo",
    8: "smart",
    9: "winwin",
    10: "multinet",
    11: "setcard",
    12: "metropol",
    13: "edended",
    14: "tips",
    15: "jetkurye",
    16: "not-payable",
    17: "migros-hemen",
    18: "getir-online",
    19: "trendyol",
    20: "yemek-sepeti"
};