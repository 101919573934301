import SurveyDisclosure from "./disclosures/survey";
import ServeDisclosure from "./disclosures/serve";
import ServiceDisclosure from "./disclosures/userService";
import AllergenDisclosure from "./disclosures/allergen";
import CancelDisclosure from "./disclosures/cancel";
import NoteDisclosure from "./disclosures/notes";
import CurrencySettings from "./disclosures/currency_settings";

import { useTranslation } from "react-i18next";

export default function () {
  const { t } = useTranslation();
  return (
    <>
      <div className="header-top">
        <h2 className="text-xl font-medium pl-2">{t('settings')}</h2>
      </div>
      <CurrencySettings />
      <div id="content">
        <div className="w-full px-4">
          <div className="w-full p-2 mx-auto bg-white rounded-2xl">
            <SurveyDisclosure />
            <ServeDisclosure />
            <ServiceDisclosure />
            <AllergenDisclosure />
            <CancelDisclosure />
            <NoteDisclosure />
          </div>
        </div>
      </div>
    </>
  );
}
