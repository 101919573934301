import UString from "utilities/string"
import { useTranslation } from "react-i18next";

export default function ({ className, error, retry }: { className?: string | undefined, error?: any | undefined, retry: () => void }) {
    if (!error)
        return null
    const { t } = useTranslation();
    return (
        <div className={UString.concat_class_name(className, "network-error")}>
            <div className="block text-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
                <div className="mt-2 mb-6">
                    <div className="font-medium text-2xl mb-1">{t('network-error')}</div>
                    <div className="text-gray-600">{error.message}</div>
                </div>
                <button className="to-gray-btn active mx-auto" onClick={retry}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                    <span>{t('try-again')}</span>
                </button>
            </div>
        </div>
    );
}