import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Select, MenuItem, Box, Button, InputLabel, TextField, Dialog, DialogTitle, DialogContent, Grid, Collapse, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Toolbar, Stack, ButtonGroup, DialogActions, FormControl } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import Http from "services/http";
import Response from "services/response";
import { toast } from 'react-toastify';
import Loading from "components/loading";
import { PaymentTypes } from 'services/interfaces/report/table';
import { FileDownload } from '@mui/icons-material';
import { parseOdenmezReport, parseSpecialCustomerDetail, parseSpecialCustomerReport } from 'services/helpers/excel';
import { convertCurrency, defaultCurrency } from 'services/helpers/exchange';

//user token info for api
const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(
    process.env.REACT_APP_REFRESH_TOKEN_NAME!
);

export default function ExpenseReport() {
    const { t } = useTranslation();

    //report range - last 1 day by default
    const [to, setTo] = useState(new Date());
    const [from, setFrom] = useState(new Date(new Date().getTime() - 86400000));

    //loading info for fetching
    const [loading, setLoading] = useState(false);
    //all branches accessible by the user
    const [branches, setBranches]: any[] = useState([]);
    //branch to fetch reports
    const [selectedBranch, setSelectedBranch] = useState("");
    //report data
    const [report, setReport] = useState([]);

    //special customers
    const [specialCustomerDialogOpen, setSpecialCustomerDialog] = useState(false);
    const [specialCustomers, setSpecialCustomers] = useState([]);
    const [detailDialogOpen, setDetailDialogOpen] = useState(false);
    const [selectedSpecialCustomer, setSelectedSpecialCustomer]: any = useState({});

    //only works once for first report data
    useEffect(() => {
        //fetch branch then fetch report
        const fetchBranch = async () => {
            let branch: any = await axios.get(`manager/branches`, {
                baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "x-refresh": refreshToken!,
                },
            });
            await setBranches([branch?.data[0]].concat(branch?.data[0]?.subBranch));
            await setSelectedBranch(branch?.data[0]?._id);
            const fetchReports = async () => {
                let reportData: any = await axios.get(`manager/report/not-payable/${branch?.data[0]?._id}`, {
                    baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "x-refresh": refreshToken!,
                    },
                    params: {
                        from: new Date(from).getTime(),
                        to: new Date(to).getTime()
                    }
                });
                setReport(reportData.data);
            }
            fetchReports();
        }

        fetchBranch();
    }, [])

    //handle button click - runs auto on every branch changes
    const syncData = async () => {
        if (
            !new Date(from).getTime() ||
            !new Date(to).getTime()
        ) {
            toast.warning(t('please-check-date'));
            return;
        }
        if (from > to) {
            toast.warning(t('date-warning'));
            return;
        }
        setLoading(true);
        let reportData: any = await axios.get(`manager/report/not-payable/${selectedBranch}`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                Authorization: `Bearer ${token}`,
                "x-refresh": refreshToken!,
            },
            params: {
                from: new Date(from).getTime(),
                to: new Date(to).getTime()
            }
        })
        setReport(reportData.data);
        setLoading(false);
    }

    const calculatePayment = (payments: any[], notPayable: boolean) => {
        let total = 0;
        payments.map((payment: any) => {
            if (payment.type == 16) {
                if (notPayable) total += payment.amount;
            }
            if (!notPayable) total += payment.amount;
        });
        return total;
    }

    const openCustomerDialog = async () => {
        let users: any = await axios.get(`manager/report/special-customer/${selectedBranch}`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                Authorization: `Bearer ${token}`,
                "x-refresh": refreshToken!,
            }
        });
        setSpecialCustomers(users.data);
        setSpecialCustomerDialog(true);
    }

    const SpecialCustomerDetail = () => {

        const exportSpecialCustomerDetail = () => {
            if (!selectedSpecialCustomer || selectedSpecialCustomer?.checks?.length == 0) {
                toast.warning(t('no-ready-report'));
                return;
            }
            parseSpecialCustomerDetail(selectedSpecialCustomer);
        }

        return (
            <Dialog open={detailDialogOpen} onClose={() => setDetailDialogOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>{selectedSpecialCustomer.name} {t('detail')}</DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('table')}</TableCell>
                                    <TableCell>{t('total-order-revenue')}</TableCell>
                                    <TableCell>{t('not-payable-amount')}</TableCell>
                                    <TableCell>{t('worker')}</TableCell>
                                    <TableCell>{t('created-at')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedSpecialCustomer?.checks?.map((check: any) => (
                                    <TableRow>
                                        <TableCell>{check.table?.title}</TableCell>
                                        <TableCell>{convertCurrency(calculatePayment(check.payments, false))} {defaultCurrency()}</TableCell>
                                        <TableCell>{convertCurrency(calculatePayment(check.payments, true))} {defaultCurrency()}</TableCell>
                                        <TableCell>{check.user?.name} {check.user?.lastname}</TableCell>
                                        <TableCell>{new Date(check.createdAt)?.toLocaleDateString()}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" startIcon={<FileDownload />} onClick={() => exportSpecialCustomerDetail()} sx={{ backgroundColor: "#717D8C" }}>{t('export-reports')}</Button>
                    <Button variant="outlined" color="error" onClick={() => setDetailDialogOpen(false)}>{t('close-it')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const SpecialCustomerDialog = () => {

        const specialCustomerOnClick = (user: any) => {
            setSelectedSpecialCustomer(user);
            setDetailDialogOpen(true);
        }

        const exportSpecialCustomerReport = () => {
            if (specialCustomers?.length == 0) {
                toast.warning('no-special-customers');
                return;
            }
            parseSpecialCustomerReport(specialCustomers, branches?.find((b: any) => b._id == selectedBranch)?.title)
        }

        return (
            <Dialog open={specialCustomerDialogOpen} onClose={() => setSpecialCustomerDialog(false)} maxWidth="lg" fullWidth>
                <DialogTitle>{t('not-payable-accounts')}</DialogTitle>
                <DialogContent>
                    {specialCustomers?.length == 0 ?
                        <>
                            {t('no-special-customers')}
                        </> : (
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('name')}</TableCell>
                                            <TableCell>{t('phone_number')}</TableCell>
                                            <TableCell>{t('total-amount')}</TableCell>
                                            <TableCell>{t('applied-discount-percentile')}</TableCell>
                                            <TableCell>{t('createdBy')}</TableCell>
                                            <TableCell>{t('created-at')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {specialCustomers?.map((user: any) => (
                                            <TableRow sx={{ cursor: "pointer", "&:hover": { backgroundColor: "#e8e8e8" } }} onClick={() => specialCustomerOnClick(user)}>
                                                <TableCell>{user.name}</TableCell>
                                                <TableCell>{user.gsm_no}</TableCell>
                                                <TableCell>{convertCurrency(user.totalAmount)} {defaultCurrency()}</TableCell>
                                                <TableCell>%{user.discount}</TableCell>
                                                <TableCell>{user.createdBy?.name} {user.createdBy?.lastname}</TableCell>
                                                <TableCell>{new Date(user.createdAt)?.toLocaleDateString()}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" startIcon={<FileDownload />} onClick={() => exportSpecialCustomerReport()} sx={{ backgroundColor: "#717D8C" }}>{t('export-reports')}</Button>
                    <Button variant="outlined" color="error" onClick={() => setSpecialCustomerDialog(false)}>{t('close-it')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const exportReports = () => {
        if (report?.length == 0) {
            toast.warning(t('no-ready-report'));
            return;
        }
        parseOdenmezReport(report, branches?.find((b: any) => b._id == selectedBranch)?.title);
    }

    return (
        <Box sx={{ overflow: "auto" }}>
            <SpecialCustomerDialog />
            <SpecialCustomerDetail />
            <Stack direction="row" sx={{ marginTop: 1 }}>
                <Grid container justifyContent="left">
                    {branches.length>1?<FormControl sx={{ marginLeft: 1, marginTop: 1 }}>
                        <InputLabel id="branchLabel">{t('branch')}</InputLabel>
                        <Select sx={{ minWidth: "10vw" }} labelId="branchLabel" value={selectedBranch} onChange={async (e: any) => {
                            await setSelectedBranch(e.target.value);
                            syncData();
                        }}>
                            {branches?.map((branch: any) => (
                                <MenuItem value={branch._id}>
                                    {branch.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>:<></>}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack direction="row" spacing={1} sx={{marginTop:1, marginLeft:1 }}>
                            <DateTimePicker
                                label={t('start-date')!}
                                inputFormat="DD/MM/YYYY HH:mm"
                                value={from}
                                ampm={false}
                                onChange={async (value: any) => {
                                    await setFrom(value)
                                }}
                                renderInput={(params: any) => <TextField {...params} />}
                            />
                            <DateTimePicker
                                label={t('end-date')!}
                                inputFormat="DD/MM/YYYY HH:mm"
                                value={to}
                                ampm={false}
                                onChange={async (value: any) => {
                                    await setTo(value)
                                }}
                                renderInput={(params: any) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>
                <Grid container justifyContent="right" sx={{ marginRight: "1vw" }}>
                    <ButtonGroup orientation='vertical'>
                        <Button variant="contained" color="inherit" onClick={() => {
                            syncData();
                        }}>{t('get-reports')}
                        </Button>
                        <Button variant="contained" color="inherit" onClick={() => openCustomerDialog()}>{t('not-payable-accounts')}</Button>
                        <Button variant="contained" startIcon={<FileDownload />} onClick={() => exportReports()} sx={{ backgroundColor: "#717D8C" }}>{t('export-reports')}</Button>
                    </ButtonGroup>
                </Grid>
            </Stack>
            <Box>
                {loading ? (
                    <Loading />
                ) : (
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('customer')}</TableCell>
                                    <TableCell>{t('note')}</TableCell>
                                    <TableCell>{t('amount')}</TableCell>
                                    <TableCell>{t('currency')}</TableCell>
                                    <TableCell>{t('created-at')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {report?.map((report: any) => (
                                    <TableRow>
                                        <TableCell>{report.specialCustomer?.name}</TableCell>
                                        <TableCell>{report.note?.note}</TableCell>
                                        <TableCell>{convertCurrency(report.amount)}</TableCell>
                                        <TableCell>{defaultCurrency()}</TableCell>
                                        <TableCell>{new Date(report.createdAt).toLocaleString()}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
        </Box>
    )
}
