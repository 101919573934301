import React, { Component, useState } from 'react'
import { Select, MenuItem, Box, Button, InputLabel, TextField, Dialog, DialogTitle, DialogContent, Grid, Collapse, Typography, Stack, ButtonGroup } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, BarChart, Bar } from 'recharts';
import { DataGrid, GridColDef, GridDragIcon } from '@mui/x-data-grid';
import axios from 'axios';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import clone from 'clone';
import Loading from 'components/loading';
import { toast } from 'react-toastify';
import NoReport from '../no-report';
import { withTranslation, WithTranslation } from 'react-i18next';
import FileDownload from '@mui/icons-material/FileDownload';
import { parseOrderTypeReport } from 'services/helpers/excel';
import { convertCurrency, defaultCurrency } from 'services/helpers/exchange';


type OrderTypeReportState = {
    reportData: any,
    lineData: any,
    startdate: any,
    enddate: any,
    per: number,
    userBranch: any,
    subBranches: any,
    chartType: string,
    branchDialog: boolean,
    selectedBranches: string[],
    selectedBranch: string,
    loading: boolean
}

const theme = createTheme({
    status: {
        checked: "##003399",
        unchecked: "#0099CC"
    },
    default: {
        primary: "#717D8C",
        secondary: "#2F343A"
    }
})

const SubbranchButton = styled(Button)(({ theme }) => ({
    color: theme.default.secondary,
    backgroundColor: theme.default.primary,
    borderRadius: 10,
    marginTop: "2vw",
}));

const BranchButton = styled(Button)(({ theme }) => ({
    color: "#fff",
    backgroundColor: theme.default.primary,
    fontSize: "0.9vw",
    padding: "0.5vw",
    height: "8vh",
    width: "10vw",
    "&:hover": {
        color: "#66CCFF",
        backgroundColor: "#003399"
    }
}));

const COLORS = ["#BCF1ED", "#FF634D", "#FD795B", "#028482", "#7ABA7A", "#B76EB8", "#FFEC94", "#FFAEAE", "#FFF0AA", "#B0E57C", "#B4D8E7", "#56BAEC"];
const LINE_COLORS = ["#333333", "#FF9900", "#336699", "#AC54AA", "#6600CC", "#FF6600", "#CC0000", "#000080"]

const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_NAME!);

interface IProps extends WithTranslation { }

class OrderTypeReport extends Component<IProps> {
    state: OrderTypeReportState = {
        chartType: this.props.t('table-chart'),
        reportData: [],
        lineData: [],
        per: 86400000,
        userBranch: {},
        subBranches: [],
        startdate: new Date(Date.now() - 86400000),
        enddate: new Date(),
        branchDialog: false,
        selectedBranches: [],
        selectedBranch: "",
        loading: true
    }

    async componentDidMount() {
        let data: any = await axios.get(`manager/report/ordertype`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-refresh': refreshToken!
            },
            params: {
                from: new Date(this.state.startdate).getTime(),
                to: new Date(this.state.enddate).getTime(),
            }
        });
        let branch: any = await axios.get(`manager/branches`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-refresh': refreshToken!
            }
        });
        this.setState({
            reportData: data.data,
            userBranch: branch?.data[0],
            subBranches: branch?.data[0]?.subBranch,
            selectedBranches: [branch?.data[0]?._id],
            selectedBranch: branch?.data[0]?._id,
            loading: false
        });
    }

    columns: GridColDef[] = [
        {
            field: "name",
            headerName: this.props.t('bill-type')!,
            width: 400,
            renderCell: (params) => (
                <strong>
                    {params.value}
                </strong>
            )
        },
        {
            field: "saleCount",
            headerName: this.props.t('order-count')!,
            width: 160,
            renderCell: (params) => (
                <>{params.value?.toFixed()}</>
            )
        },
        {
            field: "salePercent",
            headerName: this.props.t('sale-percent')!,
            width: 200,
            renderCell: (params) => (
                <>{params.value?.toFixed(1)}%</>
            )
        },
        {
            field: "totalPrice",
            headerName: this.props.t('total-income')!,
            width: 200,
            renderCell: (params) => (
                <>{convertCurrency(params.value)} {defaultCurrency()}</>
            )
        },
        {
            field: "pricePercent",
            headerName: this.props.t('price-percent')!,
            width: 200,
            renderCell: (params) => (
                <>{params.value?.toFixed(1)}%</>
            )
        },
        {
            field: "averagePrice",
            headerName: this.props.t('average-income')!,
            width: 200,
            renderCell: (params) => (
                <>{convertCurrency(params.value)} {defaultCurrency()}</>
            )
        }
    ]

    BranchDialog = () => {
        const handleClick = async (value: string) => {
            if (value == "all") {
                if (this.state.selectedBranches.length == this.state.subBranches.length + 1) {
                    this.setState({
                        selectedBranches: []
                    });
                    return;
                }
                this.setState({
                    selectedBranches: this.state.subBranches.map((b: any) => (b._id)).concat(this.state.userBranch._id)
                })
                return;
            }
            let index: number = this.state.selectedBranches.findIndex((b: any) => b == value);
            if (index > -1) {
                let branches: any = clone(this.state.selectedBranches);
                branches.splice(index, 1);
                await this.setState({
                    selectedBranches: branches
                });
                return;
            }
            await this.setState({
                selectedBranches: this.state.selectedBranches.concat([value])
            });
        }
        return (
            <Dialog open={this.state.branchDialog} onClose={() => this.setState({ branchDialog: false })} fullWidth sx={{ overflow: "auto" }}>
                <ThemeProvider theme={theme}>
                    <DialogTitle>{this.props.t('select-branch-to-list-reports')}</DialogTitle>
                    <DialogContent>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6} key={0}>
                                <BranchButton onClick={(e: any) => handleClick(e.target.value)} startIcon={this.state.selectedBranches.length == this.state.subBranches.length + 1 ? (<DoneAllIcon />) : (<CloseIcon />)} value="all" sx={this.state.selectedBranches.length == this.state.subBranches.length + 1 ? { color: theme.status.unchecked, backgroundColor: "#003399" } : {}}>{this.props.t("all")}</BranchButton>
                            </Grid>
                            <Grid item xs={6} key={1}>
                                <BranchButton onClick={(e: any) => handleClick(e.target.value)} startIcon={this.state.selectedBranches.includes(this.state.userBranch._id) ? (<DoneIcon />) : (<CloseIcon />)} value={this.state.userBranch._id} sx={this.state.selectedBranches.includes(this.state.userBranch._id) ? { color: theme.status.unchecked, backgroundColor: "#003399" } : {}}>{this.state.userBranch.title}</BranchButton>
                            </Grid>
                            {this.state.subBranches.map((branch: any, index: number) => (
                                <Grid item xs={6} key={index + 2}>
                                    <BranchButton onClick={(e: any) => handleClick(e.target.value)} startIcon={this.state.selectedBranches.includes(branch._id) ? (<DoneIcon />) : (<CloseIcon />)} value={branch._id} sx={this.state.selectedBranches.includes(branch._id) ? { color: theme.status.unchecked, backgroundColor: "#003399" } : {}}>{branch.title}</BranchButton>
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContent>
                </ThemeProvider>
            </Dialog >
        )
    }

    syncData = async () => {
        if (!new Date(this.state.startdate)?.getTime() || !new Date(this.state.enddate)?.getTime()) {
            this.setState({
                lineData: [],
                reportData: []
            });
            return;
        }
        if (this.state.startdate > this.state.enddate) {
            toast.warning(this.props.t('date-warning'));
            return;
        }
        if (this.state.enddate >= this.state.startdate) {
            this.setState({ loading: true });
            if (this.state.chartType == this.props.t('line-chart')) {
                let lineData: any = await axios.get(`manager/report/ordertype/${this.state.selectedBranch}`, {
                    baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'x-refresh': refreshToken!
                    },
                    params: {
                        from: new Date(this.state.startdate).getTime(),
                        to: new Date(this.state.enddate).getTime(),
                        per: this.state.per
                    }
                });
                this.setState({
                    lineData: lineData.data,
                    loading: false
                })
                return;
            }
            let data: any = await axios.get(`manager/report/ordertype`, {
                baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'x-refresh': refreshToken!
                },
                params: {
                    from: new Date(this.state.startdate).getTime(),
                    to: new Date(this.state.enddate).getTime(),
                }
            });
            this.setState({
                reportData: data.data,
                loading: false
            });
        }

    }

    TableReport = (data: any) => {
        const [expanded, setExpanded] = useState(false);
        return (
            <Box sx={{ marginBottom: "5vw" }}>
                <Typography component="div" sx={{ cursor: "pointer" }} onClick={() => setExpanded(!expanded)}>
                    <Box sx={{ textAlign: "center", backgroundColor: "#ededed", borderRadius: "10px 10px 0 0", color: "#000", fontWeight: "bold", fontSize: "1.23vw" }}>{data.branchTitle} {expanded ? (<KeyboardArrowUpIcon fontSize='large' />) : (<KeyboardArrowDownIcon fontSize='large' />)}</Box>
                </Typography>
                <Collapse in={expanded}>
                    <Box>
                        <Box sx={{
                            height: 600,
                            width: "100%",
                            backgroundColor: "#ededed",
                            color: "#fff",
                            '& .row-class': {
                                bgcolor: "#fff",
                                color: "#000",
                                '&:hover': {
                                    color: "#000"
                                },
                                '&:selected': {
                                    color: "#fff"
                                }
                            }
                        }}
                            key={data.id}
                        >
                            <DataGrid
                                rows={data.reports || []}
                                columns={this.columns}
                                getRowClassName={(params: any) => 'row-class'}
                                disableSelectionOnClick

                            />
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        )

    }

    exportReport = () => {
        if (this.state.reportData.length == 0) {
            toast.warning('no-ready-report');
            return;
        }
        parseOrderTypeReport(this.state.reportData);
    }

    renderCustomizedLabel = (props: any) => {
        const { cx, cy, midAngle, innerRadius, outerRadius, percent, index, value } = props;
        const RADIAN = Math.PI / 180;
        // eslint-disable-next-line
        const radius = 25 + innerRadius + (outerRadius - innerRadius);
        // eslint-disable-next-line
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        // eslint-disable-next-line
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x}
                y={y}
                fill="#015A09"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {`${(percent * 100)?.toFixed(0)}%`}
                {" "}
                {props.name}
            </text>
        );
    };

    PieReport = (data: any, index: number) => {
        const [expanded, setExpanded] = useState(false);

        return (
            <Box sx={{ marginBottom: "5vw" }}>
                <Typography component="div" sx={{ cursor: "pointer" }} onClick={() => setExpanded(!expanded)}>
                    <Box sx={{ textAlign: "center", backgroundColor: "#2F343A", borderRadius: "10px 10px 0 0", color: "#fff", fontWeight: "bold", fontSize: "1.23vw" }}>{data.branchTitle} {expanded ? (<KeyboardArrowUpIcon fontSize='large' />) : (<KeyboardArrowDownIcon fontSize='large' />)}</Box>
                </Typography>
                <Collapse in={expanded}>
                    <Box sx={{ display: "flex", flexDirection: "column", overflow: "auto", justifyContent: "space-between" }}>
                        <Box>
                            <h3 style={{ textAlign: "center", margin: "auto", fontWeight: "bold", fontSize: "1.4vw" }}>{this.props.t('sale-count')}</h3>
                            <PieChart width={1200} height={700} title={this.props.t('sale-count')!}>
                                <Pie
                                    data={data?.reports.filter((data: any) => data.saleCount != 0)}
                                    labelLine={false}
                                    dataKey="saleCount"
                                    name={this.props.t('sale-count')!}
                                    fill="#8884d8"
                                    label={this.renderCustomizedLabel}
                                    outerRadius={250}
                                >
                                    {data?.reports?.filter((data: any) => data.saleCount != 0)?.map((data: any, index: number) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </Box>
                        <Box>
                            <h3 style={{ textAlign: "center", margin: "auto", fontWeight: "bold", fontSize: "1.4vw", marginTop: 3 }}>{this.props.t('total-price')}</h3>
                            <PieChart width={1200} height={800} title={this.props.t('total-price')!}>
                                <Pie
                                    data={data?.reports.filter((data: any) => data.saleCount != 0)}
                                    labelLine={false}
                                    dataKey="totalPrice"
                                    name={this.props.t('total-price')!}
                                    fill="#8884d8"
                                    label={this.renderCustomizedLabel}
                                    outerRadius={250}
                                >
                                    {data?.reports?.filter((data: any) => data.saleCount != 0)?.map((data: any, index: number) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </Box>
                        <Box>
                            <h3 style={{ textAlign: "center", margin: "auto", fontWeight: "bold", fontSize: "1.4vw", marginTop: 3 }}>{this.props.t('average-price')}</h3>
                            <PieChart width={1200} height={800} title={this.props.t('average-price')!}>
                                <Pie
                                    data={data?.reports.filter((data: any) => data.saleCount != 0)}
                                    labelLine={false}
                                    dataKey="averagePrice"
                                    name={this.props.t('average-price')!}
                                    fill="#8884d8"
                                    label={this.renderCustomizedLabel}
                                    outerRadius={250}
                                >
                                    {data?.reports?.filter((data: any) => data.saleCount != 0)?.map((data: any, index: number) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        )
    }

    LineReport = () => {

        return (
            <Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box sx={{ marginLeft: "1.3vw", marginRight: "1.3vw" }}>
                        <InputLabel id="branchLabel">{this.props.t('branch')}</InputLabel>
                        <Select labelId="branchLabel" value={this.state.selectedBranch} onChange={async (e: any) => {
                            await this.setState({
                                selectedBranch: e.target.value
                            });
                            this.syncData();
                        }}>
                            {[this.state.userBranch].concat(this.state.subBranches).map((branch: any) => (
                                <MenuItem value={branch._id}>{branch.title}</MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box>
                        <InputLabel id="per">{this.props.t('time-interval')}</InputLabel>
                        <Select labelId="per" value={this.state.per} onChange={async (e: any) => {
                            await this.setState({
                                per: e.target.value
                            });
                            this.syncData();
                        }}>
                            {[{ name: this.props.t('daily'), value: 86400000 }, { name: this.props.t('3-daily'), value: 259200000 }, { name: this.props.t('weekly'), value: 604800000 }, { name: this.props.t('monthly'), value: 2629743000 }, { name: this.props.t('3-monthly'), value: 7889229000 }, { name: this.props.t('6-monthly'), value: 15778458000 }].map((p: any) => (
                                <MenuItem key={p.value} value={p.value}>{p.name}</MenuItem>
                            ))}

                        </Select>
                    </Box>
                </Box>
                <Box>
                    <LineChart width={1100} height={600}>
                        {this.state.lineData?.map((data: any, index: number) => (
                            <Line data={data.reports} type="monotone" strokeWidth={3} legendType="circle" dataKey="saleCount" name={data.name} stroke={LINE_COLORS[index % LINE_COLORS.length]} />
                        ))}
                        <XAxis allowDuplicatedCategory={false} dataKey="date" name="Tarih" />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend />
                    </LineChart>
                </Box>
            </Box>
        )
    }

    render() {
        if (this.state.loading) {
            return (
                <>
                    <Loading />
                </>
            )
        }
        return (
            <React.Fragment>
                <Stack direction="column" spacing={5}>
                    <Box sx={{ overflow: "auto" }}>
                        <this.BranchDialog />
                        <Stack direction="row" spacing={2} alignItems="center" sx={{ marginTop: 1 }}>
                            <Box sx={{
                                marginLeft: "0.5vw"
                            }}>
                                <InputLabel id="chartTypeLabel">{this.props.t('chart-type')}</InputLabel>
                                <Select value={this.state.chartType} onChange={async (e: any) => {
                                    await this.setState({
                                        chartType: e.target.value
                                    });
                                    this.syncData();
                                }} labelId='chartTypeLabel'
                                    sx={{
                                        minWidth: "9vw",
                                        textAlign: "center"
                                    }}
                                >
                                    {
                                        [this.props.t('table-chart'), this.props.t('line-chart'), this.props.t('pie-chart')].map((type: string) => (
                                            <MenuItem key={type} value={type}>{type}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Box>
                            <Grid container justifyContent="left">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Stack direction="row" spacing={2} sx={{marginTop:2}}>
                                        <DateTimePicker
                                            label={this.props.t('start-date')!}
                                            inputFormat="DD/MM/YYYY HH:mm"
                                            value={this.state.startdate}
                                            ampm={false}
                                            onChange={async (value: any) => {
                                                await this.setState({
                                                    startdate: value
                                                });
                                            }}
                                            renderInput={(params: any) => <TextField {...params} />}
                                        />
                                        <DateTimePicker
                                            label={this.props.t('end-date')!}
                                            inputFormat="DD/MM/YYYY HH:mm"
                                            value={this.state.enddate}
                                            ampm={false}
                                            onChange={async (value: any) => {
                                                await this.setState({
                                                    enddate: value
                                                });
                                            }}
                                            renderInput={(params: any) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                            <Grid container justifyContent="right">
                                <ButtonGroup orientation='vertical'>
                                    {this.state.chartType == this.props.t('line-chart') || this.state.subBranches.length < 1 ? null : (
                                        <>
                                            <ThemeProvider theme={theme}>
                                                <SubbranchButton sx={{
                                                    "&:hover": {
                                                        color: theme.default.primary,
                                                        backgroundColor: theme.default.secondary
                                                    }
                                                }} variant="contained" onClick={() => this.setState({ branchDialog: true })}>{this.props.t('selected-branches')} ({this.state.selectedBranches.length})</SubbranchButton>
                                            </ThemeProvider>
                                        </>

                                    )}
                                    <Button variant="contained" color="inherit" onClick={() => {
                                        this.syncData();
                                    }}>{this.props.t('get-reports')}
                                    </Button>
                                    {this.state.chartType == this.props.t('line-chart') ? null : (
                                        <Button variant="contained" onClick={() => this.exportReport()} startIcon={<FileDownload />} sx={{ backgroundColor: "#717D8C" }}>{this.props.t('export-reports')}</Button>

                                    )}
                                </ButtonGroup>
                            </Grid>
                        </Stack>
                    </Box>
                    <Box sx={{ overflow: "auto" }}>
                        {this.state.chartType == this.props.t('table-chart') ? (
                            <>
                                {this.state.reportData?.length == 0 ? (
                                    <>
                                        <NoReport />
                                    </>
                                ) : (
                                    <Box>
                                        {
                                            this.state.reportData?.filter((report: any) => this.state.selectedBranches.includes(report.id)).map((report: any) => (
                                                <this.TableReport {...report} />
                                            ))
                                        }
                                    </Box>
                                )}
                            </>

                        ) : this.state.chartType == this.props.t('price-chart') ? (
                            <>
                                {this.state.reportData?.length == 0 ? (
                                    <>
                                        <NoReport />
                                    </>
                                ) : (
                                    <Box>
                                        {
                                            this.state.reportData?.filter((report: any) => this.state.selectedBranches.includes(report.id)).map((report: any) => (
                                                <this.PieReport {...report} />
                                            ))
                                        }
                                    </Box>
                                )}
                            </>
                        ) : this.state.chartType == this.props.t('line-chart') ? (
                            <Box>
                                <this.LineReport />
                            </Box>
                        ) : (
                            <>{this.props.t('invalid-chart-type')}e</>
                        )}
                    </Box>
                </Stack>
            </React.Fragment>
        )
    }
}

export default withTranslation()(OrderTypeReport);
