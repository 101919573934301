export default function ShowSum(
    {
        name,
        data,
        type,
    }:{
        name:string;
        data:any;
        type?:string;
    }
) {
    return <div className="bg-white p-4 border border-gray-200 rounded-lg">
        <div>
            <span className="text-xs font-medium text-gray-500 uppercase">{name}</span>
            <h2 className="text-4xl font-bold xl:font-medium mt-1">
                {data+(type?type:"")} 
            </h2>
        </div>
    </div>;
}
