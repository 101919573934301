import { Service as HService } from "services/http";
import Http from "services/http";

import Authenticate from "services/authenticate";
import { useContext } from "react";
export default class Service {
  private Http: HService | null = null;

  constructor(http: HService) {
    const Auth = useContext(Authenticate.Context);
    let authToken = Auth?.bearerToken();

    let getirHttpInstance = Http.createInstance({ Authorization: authToken! });
    let tempBaseUrl = http.baseURL;
    getirHttpInstance.instance.defaults.baseURL = tempBaseUrl?.replace(
      "/manager",
      "/getir"
    );
    getirHttpInstance.addHeader("Authorization", authToken!);
    this.Http = getirHttpInstance;
  }

  public async login(restaurantSecretKey: string) {
    const res = await this.Http?.instance.post(`/login`, {
      restaurantSecretKey,
    });
    return res;
  }

  public async getCategories() {
    return await this.Http?.instance.get(`/products/categories`);
  }

  // RESTAURANT
  public async getIntegration() {
    return await this.Http?.instance.get(`/restaurant`);
  }

  public async setIntegration(RSK: string, token: string) {
    this.Http?.addHeader("token", token);
    return await this.Http?.instance.post(`/restaurant`, {
      restaurantSecretKey: RSK,
    });
  }

  // PRODUCTS
  public async getProducts() {
    return await this.Http?.instance.get(`/products`);
  }

  public async getMatchedProducts() {
    return await this.Http?.instance.get(`/matches`);
  }

  public async matchProducts(data: Object[]) {
    const res = await this.Http?.instance.post(`/products/match`, data);
    return res;
  }

  //OPTIONS
  public async getOptions() {
    return await this.Http?.instance.get(`/products/options`);
  }

  public async getMatchedOptions() {
    return await this.Http?.instance.get(`/options/match`);
  }

  public async matchOptions(data: Object[]) {
    const res = await this.Http?.instance.post(`/options/match`, data);
    return res;
  }
}
