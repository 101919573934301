import { Service as HService } from "services/http"

export default class Service {
    private Http: HService | null = null

    constructor(http: HService) {
        this.Http = http
    }

    public get endpoint(){
      return `${this.Http?.baseURL}/serves`
    }

    public async getAll() {
        return await this.Http?.instance.get("/serves");
    }

    public async createServe(data:any) {
        return await this.Http?.instance.post("/serves", data);
    }

    public async updateServe(id:string, data:object) {
        return await this.Http?.instance.put(`/serves/${id}`, data);
    }

    public async getServe(id:string) {
        return await this.Http?.instance.get(`/serves/${id}`);
    }

    public async deleteServe(id:string) {
        return await this.Http?.instance.delete(`/serves/${id}`);
    }



}