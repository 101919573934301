import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Spinner from "components/spinner";

import Http from "services/http";
import Response from "services/response";

import Option from "services/option";
import Getir from "services/getir";
import Branch from "services/branch";
// import Trendyol from "services/trendyol"

import { toast } from "react-toastify";

import NetworkError from "components/network-error";
import Loading from "components/loading";
import Table from "components/table";
import useCache from "services/useCache";
import { useSWRConfig } from "swr";
import { useTranslation } from "react-i18next";

export default function () {
  const { name } = useParams();
  const { mutate } = useSWRConfig();
  const { t } = useTranslation();
  const OptionService = new Option(useContext(Http.Context)!);

  // const TrendyolService = new Trendyol(useContext(Http.Context)!)
  const GetirService = new Getir(useContext(Http.Context)!);
  const BranchService = new Branch(useContext(Http.Context)!);

  const IntegrationOptions = new Response();
  const MatchedOptions = new Response();

  // const TrendyolToken = new Response();

  const Save = new Response();

  const fetchIntegrationOptions = async () => {
    if (!IntegrationOptions.data) {
      if (name === "getir") {
        IntegrationOptions.handle(GetirService.getOptions());
        MatchedOptions.handle(BranchService.getMatchedGetirOptions());
      }

      if (name === "trendyol") {
        toast.error(t("trendyol-integration-not-ready"));
        /*  TRENDYOL INTEGRATION NOT YET IMPLEMENTED

          IntegrationOptions.handle(TrendyolService.getOptions(TrendyolToken.data))
          MatchedOptions.handle(TrendyolService.getMatchedOptions())

          */
      }
    }
  };

  useEffect(() => {
    if (MatchedOptions.data) {
      let pairsFromDb: string[][] = [];
      let randomColors: string[] = [];
      let objectsFromDb: ResultObject[] = [];

      MatchedOptions.data.map((p: any) => {
        let obj: ResultObject = { id: p._id, getirId: p.getirId, items: [] };
        pairsFromDb.push([p._id, p.getirId]);
        randomColors.push(generateLightColorRgb());
        p.items.map((item: any) => {
          obj.items.push({
            id: item._id,
            getirId: item.getirId,
          });
          pairsFromDb.push([item._id, item.getirId]);
          randomColors.push(generateLightColorRgb());
        });
        objectsFromDb.push(obj);
      });

      setObjectPairs(objectsFromDb);
      setPairs(pairsFromDb);
      setColors(randomColors);
    }
  }, [MatchedOptions.data]);

  const handleSubmittingPairs = () => {
    Save.handle(
      BranchService.saveMatchedGetirOptions({
        paired: objectPairs,
        unpaired: unpaired,
      })
    );
    toast.success(t("saved-match-option"));
  };

  // Handle Filter
  const [category, setCategory] = useState("All");
  const [category2, setCategory2] = useState("All");
  const [unpaired, setUnpaired]: any = useState([]);

  // Fetch panel options
  const PanelOptions = useCache(OptionService);

  function generateLightColorRgb() {
    const red = Math.floor(((1 + Math.random()) * 256) / 2);
    const green = Math.floor(((1 + Math.random()) * 256) / 2);
    const blue = Math.floor(((1 + Math.random()) * 256) / 2);
    return "rgba(" + red + ", " + green + ", " + blue + ", 0.75)";
  }

  const [pair, setPair] = useState<string[]>([]);
  const [pairs, setPairs] = useState<string[][]>([]);

  type ResultObject = {
    id: string;
    getirId: string;
    items: {
      id: string;
      getirId: string;
    }[];
  };

  const [objectPairs, setObjectPairs] = useState<ResultObject[]>([]);
  const [colors, setColors] = useState<string[]>([]);
  const [selectedParent, setSelectedParent] = useState<string[]>([]);

  const clickHandle = (optionId: string, from: string, parent: any) => {
    if (unpaired.find((item: any) => item == optionId)) {
      setUnpaired((prev: any) => prev.filter((item: any) => item != optionId));
    }
    let validLeft = true,
      validRight = true;
    pairs.map((pair) => {
      if (pair[0] == optionId) {
        validLeft = false;
      }
      if (pair[1] == optionId) {
        validRight = false;
      }
    });

    if (validLeft && from == "left") {
      if (pair[0] == optionId) {
        // unselect
        setPair([]);
        let temp = [...colors];
        temp.splice(-1, 1);
        setColors(temp);
        setSelectedParent([]);
      } else {
        if (pair.length == 0) {
          let color = generateLightColorRgb();
          setColors([...colors, color]);
        }

        if (parent) {
          setSelectedParent([parent]);
        } else {
          setSelectedParent([]);
        }
        setPair([optionId]);
      }
    }

    if (validRight && pair.length == 1 && from == "right") {
      if (selectedParent.length == 1 && parent) {
        setSelectedParent([...selectedParent, parent]);
      }

      let isValid = true;
      if (selectedParent.length == 1 && !parent) {
        isValid = false;
      }
      if (selectedParent.length == 0 && parent) {
        isValid = false;
      }

      if (isValid) {
        setPair([...pair, optionId]);
      } else {
        toast.warning(t("option-match-warning"));
      }
    }
  };

  useEffect(() => {
    if (pair.length == 2) {
      if (selectedParent.length == 0) {
        let option = {
          id: pair[0],
          getirId: pair[1],
          items: [],
        };
        setPairs([...pairs, pair]);
        setObjectPairs([...objectPairs, option]);
        setPair([]);
        setSelectedParent([]);
      }

      if (selectedParent.length > 0) {
        let item = {
          id: pair[0],
          getirId: pair[1],
        };
        let modifiedOptions: ResultObject[] = [];

        let error: any = t("please-match-title-first");
        objectPairs.map((option) => {
          let opt = Object.assign({}, option);
          if (opt.id == selectedParent[0]) {
            if (opt.getirId == selectedParent[1]) {
              opt.items.push(item);
              error = false;
            } else {
              error = t("match-option-error");
            }
          }
          modifiedOptions.push(opt);
        });

        if (!error) {
          setObjectPairs([...modifiedOptions]);
          setPairs([...pairs, pair]);
          setPair([]);
          setSelectedParent([]);
        } else {
          toast.warning(error);
          setPair([pair[0]]);
          setSelectedParent([selectedParent[0]]);
        }
      }
    }
  }, [pair]);

  const checkIfPaired = (optionId: string) => {
    let paired = -1;
    let mergedPairs = [...pairs, [pair]];
    mergedPairs.map((pair, index) => {
      if (pair[1] == optionId || pair[0] == optionId) {
        paired = index;
      }
    });
    return paired;
  };

  const handleRemove = (optionId: string) => {
    let error: any = null;

    let copyObjectPairs = [...objectPairs];
    let result = copyObjectPairs.filter((option) => {
      let filtered = option.items.filter((item) => {
        if (item.getirId != optionId) {
          return true;
        } else {
          removePair(optionId);
          return false;
        }
      });
      option.items = filtered;
      if (option.getirId != optionId) {
        return true;
      } else {
        if (option.items.length == 0) {
          return false;
        } else {
          error = t("delete-opt-first");
          return true;
        }
      }
    });

    if (!error) {
      removePair(optionId);
      setObjectPairs([...result]);
    } else {
      toast.warning(error);
    }
  };

  const removePair = (id: string) => {
    let index = checkIfPaired(id);
    if (index != -1) {
      setUnpaired((prev: any) => [...prev, pairs[index][0]]);
      let newPairs = [...pairs];
      newPairs.splice(index, 1);
      setPairs(newPairs);

      let tempColors = [...colors];
      tempColors.splice(index, 1);
      setColors(tempColors);
    }
  };

  return (
    <React.Fragment>
      <div className="header-top">
        <h2 className="text-xl font-medium pl-2">
          <span className="capitalize">
            {name} {t("match-options")}{" "}
          </span>
        </h2>
      </div>
      <div id="content" className="flex justify-between">
        <div className="overflow-y-auto w-1/2 border-r">
          <div className="py-4 px-6 flex gap-x-6 border-b">
            <select
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              className="
                  rounded
                  text-sm
                  focus:border-ebony focus:bg-gray-50 focus:ring-0"
              aria-label="Filter Options By Option Title"
            >
              <option value="All" className="hover:bg-ebony hover:text-white">
                {t("option-titles")}
              </option>
              {PanelOptions?.data?.map((categories: any) => (
                <option
                  key={categories.id}
                  className="hover:bg-ebony hover:text-white"
                  value={categories.id}
                >
                  {categories.special_name}
                </option>
              ))}
            </select>
          </div>
          <Loading done={PanelOptions.data} />
          {PanelOptions.error ? (
            <NetworkError
              error={PanelOptions.error}
              retry={() => {
                mutate(OptionService.endpoint);
              }}
            />
          ) : null}
          {PanelOptions.data && !PanelOptions.error && (
            <Table
              data={{
                head: [
                  { element: t("options-in-panel"), props: { scope: "col" } },
                ],

                body: PanelOptions.data
                  .filter((option: any) => {
                    if (category === "All" || option.id === category) {
                      return option;
                    }
                  })
                  .map((option: any) => ({
                    id: option.id,
                    elements: [
                      {
                        element: (
                          <>
                            <div
                              className={`hover:bg-gray-50 cursor-pointer select-none flex items-center -my-4 -mx-6 py-4 px-6`}
                              style={{
                                backgroundColor:
                                  checkIfPaired(option.id) != -1
                                    ? colors[checkIfPaired(option.id)]
                                    : "",
                              }}
                              onClick={() => {
                                clickHandle(option.id, "left", null);
                              }}
                            >
                              <div>
                                <div className="font-medium text-gray-900">
                                  {option.special_name}
                                </div>
                              </div>
                            </div>
                            <div className="text-sm mt-4 -mx-6 -mb-4">
                              {option.items.map((item: any) => {
                                return (
                                  <div
                                    key={item._id}
                                    style={{
                                      backgroundColor:
                                        checkIfPaired(item._id) != -1
                                          ? colors[checkIfPaired(item._id)]
                                          : "",
                                    }}
                                    className="py-2 border-t px-8 cursor-pointer hover:bg-gray-50"
                                    onClick={() => {
                                      clickHandle(item._id, "left", option.id);
                                    }}
                                  >
                                    {item.item_name}
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        ),
                      },
                    ],
                  })),
              }}
            />
          )}
        </div>
        <div className="w-1/2 overflow-y-auto">
          {IntegrationOptions.data && (
            <div className="py-4 px-6 flex justify-end gap-x-6 border-b">
              <select
                value={category2}
                onChange={(e) => {
                  setCategory2(e.target.value);
                }}
                className="
                    rounded
                    text-sm
                    focus:border-ebony focus:bg-gray-50 focus:ring-0"
                aria-label="Filter Options By Option Title"
              >
                <option value="All" className="hover:bg-ebony hover:text-white">
                  {t("option-titles")}
                </option>
                {IntegrationOptions?.data?.map((categories: any) => (
                  <option
                    key={categories.id}
                    className="hover:bg-ebony hover:text-white"
                    value={categories.id}
                  >
                    {categories.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {!IntegrationOptions.data && (
            <div className="grid place-items-center h-full">
              <button
                className="classic-btn capitalize"
                onClick={fetchIntegrationOptions}
              >
                {name} {t("get-options")}
              </button>
            </div>
          )}
          <Loading done={!IntegrationOptions.loading} />
          {!IntegrationOptions.loading ? (
            <NetworkError
              error={IntegrationOptions.error}
              retry={() => {
                fetchIntegrationOptions();
              }}
            />
          ) : null}
          {IntegrationOptions.data &&
            !IntegrationOptions.loading &&
            !IntegrationOptions.error && (
              <Table
                data={{
                  head: [
                    {
                      element: name + " " + t("_options"),
                      props: { scope: "col" },
                    },
                  ],

                  body: IntegrationOptions.data
                    .filter((option: any) => {
                      if (category2 === "All" || option.id === category2) {
                        return option;
                      }
                    })
                    .map((option: any) => ({
                      id: option.id,
                      elements: [
                        {
                          element: (
                            <>
                              <div
                                className={`hover:bg-gray-50 cursor-pointer select-none flex items-center -my-4 -mx-6 py-4 px-6`}
                                onClick={() => {
                                  clickHandle(option.id, "right", null);
                                }}
                                style={{
                                  backgroundColor:
                                    checkIfPaired(option.id) != -1
                                      ? colors[checkIfPaired(option.id)]
                                      : "",
                                }}
                              >
                                <div className="flex w-full justify-between">
                                  <div className="font-medium text-gray-900">
                                    {option.name}
                                  </div>
                                  <div
                                    className={`
                                      ${
                                        checkIfPaired(option.id) != -1
                                          ? "visible"
                                          : "invisible"
                                      }
                                      bg-red-500 mr-2 text-white p-1 rounded-md hover:opacity-60`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemove(option.id);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-5 w-5"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="text-sm mt-4 -mx-6 -mb-4">
                                {option.items.map((item: any) => {
                                  return (
                                    <div
                                      key={item.id}
                                      className="flex items-center justify-between py-2 border-t px-8 cursor-pointer hover:bg-gray-50"
                                      onClick={() => {
                                        clickHandle(
                                          item.id,
                                          "right",
                                          option.id
                                        );
                                      }}
                                      style={{
                                        backgroundColor:
                                          checkIfPaired(item.id) != -1
                                            ? colors[checkIfPaired(item.id)]
                                            : "",
                                      }}
                                    >
                                      <div>{item.name}</div>
                                      <div
                                        className={`
                                            ${
                                              checkIfPaired(item.id) != -1
                                                ? "visible"
                                                : "invisible"
                                            }
                                            bg-red-500 text-white p-1 rounded-md hover:opacity-60`}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemove(item.id);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-5 w-5"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={1.5}
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          ),
                        },
                      ],
                    })),
                }}
              />
            )}
        </div>
      </div>

      <div className="header-bottom">
        <button
          type="submit"
          disabled={Save.loading}
          className="ml-auto classic-btn"
          onClick={handleSubmittingPairs}
        >
          {Save.loading ? <Spinner className="h-5 m-auto" /> : t("save")}
        </button>
      </div>
    </React.Fragment>
  );
}
