import { Service as HService } from "services/http";

export default class Service {
  private Http: HService | null = null;

  constructor(http: HService) {
    this.Http = http;
  }

  public async get() {
    return await this.Http?.instance.get("/lang");
  }

  public async create(data: any) {
    return await this.Http?.instance.post("/lang", data);
  }

  public async update(id: string, data: any) {
    return await this.Http?.instance.put(`/lang/${id}`, data);
  }

  public async delete(id: string) {
    return await this.Http?.instance.delete(`/lang/${id}`);
  }
}
