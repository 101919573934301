import UserService from "api/user";
import React, { useContext } from "react";
import io from "socket.io-client";

const getUser = async () => {
  const Service = new UserService();
  let user = await Service.getProfile();
  return user;
};

export const socket: any = io(
  `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}`,
  {
    withCredentials: true,
    transports: ["websocket"],
    path: "/socket.io",
    secure: true,
  }
);


(async () => {
    const user = await getUser();
    socket.on("connect", () => {
        let data = { userId: user._id, socketId: socket.id };
        socket.emit("pair", data);
    });
})();


export const SocketContext = React.createContext(null);
