import { Service as HService } from "services/http"

export default class Service {
    private Http: HService | null = null

    constructor(http: HService) {
        this.Http = http
    }

    // public async get(start: number | undefined = undefined, end: number | undefined = undefined) {
    //     const base: URL = new URL(`${this.Http?.baseURL}/createmultitable`);

    //     if (start !== undefined && !Number.isNaN(start))
    //         base.searchParams.append("_start", String(start))

    //     if (end !== undefined && !Number.isNaN(end))
    //         base.searchParams.append("_end", String(end))

    //     return await this.Http?.instance.get(base.href.substring(base.href.lastIndexOf('/')));
    // }

    // public async getById(id: string) {
    //     return await this.Http?.instance.get(`/table/${id}`);
    // }

    public async create(data: any) {
        return await this.Http?.instance.post("/createmultitable", data)
    }

    // public async update(id: string, data: any) {
    //     return await this.Http?.instance.put(`/table/${id}`, data);
    // }

    // public async delete(id: string) {
    //     return await this.Http?.instance.delete(`/table/${id}`)
    // }
}