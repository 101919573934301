import React, { Component } from 'react'
import Product from './product'

interface RankState {
    type: string,
}


export default class Rank extends Component {
    state: RankState = {
        type: "",
    }
    async componentDidMount() {
        this.setState({
            type: window.location.pathname.split('/')[1]
        })

    }
    render() {
        switch(this.state.type){
            case "product":
                return <Product />
            case "opportunity":
                return <Product />
            case "favorite":
                return <Product />
            case "category":
                return <Product />
                break;
            default:
                return null;
        }

    }
}
