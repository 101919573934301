import IntegrationList from "components/integrationList"
import { useTranslation } from "react-i18next"
export default function () {
	const { t } = useTranslation();
    return (
			<>
				<div className="header-top">
					<h2 className="text-xl font-medium pl-2">{t('match-option')}</h2>
				</div>
				<div id="content" className="p-6 flex flex-col gap-y-4 ">

					<h3>{t('can-match-options')}</h3>
					<IntegrationList 
						linkBase="/pair-options/action" 
						emptyMessage={t('pair-option-message')!}
					/>
						
				</div>
			</>
    )
}