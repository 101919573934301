import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Http from "services/http";
import STable from "services/table";
import Response from "services/response";
import Section from "services/section";

import Table from "components/table";
import Modal from "components/modal";
import Loading from "components/loading";
import NetworkError from "components/network-error";
import Spinner from "components/spinner";
import useCache from "services/useCache";
import { useSWRConfig } from "swr";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function () {
  const TableService = new STable(useContext(Http.Context)!);
  const SectionService = new Section(useContext(Http.Context)!);
  //const Tables = new Response();
  const Delete = new Response();

  const [modal, setModal] = useState<boolean>(false);
  const [table, setTable] = useState<any | null>(null);
  const [section, setSection] = useState("All");
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();
  const Tables = useCache(TableService);
  const Sections = useCache(SectionService);

  useEffect(() => {
    if (Delete.data && !Delete.error && !Delete.loading) {
      let newData = Tables.data.filter((d: any) => d.id !== table.id);
      mutate(TableService.endpoint, newData, {
        optimisticData: newData,
        rollbackOnError: true,
      });
      setModal(false);
    } else if (Delete.error && !Delete.loading) {
      toast.error(Delete.error?.response?.data);
      setModal(false);
    }
  }, [Delete.data, Delete.loading]);

  useEffect(() => setModal(Boolean(table)), [table]);
  useEffect(
    () => (
      !modal && setTimeout(() => (setTable(null), (Delete.data = null)), 200),
      undefined
    ),
    [modal]
  );

  return (
    <React.Fragment>
      <Modal
        onClose={() => !Delete.loading && setModal(false)}
        state={[modal, setModal]}
      >
        <React.Fragment>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 p-0.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {table?.title}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {t('delete-table-question')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-actions">
            <button
              disabled={Delete.data || Delete.loading}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => Delete.handle(TableService.delete(table.id))}
            >
              {Delete.data ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : Delete.loading ? (
                <Spinner className="h-5 m-auto" />
              ) : (
                <span>{t('delete')}</span>
              )}
            </button>
            <button
              disabled={Delete.loading}
              type="button"
              className="mt-3 w-full classic-btn justify-center sm:mt-0 sm:ml-3 sm:w-auto"
              onClick={setModal.bind(null, false)}
            >
              {t('cancel')}
            </button>
          </div>
        </React.Fragment>
      </Modal>
      <div className="header-top">
        <div className="relative">
          <select
            value={section}
            onChange={(e) => {
              setSection(e.target.value);
            }}
            className="
                    w-full
                    mt-1
                    rounded
                    bg-gray
                    focus:border-ebony focus:bg-white focus:ring-0"
            aria-label="Filter Product By Category"
          >
            <option value="All" className="hover:bg-ebony hover:text-white">
              {t('sections')}
            </option>
            {Sections?.data?.map((section: any) => (
              <option
                key={section.id}
                className="hover:bg-ebony hover:text-white"
                value={section.id}
              >
                {section.title}
              </option>
            ))}
          </select>
        </div>
        <div className="flex ml-auto">
          <Link
            to="/table/multipleTable"
            className="flex items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium mr-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            <span>{t('add-multi-table')}</span>
          </Link>
          <Link
            to="/table/action"
            className="flex items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium mr-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            <span>{t('add-table')}</span>
          </Link>
        </div>
      </div>
      <div id="content">
        <Loading done={Tables.data} />
        {!Tables.error ? (
          <NetworkError
            error={Tables.error}
            retry={() => {
              mutate(TableService.endpoint);
            }}
          />
        ) : null}
        {Tables.data && !Tables.error && (
          <Table
            data={{
              head: [
                { element: t('table'), props: { scope: "col" } },
                { element: t('created-at'), props: { scope: "col" } },
                {
                  element: t('actions'),
                  props: { scope: "col" },
                  screenReader: true,
                },
              ],
              body: Tables.data
                .filter((table: any) => {
                  if (section === "All" || table.section === section) {
                    return table;
                  } else if (section === "All" || table.section === section) {
                    return table;
                  }
                })
                .map((table: any) => ({
                  id: table.id,
                  elements: [
                    
                    {            
                      element: (
                        <div className="flex items-center">
                          <div className="text-sm font-medium text-gray-900">
                          {(Sections?.data?.find((s: any) => s.id == table.section)? Sections?.data?.find((s: any) => s.id == table.section).title :"")} {table.title}
                          </div>
                        </div>
                      ),
                    },
                    {
                      element: new Date(table.createdAt).toLocaleDateString(
                        "tr-TR",
                        {
                          minute: "numeric",
                          hour: "numeric",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      ),
                      props: { className: "text-left text-sm text-gray-900" },
                    },
                    {
                      element: (
                        <>
                          <Link
                            to={`/table/action/${table.id}`}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            {t('edit')}
                          </Link>
                          <button
                            onClick={setTable.bind(null, table)}
                            className="text-red-600 hover:text-red-900"
                          >
                            {t('delete')}
                          </button>
                        </>
                      ),
                      props: {
                        className: "text-right text-sm font-medium space-x-6",
                      },
                    },
                  ],
                })),
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
