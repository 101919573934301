import React, { Component } from 'react'
import { Box, TextField, Button, Stack, ButtonGroup, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Loading from 'components/loading';
import NoReport from './no-report';
import axios from 'axios';
import { toast } from 'react-toastify';
import { withTranslation, WithTranslation } from 'react-i18next';
import { exportReports, numberWithCommas } from 'services/helpers/excel';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { convertCurrency, defaultCurrency } from 'services/helpers/exchange';

interface EndrosementReportState {
  reportData: any,
  from: any,
  to: any,
  loading: boolean,
}

interface IProps extends WithTranslation { }

const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_NAME!);

class Index extends Component<IProps> {
  state: EndrosementReportState = {
    reportData: {},
    from: new Date(Date.now() - 86400000),
    to: new Date(),
    loading: true,
  }
  async componentDidMount() {
    let data: any = await axios.get(`manager/report/endorsement`, {
      baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'x-refresh': refreshToken!
      },
      params: {
        from: new Date(this.state.from).getTime(),
        to: new Date(this.state.to).getTime(),
      }
    });
    this.setState({
      reportData: data.data,
      loading: false
    })
  }

  
  exportReport = () => {
    let fields = [
      {
        label: this.props.t('branch'),
        value: (row: any, field: any) => row.branchTitle
      },
      {
        label: this.props.t('total-revenue'),
        value: (row: any, field: any) => numberWithCommas(row.totalRevenue)
      },
      {
        label: this.props.t('total-tips'),
        value: (row: any, field: any) => numberWithCommas(row.totalTips)
      },
      {
        label: this.props.t('costs'),
        value: (row: any, field: any) => numberWithCommas(row.totalExpenses)
      },
      {
        label: this.props.t('total-catering'),
        value: (row: any, field: any) => numberWithCommas(row.totalCateringAmount)
      },
      {
        label: this.props.t('endorsement'),
        value: (row: any, field: any) => numberWithCommas(row.endorsement)
      }
    ]
    let data = this.state?.reportData?.branchReports;
    exportReports(fields, data, this.props.t('endorsement'), new Date(this.state.from).toLocaleDateString(), new Date(this.state.to).toLocaleDateString(), this.props.t('general'));
  }

  syncData = async () => {
    if (!new Date(this.state.from)?.getTime() || !new Date(this.state.to)?.getTime()) {
      return;
    }
    if (this.state.from > this.state.to) {
      toast.warning(this.props.t('date-warning'));
      return;
    }
    this.setState({
      loading: true
    });
    let data: any = await axios.get(`manager/report/endorsement`, {
      baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'x-refresh': refreshToken!
      },
      params: {
        from: new Date(this.state.from).getTime(),
        to: new Date(this.state.to).getTime(),
      }
    });
    this.setState({
      reportData: data.data,
      loading: false
    });
  }

  columns: GridColDef[] = [
    {
      field: "branchTitle",
      headerName: this.props.t('branch-title')!,
      flex: 2,
      renderCell: (params) => (
        <strong>
          {params.value}
        </strong>
      )
    },
    {
      field: "totalRevenue",
      headerName: this.props.t('total-revenue')!,
      flex: 1,
      renderCell: (params) => (
        // <>{params.value?.toFixed(2)} ₺</>
        <>{convertCurrency(params.value)} {defaultCurrency()}</>
      )
    },
    {
      field: "totalTips",
      headerName: this.props.t('tips-revenue')!,
      flex: 1,
      renderCell: (params) => (
        <>{convertCurrency(params.value)} {defaultCurrency()}</>
      )
    },
    {
      field: "totalExpenses",
      headerName: this.props.t('expense-amounts')!,
      flex: 1,
      renderCell: (params) => (
        <>{convertCurrency(params.value)} {defaultCurrency()}</>
      )
    },
    {
      field: "notPayable",
      headerName: this.props.t('not-payable-amount')!,
      flex: 1,
      renderCell: (params) => (
        <>{convertCurrency(params.value)} {defaultCurrency()}</>
      )
    },
    {
      field: "totalCateringAmount",
      headerName: this.props.t('total-catering')!,
      flex: 1,
      renderCell: (params) => (
        <>{convertCurrency(params.value)} {defaultCurrency()}</>
      )
    },
    {
      field: "endorsement",
      headerName: this.props.t('endorsement')!,
      flex: 1,
      renderCell: (params) => (
        <>{convertCurrency(params.value)} {defaultCurrency()}</>
      )
    }
  ]

  TableReport = () => {
    return (
      <Box sx={{ marginBottom: "5vw" }}>
        <Box>
          <Box sx={{
            height: 600,
            width: "100%",
            backgroundColor: "#ededed",
            color: "#000",
            '& .row-class': {
              bgcolor: "#ededed",
              color: "#000",
              '&:hover': {
                color: "#000"
              },
              '&:selected': {
                color: "#fff"
              }
            }
          }}
          >
            <DataGrid
              rows={this.state.reportData.branchReports || []}
              columns={this.columns}
              sx={{color: "#000"}}
              getRowClassName={(params: any) => 'row-class'}
              disableSelectionOnClick

            />
          </Box>
        </Box>
      </Box>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <Loading />
        </>
      )
    }
    return (
      <Box>
        <Box sx={{
          marginTop: "1.3vw"
        }}>
          <Stack direction="row" spacing={3} alignItems="center">
            <Grid container justifyContent="left">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" spacing={2}>
                  <DateTimePicker
                    label={this.props.t('start-date')!}
                    inputFormat="DD/MM/YYYY HH:mm"
                    value={this.state.from}
                    onChange={async (value: any) => {
                      await this.setState({
                        from: value
                      });
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                  />
                  <DateTimePicker
                    label={this.props.t('end-date')}
                    inputFormat="DD/MM/YYYY HH:mm"
                    value={this.state.to}
                    onChange={async (value: any) => {
                      await this.setState({
                        to: value
                      });
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid container justifyContent="right">
              <ButtonGroup orientation='vertical' sx={{ margin: "auto", marginRight: "1vw" }}>
                <Button variant="contained" color="inherit" sx={{ border: "1px solid black" }} onClick={() => {
                  this.syncData();
                }}>{this.props.t('get-reports')}</Button>
                <Button startIcon={<FileDownloadIcon />} color="inherit" sx={{ border: "1px solid black" }} variant="contained" onClick={() => this.exportReport()}>{this.props.t('export-reports')}</Button>
              </ButtonGroup>
            </Grid>
          </Stack>
        </Box>
        <Box sx={{ marginTop: "4vw" }}>
          <this.TableReport />
        </Box>
      </Box>
    )
  }
}

export default withTranslation()(Index);
