import React, { Component, useState } from "react";
import {
    Select,
    MenuItem,
    Box,
    Button,
    InputLabel,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Collapse,
    Typography,
    Toolbar,
    FormHelperText,
    ButtonGroup,
    Stack,
    FormControl,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Loading from "components/loading";
import { toast } from "react-toastify";
import NoReport from "../no-report";
import { WithTranslation, withTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { PaymentTypes } from "services/interfaces/report/table";
import FileDownload from "@mui/icons-material/FileDownload";
import { parseCrewReport } from "services/helpers/excel";
import { convertCurrency, defaultCurrency } from "services/helpers/exchange";

type CrewReportState = {
    reportData: any;
    userBranch: any;
    subBranches: any;
    selectedBranch: string;
    startdate: any;
    enddate: any;
    loading: boolean;
};

interface IProps extends WithTranslation { }

const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(
    process.env.REACT_APP_REFRESH_TOKEN_NAME!
);

class CrewReport extends Component<IProps> {
    state: CrewReportState = {
        reportData: [],
        userBranch: {},
        subBranches: [],
        selectedBranch: "",
        startdate: new Date(Date.now() - 86400000),
        enddate: new Date(),
        loading: true,
    };

    async componentDidMount() {
        let branch: any = await axios.get(`manager/branches`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                Authorization: `Bearer ${token}`,
                "x-refresh": refreshToken!,
            },
        });
        let data: any = await axios.get(
            `manager/report/crew/${branch?.data[0]?._id}`,
            {
                baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "x-refresh": refreshToken!,
                },
                params: {
                    from: new Date(this.state.startdate).getTime(),
                    to: new Date(this.state.enddate).getTime(),
                },
            }
        );
        this.setState({
            reportData: data.data,
            userBranch: branch?.data[0],
            subBranches: branch?.data[0]?.subBranch,
            selectedBranches: [branch?.data[0]?._id],
            selectedBranch: branch?.data[0]?._id,
            loading: false,
        });
    }

    CalculateTotalAmount = (data: any, isExpense?: boolean) => {
        let total = 0;
        let quantity = 0
        data.map((d: any) => {
            if(!d.price && !d.amount){
                return;
            }
            total += d.price || d.amount;
            if (isExpense) {
                quantity++;
                return;
            }
            quantity += d.quantity;
        })
        return { total, quantity };
    }

    Report = (data: any) => {
        const [expanded, setExpanded] = useState(false);
        return (data.payments?.length > 0 || data.products?.length > 0 || data.cancelledProducts?.length > 0 || data.caterings?.length > 0 || data.expenses?.length > 0 || data.discounts?.length > 0 || data.ticks?.length > 0)?(
            <Box sx={{ marginTop: "5vw" }}>
                <Typography
                    component="div"
                    sx={{ cursor: "pointer" }}
                    onClick={() => setExpanded(!expanded)}
                >
                    <Box
                        sx={{
                            textAlign: "center",
                            backgroundColor: "#ededed",
                            borderRadius: "10px 10px 0 0",
                            color: "#000",
                            fontWeight: "bold",
                            fontSize: "1.23vw",
                            "& Table": {
                                maxHeight: "5vh"
                            }
                        }}
                    >
                        {data.name}{" "}
                        {expanded ? (
                            <KeyboardArrowUpIcon fontSize="large" />
                        ) : (
                            <KeyboardArrowDownIcon fontSize="large" />
                        )}
                    </Box>
                </Typography>
                <Collapse in={expanded}>
                    <Box
                        sx={{
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "space-evenly",
                        }}
                    > 
                        {data.payments?.length > 0 ? (
                            <Box sx={{ width: "50%" }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >{this.props.t('checkouts')}</Typography>
                                </Toolbar>
                                <TableContainer sx={{ minminHeight: 250 }}>
                                    <Table stickyHeader sx={{ border: 2, borderRadius: 1, borderColor: "primary.main" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{this.props.t('payment_type')}</TableCell>
                                                <TableCell>{this.props.t('amount')}</TableCell>
                                                <TableCell>{this.props.t('currency')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.payments.map((payment: any) => (
                                                <TableRow>
                                                    <TableCell>{this.props.t(PaymentTypes[(payment.type) as keyof typeof PaymentTypes])}</TableCell>
                                                    <TableCell>{convertCurrency(payment.amount)}</TableCell>
                                                    <TableCell>{payment.currency}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell>{this.props.t('total')}</TableCell>
                                                <TableCell>{convertCurrency(this.CalculateTotalAmount(data.payments).total)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : null}
                        {data.products?.length > 0 ? (
                            <Box sx={{ width: "50%" }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >{this.props.t('sold-products')}</Typography>
                                </Toolbar>
                                <TableContainer sx={{ minHeight: 250 }}>
                                    <Table stickyHeader sx={{ border: 2, borderRadius: 1, borderColor: "primary.main" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{this.props.t('product-name')}</TableCell>
                                                <TableCell>{this.props.t('quantity')}</TableCell>
                                                <TableCell>{this.props.t('amount')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.products.map((product: any) => (
                                                <TableRow>
                                                    <TableCell>{product.productName}</TableCell>
                                                    <TableCell>{convertCurrency(product.quantity)}</TableCell>
                                                    <TableCell>{convertCurrency(product.price)} {defaultCurrency()}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell>{this.props.t('total')}</TableCell>
                                                <TableCell>{convertCurrency(this.CalculateTotalAmount(data.products).quantity)}</TableCell>
                                                <TableCell>{convertCurrency(this.CalculateTotalAmount(data.products).total)} {defaultCurrency()}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : null}
                        {data.cancelledProducts?.length > 0 ? (
                            <Box sx={{ width: "50%" }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >{this.props.t('cancelled-products')}</Typography>
                                </Toolbar>
                                <TableContainer sx={{ minHeight: 250 }}>
                                    <Table stickyHeader sx={{ border: 2, borderRadius: 1, borderColor: "primary.main" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{this.props.t('product-name')}</TableCell>
                                                <TableCell>{this.props.t('quantity')}</TableCell>
                                                <TableCell>{this.props.t('amount')}</TableCell>
                                                <TableCell>{this.props.t('reason')}</TableCell>
                                                <TableCell>{this.props.t('cancellation-date')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.cancelledProducts.map((product: any) => (
                                                <TableRow>
                                                    <TableCell>{product.productName}</TableCell>
                                                    <TableCell>{convertCurrency(product.quantity)}</TableCell>
                                                    <TableCell>{convertCurrency(product.price)} {defaultCurrency()}</TableCell>
                                                    <TableCell>{product.cancelReason}</TableCell>
                                                    <TableCell>{new Date(product.cancellationDate).toLocaleDateString()}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell>{this.props.t('total')}</TableCell>
                                                <TableCell>{convertCurrency(this.CalculateTotalAmount(data.cancelledProducts).quantity)}</TableCell>
                                                <TableCell>{convertCurrency(this.CalculateTotalAmount(data.cancelledProducts).total)} {defaultCurrency()}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : null}
                        {data.caterings?.length > 0 ? (
                            <Box sx={{ width: "50%" }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >{this.props.t('caterings')}</Typography>
                                </Toolbar>
                                <TableContainer sx={{ minHeight: 250 }}>
                                    <Table stickyHeader sx={{ border: 2, borderRadius: 1, borderColor: "primary.main" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{this.props.t('product-name')}</TableCell>
                                                <TableCell>{this.props.t('quantity')}</TableCell>
                                                <TableCell>{this.props.t('amount')}</TableCell>
                                                <TableCell>{this.props.t('reason')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.caterings.map((catering: any) => (
                                                <TableRow>
                                                    <TableCell>{catering.productName}</TableCell>
                                                    <TableCell>{convertCurrency(catering.quantity)}</TableCell>
                                                    <TableCell>{convertCurrency(catering.price)} {defaultCurrency()}</TableCell>
                                                    <TableCell>{catering.serveId}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell>{this.props.t('total')}</TableCell>
                                                <TableCell>{convertCurrency(this.CalculateTotalAmount(data.caterings).quantity)}</TableCell>
                                                <TableCell>{convertCurrency(this.CalculateTotalAmount(data.caterings).total)} {defaultCurrency()}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : null}
                        {data.expenses?.length > 0 ? (
                            <Box sx={{ width: "50%" }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >{this.props.t('expenses')}</Typography>
                                </Toolbar>
                                <TableContainer sx={{ minHeight: 250 }}>
                                    <Table stickyHeader sx={{ border: 2, borderRadius: 1, borderColor: "primary.main" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{this.props.t('title')}</TableCell>
                                                <TableCell>{this.props.t('description')}</TableCell>
                                                <TableCell>{this.props.t('payment_type')}</TableCell>
                                                <TableCell>{this.props.t('amount')}</TableCell>
                                                <TableCell>{this.props.t('currency')}</TableCell>
                                                <TableCell>{this.props.t('created-at')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.expenses.map((expense: any) => (
                                                <TableRow>
                                                    <TableCell>{expense.title}</TableCell>
                                                    <TableCell>{expense.description}</TableCell>
                                                    <TableCell>{this.props.t(PaymentTypes[expense.payment_type as keyof typeof PaymentTypes])}</TableCell>
                                                    <TableCell>{convertCurrency(expense.amount)}</TableCell>
                                                    <TableCell>{defaultCurrency()}</TableCell>
                                                    <TableCell>{new Date(expense.createdAt).toLocaleDateString()}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell>{this.props.t('total')}</TableCell>
                                                <TableCell><FormHelperText>{this.props.t('total-expense-count')}</FormHelperText>{convertCurrency(this.CalculateTotalAmount(data.expenses, true).quantity)}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{convertCurrency(this.CalculateTotalAmount(data.expenses, true).total)} {defaultCurrency()}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : null}
                        {data.discounts?.length > 0 ? (
                            <Box sx={{ width: "50%" }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >{this.props.t('applied-discounts')}</Typography>
                                </Toolbar>
                                <TableContainer sx={{ minHeight: 250 }}>
                                    <Table stickyHeader sx={{ border: 2, borderRadius: 1, borderColor: "primary.main" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{this.props.t('note')}</TableCell>
                                                <TableCell>{this.props.t('amount')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.discounts.map((discount: any) => (
                                                <TableRow>
                                                    <TableCell>{discount.note}</TableCell>
                                                    <TableCell>{convertCurrency(discount.amount) } {defaultCurrency()}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell>{this.props.t('total')}</TableCell>
                                                <TableCell>{convertCurrency(this.CalculateTotalAmount(data.discounts, true).total)} {defaultCurrency()}</TableCell>
                                                <TableCell><FormHelperText>{this.props.t('total-discount-count')}</FormHelperText>{this.CalculateTotalAmount(data.discounts, true).quantity}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : null}
                        {data.ticks?.length > 0 ? (
                            <Box sx={{ width: "50%" }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >{this.props.t('paid-ticks')}</Typography>
                                </Toolbar>
                                <TableContainer sx={{ minHeight: 250 }}>
                                    <Table stickyHeader sx={{ border: 2, borderRadius: 1, borderColor: "primary.main" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{this.props.t('customer')}</TableCell>
                                                <TableCell>{this.props.t('amount')}</TableCell>
                                                <TableCell>{this.props.t('currency')}</TableCell>
                                                <TableCell>{this.props.t('date')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.ticks.map((tick: any) => (
                                                <TableRow>
                                                    <TableCell>{tick.customer}</TableCell>
                                                    <TableCell>{convertCurrency(tick.amount)}</TableCell>
                                                    <TableCell>{defaultCurrency()}</TableCell>
                                                    <TableCell>{new Date(tick.createdAt).toLocaleDateString()}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell>{this.props.t('total')}</TableCell>
                                                <TableCell>{convertCurrency(this.CalculateTotalAmount(data.ticks, true).total)} {defaultCurrency()}</TableCell>
                                                <TableCell><FormHelperText>{this.props.t('total-tick-amount')}</FormHelperText>{convertCurrency(this.CalculateTotalAmount(data.ticks, true).quantity)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : null}
                    </Box>
                </Collapse>
            </Box>
        ):(<div></div>);
    };

    exportReports = () => {
        if (this.state.reportData.length == 0) {
            toast.warning('no-ready-report');
            return;
        }
        let branch = [this.state.userBranch].concat(this.state.subBranches)?.find((b: any) => b._id == this.state.selectedBranch)?.title;
        parseCrewReport(this.state.reportData, branch);
    }

    syncData = async () => {
        if (
            !new Date(this.state.startdate).getTime() ||
            !new Date(this.state.enddate).getTime()
        ) {
            this.setState({
                reportData: [],
            });
            return;
        }
        if (this.state.startdate > this.state.enddate) {
            toast.warning(this.props.t("date-warning"));
            return;
        }
        if (this.state.enddate >= this.state.startdate) {
            this.setState({ loading: true });
            let data: any = await axios.get(
                `manager/report/crew/${this.state.selectedBranch}`,
                {
                    baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "x-refresh": refreshToken!,
                    },
                    params: {
                        from: new Date(this.state.startdate).getTime(),
                        to: new Date(this.state.enddate).getTime(),
                    },
                }
            );
            this.setState({
                reportData: data.data,
                loading: false,
            });
        }
    };
    render() {
        if (this.state.loading) {
            return (
                <>
                    <Loading />
                </>
            );
        }
        return (
            <React.Fragment>
                <Box sx={{ overflow: "auto" }}>
                    <Stack direction="row" sx={{ marginTop: 1 }}>
                        <Grid container justifyContent="left">
                        {[this.state.userBranch]
                        .concat(this.state.subBranches).length>1? <FormControl sx={{ marginTop:1, marginLeft:1}}  >     
                          <InputLabel id="branchLabel">{this.props.t('branch')}</InputLabel>
                                <Select
                                    labelId="branchLabel"
                                    value={this.state.selectedBranch}
                                    onChange={async (e: any) => {
                                        await this.setState({
                                            selectedBranch: e.target.value,
                                        });
                                    }}
                                >
                                    {[this.state.userBranch]
                                        .concat(this.state.subBranches)
                                        .map((branch: any) => (
                                            <MenuItem value={branch._id}>{branch.title}</MenuItem>
                                        ))}
                                </Select>
                            </FormControl>:<></>}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack direction="row" spacing={1} sx={{marginTop:1, marginLeft:1}}>
                                    <DateTimePicker
                                        label={this.props.t('start-date')!}
                                        inputFormat="DD/MM/YYYY HH:mm"
                                        value={this.state.startdate}
                                        ampm={false}
                                        onChange={async (value: any) => {
                                            await this.setState({
                                                startdate: value,
                                            });
                                        }}
                                        renderInput={(params: any) => <TextField {...params} />}
                                    />
                                    <DateTimePicker
                                        label={this.props.t('end-date')!}
                                        inputFormat="DD/MM/YYYY HH:mm"
                                        value={this.state.enddate}
                                        ampm={false}
                                        onChange={async (value: any) => {
                                            await this.setState({
                                                enddate: value,
                                            });
                                        }}
                                        renderInput={(params: any) => <TextField {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </Grid>
                        <Grid container justifyContent="right">
                            <ButtonGroup sx={{ marginRight: "1vw" }} orientation="vertical">
                                <Button variant="contained" color="inherit" onClick={() => {
                                    this.syncData();
                                }}>{this.props.t('get-reports')}</Button>
                                <Button variant="contained" startIcon={<FileDownload />} onClick={() => this.exportReports()} sx={{ backgroundColor: "#717D8C" }}>{this.props.t('export-reports')}</Button>
                            </ButtonGroup>
                        </Grid>
                    </Stack>

                    <Box sx={{ marginTop: "4vw" }}>
                        {this.state.reportData?.length == 0 ? (
                            <>
                                <NoReport />
                            </>
                        ) : (
                            <>
                                {this.state.reportData?.map((data: any) => (
                                    <this.Report key={data.id} {...data} />
                                ))}
                            </>
                        )}
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default withTranslation()(CrewReport);
