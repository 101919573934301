import { Service as HService } from "services/http"

export default class Service {
    private Http: HService | null = null

    constructor(http: HService) {
        this.Http = http
    }

    public async getReport(type: string, start: string, end: string) {
        const base: URL = new URL(`${this.Http?.baseURL}/report/${type}`);

        if (start !== undefined && !Number.isNaN(start))
            base.searchParams.append("startdate", String(start))

        if (end !== undefined && !Number.isNaN(end))
            base.searchParams.append("enddate", String(end))
        return await this.Http?.instance.get(("report" + base.href.substring(base.href.lastIndexOf('/')) + "&per=86400000"));
    }
}