import { useEffect, useContext } from "react";
import {
  Outlet,
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import Http from "services/http";
import Authenticate, { Service } from "services/authenticate";

import Auth from "components/middleware/auth";
import Layout from "components/layout";

import Index from "pages/index";

import Product from "pages/product";
import ProductAction from "pages/product/action";

import ProductGroup from "pages/productGroup";
import ProductGroupAction from "pages/productGroup/action";
import ProductGroupEdit from "pages/productGroup/edit";

import Branches from "pages/branch";

import Option from "pages/option";
import OptionAction from "pages/option/action";

import Category from "pages/category";
import CategoryAction from "pages/category/action";

import Section from "pages/section";
import SectionAction from "pages/section/action";

import Table from "pages/table";
import TableAction from "pages/table/action";

import Case from "pages/case";
import CaseAction from "pages/case/action";
import CaseSalesSlip from "pages/case/salesSlip";

import OpenTicket from "pages/openTickets/openTicket";

import Member from "pages/member";
import MemberAction from "pages/member/action";

import Settings from "pages/settings";
import PaymentMethods from "pages/settings/payment-methods";
import SettingsAction from "pages/settings/action";

import Integration from "pages/integration";
import IntegrationAction from "pages/integration/action";
import IntegrationGetir from "pages/integration/getir";
import IntegrationTrendyol from "pages/integration/trendyol";

import Report from "pages/reports";
import ProductReport from "pages/reports/product/product";
import CategoryReport from "pages/reports/category/category";
import OrderTypeReport from "pages/reports/order_type/order_type";
import PaymentTypeReport from "pages/reports/payment_type/payment_type";
import CrewReport from "pages/reports/crew/crew";
import TableReport from "pages/reports/table/table";
import ExpenseReport from "pages/reports/expense/expense";
import NotPayableReport from "pages/reports/not-payable/not-payable";
import DetailedReport from "pages/reports/detailed";

import Advice from "pages/advice";

import PairProducts from "pages/pairProducts";
import PairProductsAction from "pages/pairProducts/action";

import PairOptions from "pages/pairOptions";
import PairOptionsAction from "pages/pairOptions/action";

import SignIn from "pages/sign-in";
import MultipleTable from "pages/table/multipleTable";

import Rank from "pages/rank";
import CaseReport from "pages/reports/case/case";
import { DevicePairings } from "pages/devicePairings";
import { BannedDevices } from "pages/bannedDevices";
import { HuginIntegration } from "pages/huginIntegration";
import CancelPage from "pages/reports/cancel/cancel";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="text-center">
      <h2 className="text-6xl font-bold">404</h2>
      <h3 className="text-xl font-medium">Sayfa bulunamadı</h3>
      <button className="text-sm" onClick={() => navigate(-1)}>
        Geri dön
      </button>
    </div>
  );
};

export default function () {
  const AuthContext = useContext(Authenticate.Context);
  const HttpContext = useContext(Http.Context);

  useEffect(() => {
    AuthContext?.Context?.tokens.access && AuthContext?.Context?.tokens.refresh
      ? AuthContext.storeTokens()
      : Service.removeStoredTokens();

    AuthContext &&
      (AuthContext.User = Service.parseUserFromToken(
        AuthContext?.Context?.tokens.access
      ));
    AuthContext &&
      AuthContext.User &&
      (AuthContext.IsAuthenticated = AuthContext.verifyExpireTime());

    AuthContext &&
      AuthContext.IsAuthenticated &&
      HttpContext?.addHeader("Authorization", AuthContext.bearerToken());
  }, [AuthContext?.Context?.tokens]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Auth />}>
          <Route element={<Layout />}>
            <Route index element={<Index />} />
            <Route path="/panel" element={<Index />} />
            <Route path="/dashboard" element={<Index />} />

            <Route path="open-ticket" element={<Outlet />}>
              <Route index element={<OpenTicket />} />
            </Route>

            <Route path="product" element={<Outlet />}>
              <Route index element={<Product />} />
              <Route path="action" element={<ProductAction />}>
                <Route path=":id" element={<ProductAction />} />
              </Route>
              <Route path="rank" element={<Rank />} />
            </Route>

            <Route path="opportunity" element={<Outlet />}>
              <Route index element={<Rank />} />
            </Route>

            <Route path="opportunity" element={<Outlet />}>
              <Route path="rank" element={<Rank />} />
            </Route>

            <Route path="favorite" element={<Outlet />}>
              <Route path="rank" element={<Rank />} />
            </Route>

            <Route path="category" element={<Outlet />}>
              <Route path="rank" element={<Rank />} />
            </Route>

            <Route path="product-group" element={<Outlet />}>
              <Route index element={<ProductGroup />} />
              <Route path="action" element={<ProductGroupAction />} />
              <Route path="action/:id" element={<ProductGroupEdit />} />
            </Route>

            <Route path="sub-branches" element={<Outlet />}>
              <Route index element={<Branches />} />
            </Route>
            <Route path="option" element={<Outlet />}>
              <Route index element={<Option />} />
              <Route path="action" element={<OptionAction />}>
                <Route path=":id" element={<OptionAction />} />
              </Route>
            </Route>

            <Route path="category" element={<Outlet />}>
              <Route index element={<Category />} />
              <Route path="action" element={<CategoryAction />}>
                <Route path=":id" element={<CategoryAction />} />
              </Route>
            </Route>

            <Route path="section" element={<Outlet />}>
              <Route index element={<Section />} />
              <Route path="action" element={<SectionAction />}>
                <Route path=":id" element={<SectionAction />} />
              </Route>
            </Route>

            <Route path="table" element={<Outlet />}>
              <Route index element={<Table />} />
              <Route path="action" element={<TableAction />}>
                <Route path=":id" element={<TableAction />} />
              </Route>
              <Route path="multipleTable" element={<MultipleTable />} />
            </Route>

            <Route path="case" element={<Outlet />}>
              <Route index element={<Case />} />
              <Route path="action" element={<CaseAction />}>
                <Route path=":id" element={<CaseAction />} />
              </Route>
              <Route path="salesSlip" element={<CaseSalesSlip />}>
                <Route path=":id" element={<CaseSalesSlip />} />
              </Route>
            </Route>

            <Route path="member" element={<Outlet />}>
              <Route index element={<Member />} />
              <Route path="action" element={<MemberAction />}>
                <Route path=":id" element={<MemberAction />} />
              </Route>
            </Route>

            <Route path="settings" element={<Outlet />}>
              <Route index element={<Settings />} />
              <Route
                path="payment-methods"
                element={<PaymentMethods />}
              ></Route>
              <Route path="action/:path" element={<SettingsAction />}>
                <Route path=":id" element={<SettingsAction />} />
              </Route>
            </Route>

            <Route path="reports" element={<Outlet />}>
              <Route index element={<Report />} />
              <Route path="detailed" element={<DetailedReport />} />
              <Route path="product" element={<ProductReport />} />
              <Route path="category" element={<CategoryReport />} />
              <Route path="ordertype" element={<OrderTypeReport />} />
              <Route path="paymenttype" element={<PaymentTypeReport />} />
              <Route path="crew" element={<CrewReport />} />
              <Route path="case" element={<CaseReport />} />
              <Route path="table" element={<TableReport />} />
              <Route path="expense" element={<ExpenseReport />} />
              <Route path="not-payable" element={<NotPayableReport />} />
              <Route path="cancel" element={<CancelPage />} />
            </Route>

            <Route path="integration" element={<Outlet />}>
              <Route index element={<Integration />} />
              <Route path="action" element={<IntegrationAction />} />
              <Route path="getir" element={<IntegrationGetir />} />
              <Route path="trendyol" element={<IntegrationTrendyol />} />
            </Route>

            <Route path="advices" element={<Outlet />}>
              <Route index element={<Advice />} />
            </Route>

            <Route path="pair-products" element={<Outlet />}>
              <Route index element={<PairProducts />} />
              <Route
                path="action/:name"
                element={<PairProductsAction />}
              ></Route>
            </Route>

            <Route path="pair-options" element={<Outlet />}>
              <Route index element={<PairOptions />} />
              <Route
                path="action/:name"
                element={<PairOptionsAction />}
              ></Route>
            </Route>

            <Route path="hugin-integration" element={<Outlet />}>
              <Route index element={<HuginIntegration />} />
            </Route>
            <Route path="device-pairings" element={<Outlet />}>
              <Route index element={<DevicePairings />} />
              <Route path="banned-devices" element={<BannedDevices />} />
            </Route>
          </Route>
        </Route>

        <Route path="sign-in" element={<SignIn />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
