import React, { useEffect, useState } from "react";
import { ButtonGroup, Button, FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";

const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(
  process.env.REACT_APP_REFRESH_TOKEN_NAME!
);

export default function CurrencySettings() {
  const { t } = useTranslation();
  const [defaultCurrency, setCurrency] = useState("");
  const [reportCurrency, setReportCurrency] = useState("");

  useEffect(() => {
    const fetchCurrency = async () => {
      let response = await axios.get("manager/currency", {
        baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
        headers: {
          Authorization: `Bearer ${token}`,
          "x-refresh": refreshToken!,
        },
      });
      if (response.data?.defaultCurrency) {
        setCurrency(response.data?.defaultCurrency);
        setReportCurrency(response.data?.defaultCurrency);
      }
      if (localStorage?.getItem("reportCurrency")) {
        setReportCurrency(localStorage?.getItem("reportCurrency")!);
      } else if (response.data?.defaultCurrency) {
        localStorage.setItem("reportCurrency", response.data?.defaultCurrency);
      }
    };
    fetchCurrency();
  }, []);

  const setDefaultCurrency = async (currency: string) => {
    let url = `https://api.exchangerate.host/latest?base=${currency}&symbols=EUR,GBP,USD,TRY`;
    let response: any = await axios.get(url);
    if (response.status != 200) {
      toast.error("Internal Server Error");
      return;
    }
    let rates = response.data.rates;

    let currencies = {
      TL: currency != "TRY" ? Number((1 / rates.TRY)?.toFixed(3)) : 1,
      USD: currency != "USD" ? Number((1 / rates.USD)?.toFixed(3)) : 1,
      EUR: currency != "EUR" ? Number((1 / rates.EUR)?.toFixed(3)) : 1,
      GBP: currency != "GBP" ? Number((1 / rates.GBP)?.toFixed(3)) : 1,
    };
    let postData = {
      currencySettings: {
        defaultCurrency: currency == "TRY" ? "TL" : currency,
        currencies: currencies,
      },
    };
    let post = await axios.post("manager/currency", postData, {
      validateStatus: function (status: number) {
        return true;
      },
      baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
      headers: {
        Authorization: `Bearer ${token}`,
        "x-refresh": refreshToken!,
      },
    });
    if (post.status == 409) {
      toast.error(t("default-currency-already-set"));
      return;
    }
    if (post.status == 200) {
      setCurrency(currency == "TRY" ? "TL" : currency);
      toast.success(t("set-currency-success"));
      return;
    }
    toast.error(t("server-error"));
  };

  const selectReportCurrency = (currency: string) => {
    setReportCurrency(currency);
    localStorage.setItem("reportCurrency", currency);
  };

  return (
    <div>
      <div style={{ marginLeft: "1rem" }} className="mx-auto p-2 w-full">
        <h3 className="text-xl font-medium pl-2">
          {t("select-default-currency")}
        </h3>
        <FormHelperText>
          <span style={{ color: "red" }}>{t("attention")} </span>
          {t("set-default-currency-info")}
        </FormHelperText>
        <ButtonGroup
          disabled={defaultCurrency ? true : false}
          className="w-full mx-auto p-2"
        >
          <Button
            value="TRY"
            onClick={(e: any) => setDefaultCurrency(e.target.value)}
            sx={{
              backgroundColor: defaultCurrency == "TL" ? "#00700C" : "#928F8F",
              "&:disabled": {
                backgroundColor:
                  defaultCurrency == "TL" ? "#00700C" : "#928F8F",
                color: "#fff",
              },
            }}
            variant="contained"
          >
            TL
          </Button>
          <Button
            value="USD"
            onClick={(e: any) => setDefaultCurrency(e.target.value)}
            sx={{
              backgroundColor: defaultCurrency == "USD" ? "#00700C" : "#928F8F",
              "&:disabled": {
                backgroundColor:
                  defaultCurrency == "USD" ? "#00700C" : "#928F8F",
                color: "#fff",
              },
            }}
            variant="contained"
          >
            USD
          </Button>
          <Button
            value="EUR"
            onClick={(e: any) => setDefaultCurrency(e.target.value)}
            sx={{
              backgroundColor: defaultCurrency == "EUR" ? "#00700C" : "#928F8F",
              "&:disabled": {
                backgroundColor:
                  defaultCurrency == "EUR" ? "#00700C" : "#928F8F",
                color: "#fff",
              },
            }}
            variant="contained"
          >
            EUR
          </Button>
          <Button
            value="GBP"
            onClick={(e: any) => setDefaultCurrency(e.target.value)}
            sx={{
              backgroundColor: defaultCurrency == "GBP" ? "#00700C" : "#928F8F",
              "&:disabled": {
                backgroundColor:
                  defaultCurrency == "POUND" ? "#00700C" : "#928F8F",
                color: "#fff",
              },
            }}
            variant="contained"
          >
            POUND
          </Button>
        </ButtonGroup>
      </div>
      <div style={{ marginLeft: "1rem" }} className="mx-auto p-2 w-full">
        <h3 className="text-xl font-medium pl-2">
          {t("select-report-currency")}
        </h3>
        <FormHelperText>
          <span style={{ color: "red" }}>{t("attention")} </span>
          {t("select-report-currency-info")}
        </FormHelperText>
        <ButtonGroup
          disabled={defaultCurrency ? false : true}
          className="w-full mx-auto p-2"
        >
          <Button
            value="TL"
            onClick={(e: any) => selectReportCurrency(e.target.value)}
            sx={{
              backgroundColor: reportCurrency == "TL" ? "#00700C" : "#928F8F",
              "&:disabled": {
                backgroundColor:
                  defaultCurrency == "TL" ? "#00700C" : "#928F8F",
                color: "#fff",
              },
            }}
            variant="contained"
          >
            TL
          </Button>
          <Button
            value="USD"
            onClick={(e: any) => selectReportCurrency(e.target.value)}
            sx={{
              backgroundColor: reportCurrency == "USD" ? "#00700C" : "#928F8F",
              "&:disabled": {
                backgroundColor:
                  defaultCurrency == "USD" ? "#00700C" : "#928F8F",
                color: "#fff",
              },
            }}
            variant="contained"
          >
            USD
          </Button>
          <Button
            value="EUR"
            onClick={(e: any) => selectReportCurrency(e.target.value)}
            sx={{
              backgroundColor: reportCurrency == "EUR" ? "#00700C" : "#928F8F",
              "&:disabled": {
                backgroundColor:
                  defaultCurrency == "EUR" ? "#00700C" : "#928F8F",
                color: "#fff",
              },
            }}
            variant="contained"
          >
            EUR
          </Button>
          <Button
            value="GBP"
            onClick={(e: any) => selectReportCurrency(e.target.value)}
            sx={{
              backgroundColor: reportCurrency == "GBP" ? "#00700C" : "#928F8F",
              "&:disabled": {
                backgroundColor:
                  defaultCurrency == "POUND" ? "#00700C" : "#928F8F",
                color: "#fff",
              },
            }}
            variant="contained"
          >
            POUND
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
