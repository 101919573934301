import { Service as HService } from "services/http";

export default class ProductGroup {
  private Http: HService | null = null;

  constructor(http: HService) {
    this.Http = http;
  }

  public get endpoint() {
    return `${this.Http?.baseURL}/product-group`;
  }

  public async getAll() {
    return await this.Http?.instance.get("/product-group");
  }

  public async createProductGroup(data: any) {
    return await this.Http?.instance.post("/product-group", data);
  }

  public async updateProductGroup(id: string, data: object) {
    return await this.Http?.instance.put(`/product-group/${id}`, data);
  }

  public async getProductGroup(id: string) {
    return await this.Http?.instance.get(`/product-group/${id}`);
  }

  public async deleteProductGroup(id: string) {
    return await this.Http?.instance.delete(`/product-group/${id}`);
  }

  public async copyTargetBranch(id: string, data: any) {
    return await this.Http?.instance.post(`/product-group/to/${id}`, data);
  }

  public async deleteFromTargetBranch(id: string, data: any) {
    return await this.Http?.instance.put(`/product-group/to/${id}`, data);
  }
}
