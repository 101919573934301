import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"

export default function ({ children, onClose, state: [open, setOpen] }: { children: JSX.Element, onClose?: () => void, state: [boolean, React.Dispatch<React.SetStateAction<boolean>>] }) {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="modal" onClose={onClose || setOpen.bind(null, false)}>
                <div className="modal-wrapper">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="modal-overlay" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="w-full modal-content">
                            {children}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}