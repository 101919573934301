import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react"
import { object, string } from "yup";
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import Spinner from "components/spinner"

import UString from "utilities/string"
import Response from "services/response";
import Http from "services/http";
import { useTranslation } from "react-i18next";

export default function () {

    const Form = useRef<any>();
    const { t } = useTranslation();

    // Hiding/Showing private data
    const [isSecretShown, setIsSecretShown] = useState(false)
    const showSecretHandler = () => {
      setIsSecretShown(!isSecretShown)
    }

    return (
      <React.Fragment>
        <div className="header-top">
          <h2 className="capitalize text-xl font-medium pl-2">
            <span>Trendyol {t('integration')} {false ? t('edit') : t('add')} </span>
          </h2>
        </div>
        <div id="content">
          
          NOT READY

        </div>
        <div className="header-bottom">
          <button
            type="submit"
            // disabled={Save.loading}
            className="ml-auto classic-btn"
            onClick={() => Form.current.handleSubmit()}
          >
            { /* Save.loading */ false ? <Spinner className="h-5 m-auto" /> : t('save') }
          </button>
        </div>
       
      </React.Fragment>
    );
}
