import React, { useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { boolean, object, string } from "yup";
import { Listbox } from "@headlessui/react";

import Http from "services/http";
import Table from "services/table";
import Section from "services/section";
import Response from "services/response";

import Spinner from "components/spinner";
import { toast } from "react-toastify";

import UString from "utilities/string";
import useCache from "services/useCache";

import { useTranslation } from "react-i18next";

export default function () {
  const Service = new Table(useContext(Http.Context)!);
  const SService = new Section(useContext(Http.Context)!);
  const Fetch = new Response();
  const Save = new Response();

  const Form = useRef<any>();
  const navigate = useNavigate();

  const { id } = useParams();
  const { t } = useTranslation();
  const SFetch = useCache(SService);

  useEffect(() => (id && Fetch.handle(Service.getById(id!)), undefined), [id]);

  useEffect(() => {
    if (Save.data && !Save.error) {
      toast.success(`${t('table')} ${id ? t('editing') : t('adding')} ${t('success')}.`);
      navigate("/table");
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      toast.error(
        `${t('table')} ${id ? t('editing') : t('adding')} ${t('failure')}. (${Save.error.message})`
      );
    }
  }, [Save.error]);

  return (
    <React.Fragment>
      <div className="header-top">
        <h2 className="text-xl font-medium pl-2">
          {t('table')} {id ? t('edit') : t('add')}
        </h2>
      </div>
      <div id="content">
        <Formik
          innerRef={Form}
          enableReinitialize={true}
          initialValues={{
            title: Fetch.data?.title || String(),
            section: Fetch.data?.section || SFetch.data?.[0]?.id || String(),
            safeSales: Fetch.data?.safeSales || Boolean(),
          }}
          validationSchema={object({
            title: string().required(t('required')!),
            section: string().required(t('required')!),
            safeSales: boolean().required(
                t('case-sale-error')!
            ),
          })}
          onSubmit={(values) =>
            Save.handle(
              id && Fetch.data
                ? Service.update(id, values)
                : Service.create(values)
            )
          }
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form className="action" onSubmit={handleSubmit}>
              <div className="wrapper">
                <div className="item">
                  <div className="w-full">
                    <label htmlFor="title">{t('title')}</label>
                    <input
                      type="text"
                      name="title"
                      className={UString.concat_class_name(
                        errors.title && touched.title ? "has-error" : undefined,
                        "my-1"
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Masa 1"
                      value={values.title}
                    />
                    {errors.title && touched.title ? (
                      <span className="error">* {errors.title}</span>
                    ) : null}
                  </div>
                  <div className="relative w-full">
                    <label>{t('section')}</label>
                    {SFetch.data ? (
                      <Listbox
                        value={values.section}
                        onChange={(value) => setFieldValue("section", value)}
                      >
                        <div className="relative mt-1">
                          <Listbox.Button className="listbox-btn">
                            <span className="listbox-title">
                              {SFetch.data.find(
                                (section: any) => section.id === values.section
                              )?.title || t('unknown-section')}
                            </span>
                            <span className="listbox-selector-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </Listbox.Button>
                          <Listbox.Options className="listbox-options">
                            {SFetch.data &&
                              SFetch.data.map((section: any) => (
                                <Listbox.Option
                                  className={UString.concat_class_name(
                                    values.section === section.id
                                      ? "bg-ebony text-white"
                                      : "hover:bg-ebony hover:text-white",
                                    "flex items-center cursor-pointer px-6 py-2"
                                  )}
                                  key={section.id}
                                  value={section.id}
                                >
                                  <span>{section.title}</span>
                                </Listbox.Option>
                              ))}
                          </Listbox.Options>
                        </div>
                      </Listbox>
                    ) : (
                      <div className="classic-btn w-full min-h-[38px] mt-1">
                        <Spinner className="w-5 m-auto" />
                      </div>
                    )}
                  </div>
                  <div className="w-full">
                    <label htmlFor={`safeSales`}>
                      {t('case-sale-table')}
                    </label>
                    <div className="w-full classic-btn mt-1">
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id={`safeSales`}
                            name={`safeSales`}
                            type="checkbox"
                            checked={values?.safeSales}
                            onChange={(event) =>
                              setFieldValue(`safeSales`, event.target.checked)
                            }
                            className={UString.concat_class_name(
                              errors.title && touched.title
                                ? "has-error"
                                : undefined,
                              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded-full"
                            )}
                          />
                        </div>
                        <div className="ml-2 text-sm select-none">
                          <label
                            htmlFor={`safeSales`}
                            className="font-medium text-gray-700"
                          >
                            {t('yes')}
                          </label>
                        </div>
                        {errors.safeSales && touched.safeSales ? (
                          <span className="error">* {errors.safeSales}</span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className="header-bottom">
        <button
          type="submit"
          disabled={Save.loading}
          className="ml-auto classic-btn"
          onClick={() => {
            Form.current.handleSubmit();
          }}
        >
          {Save.loading ? <Spinner className="h-5 m-auto" /> : t('save')}
        </button>
      </div>
    </React.Fragment>
  );
}
