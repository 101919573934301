import React from "react"

import Authenticate from "services/authenticate"

import Logo from "components/sidebar/logo"

import Menu from "components/sidebar/menu"
import Data from "components/sidebar/menu-data"

import UString from "utilities/string"
import { useTranslation } from "react-i18next"

export default function ({ show, className, onClose }: { show?: boolean | undefined, className?: string | undefined, onClose?: () => void }): JSX.Element {
    const AuthContext = React.useContext(Authenticate.Context);
    const { t } = useTranslation();
    return (
        <div id="sidebar" className={UString.concat_class_name(className, Boolean(show) ? "flex" : "hidden")}>
            <div id="sidebar-logo">
                <Logo src="/static/logos/white-logo.png" />
                <button onClick={onClose} className="xl:hidden">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <Menu data={Data} />
            <div id="sign-out">
                <button onClick={AuthContext?.signOut.bind(AuthContext)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                    </svg>
                    <span>{t('log-out')}</span>
                </button>
            </div>
        </div>
    )
}