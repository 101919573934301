
import { Link } from "react-router-dom"
import IntegrationList from "components/integrationList"
import { useTranslation } from "react-i18next"

export default function () {
	const { t } = useTranslation();
		

    return (
			<>
				<div className="header-top">
					<h2 className="text-xl font-medium pl-2">{t('integrations')}</h2>
					<Link to="/integration/action" className="flex items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium ml-auto">
						<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
							<path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
						</svg>
						<span>{t('add')}</span>
					</Link>
				</div>
				<div id="content" className="p-6 flex flex-col gap-y-4 ">
					
					<h3>{t('added-integrations-list')}</h3>
					<IntegrationList 
						linkBase="/integration" 
						emptyMessage={t('no-added-integrations')}
					/>
						
				</div>
			</>
    )
}