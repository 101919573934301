import { Service as HService } from "services/http"

export default class Service {
    private Http: HService | null = null

    constructor(http: HService) {
        this.Http = http
    }

    public get endpoint(){
      return `${this.Http?.baseURL}/dashboard`
    }

    /** Son 24 saatin geliri */
    public async getIncome() {
        return await this.Http?.instance.get("/dashboard/income");
    }

    /** Son 24 saatin kârı */
    public async getProfit() {
        return await this.Http?.instance.get("/dashboard/profit");
    }

    /** Masaların doluluk oranı */
    public async getBusyRate() {
        return await this.Http?.instance.get("/dashboard/tablebusyrate");
    }

    /** Son 24 saatte satılan ürünlerin ortalaması */
    public async getTopSellingProducts() {
        return await this.Http?.instance.get("/dashboard/topsellingproducts");
    }

    /** Son 24 saatin adisyon sayısı */
    public async getChecksCount() {
        return await this.Http?.instance.get("/dashboard/checkscount");
    }
}