import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, FieldArray } from "formik";
import { object, string, ref, mixed } from "yup";
import { Listbox } from "@headlessui/react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";

import Http from "services/http";
import Member from "services/member";
import Response from "services/response";
import Structures from "services/structures";

import Spinner from "components/spinner";
import { toast } from "react-toastify";

import UString from "utilities/string";
import useCache from "services/useCache";
import clone from "clone";
import { useTranslation } from "react-i18next";

export default function () {
  const MemberService = new Member(useContext(Http.Context)!);
  const Fetch = new Response();
  const Save = new Response();
  //const Members = useCache(MemberService);

  //şifre göster butonu için
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const Form = useRef<any>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => Save.data, [Save.data]);

  useEffect(
    () => (id && Fetch.handle(MemberService.getById(id!)), undefined),
    [id]
  );

  //şifre gözükecek mi değerini değiştirir
  const showPasswordHandler = () => {
    //şifre gözükecek mi değerini zıttı yapar
    setIsPasswordShown(!isPasswordShown);
  };

  /* const handlePassword = (id: any) => {
    if (Members.data && !Members.error && !Members.loading) {
      let user = Members.data.find((value: any) => value.id == id);
      return user.password ? user.password : null;
    }
  }; */

  useEffect(() => {
    if (Save.data && !Save.error) {
      toast.success(`${t('user')} ${id ? t('editing') : t('adding')} ${t('success')}.`);
      navigate("/member");
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      toast.error(
        `${t('user')} ${id ? t('editing') : t('adding')} ${t('failure')}. (${
          Save.error.message
        })`
      );
    }
  }, [Save.error]);

  return (
    <React.Fragment>
      <div className="header-top">
        <h2 className="text-xl font-medium pl-2">
          {t('user')} {id ? t('edit') : t('add')}
        </h2>
      </div>
      <div id="content">
        <Formik
          innerRef={Form}
          enableReinitialize={true}
          initialValues={{
            name: Fetch.data?.name || String(),
            lastname: Fetch.data?.lastname || String(),
            password: Fetch.data?.password,
            passwordConfirmation: Fetch.data?.password,
            //password: handlePassword(id),
            //passwordConfirmation: handlePassword(id),
            role: Fetch.data?.role || "pos",
            permissions: Fetch.data?.permissions || Array(),
          }}
          validationSchema={object({
            name: string().required(t('required')!),
            lastname: string().required(t('required')!),
            password: string()
              .required(t('required')!)
              .min(6, t('min-6-characters')!)
              .matches(
                /^[a-zA-Z0-9_.-]*$/,
                t('password-only-latin-letters')!
              ),
            passwordConfirmation: string()
              .required(t('required')!)
              .oneOf([ref("password"), null], t('password-match-error')!),
            role: mixed()
              .oneOf(["pos", "waiter", "accounting", "kitchen", "delivery"])
              .required(t('required')!),
          })}
          onSubmit={(values, {resetForm}) => {
            Save.handle(
              id && Fetch.data
                ? MemberService.update(id, { ...Fetch.data, ...values })
                : MemberService.create(values)
            );
            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form className="action" onSubmit={handleSubmit}>
              <div className="wrapper">
                <div className="item">
                  <div className="w-full flex flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-2">
                    <div className="w-full xl:w-1/2">
                      <label htmlFor="name">İsim</label>
                      <input
                        type="text"
                        name="name"
                        className={UString.concat_class_name(
                          errors.name && touched.name ? "has-error" : undefined,
                          "my-1"
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="John"
                        value={values.name}
                      />
                      {errors.name && touched.name ? (
                        <span className="error">* {errors.name}</span>
                      ) : null}
                    </div>
                    <div className="w-full xl:w-1/2">
                      <label htmlFor="lastname">{t('lastname')}</label>
                      <input
                        type="text"
                        name="lastname"
                        className={UString.concat_class_name(
                          errors.lastname && touched.lastname
                            ? "has-error"
                            : undefined,
                          "my-1"
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Doe"
                        value={values.lastname}
                      />
                      {errors.lastname && touched.lastname ? (
                        <span className="error">* {errors.lastname}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="relative w-full">
                    <label>{t('role')}</label>
                    <Listbox
                      value={values.role}
                      onChange={(value) => setFieldValue("role", value)}
                    >
                      <div className="relative mt-1">
                        <Listbox.Button className="listbox-btn">
                          <span className="listbox-title">
                            {
                              Structures.types.roles.find(
                                (role) => values.role === role.id
                              )?.label
                            }
                          </span>
                          <span className="listbox-selector-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5 text-gray-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </Listbox.Button>
                        <Listbox.Options className="listbox-options">
                          {Structures.types.roles.map((value) => (
                            <Listbox.Option
                              className={UString.concat_class_name(
                                values.role === value.id
                                  ? "bg-ebony text-white"
                                  : "hover:bg-ebony hover:text-white",
                                "flex items-center cursor-pointer px-6 py-2"
                              )}
                              key={value.id}
                              value={value.id}
                            >
                              <span>{value.label}</span>
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </div>
                    </Listbox>
                  </div>
                  <div className="w-full flex flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-2">
                    <div className="relative flex w-full xl:w-1/2 flex-wrap items-stretch">
                      <label htmlFor="password" className="h-5">
                        {t('password')}
                      </label>
                      <input
                        type={isPasswordShown ? "text" : "password"}
                        name="password"
                        className={UString.concat_class_name(
                          errors.password && touched.password
                            ? "has-error"
                            : undefined,
                          "my-1 pl-3 relative w-full pr-10 "
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="******"
                        value={values.password}
                      />
                      <span className="mt-3 absolute bg-transparent items-center justify-center w-8 right-0 pr-3 py-5">
                        {isPasswordShown ? (
                          <EyeOffIcon
                            className="h-5 w-5"
                            onClick={showPasswordHandler}
                          />
                        ) : (
                          <EyeIcon
                            className="h-5 w-5"
                            onClick={showPasswordHandler}
                          />
                        )}
                      </span>
                      {errors.password && touched.password ? (
                        <span className="error absolute mt-16">
                          * {errors.password}
                        </span>
                      ) : null}
                    </div>
                    <div className="relative flex w-full xl:w-1/2 flex-wrap items-stretch mb-3">
                      <label htmlFor="passwordConfirmation" className="h-5">
                        {t('password-confirm')}
                      </label>
                      <input
                        type={isPasswordShown ? "text" : "password"}
                        name="passwordConfirmation"
                        className={UString.concat_class_name(
                          errors.passwordConfirmation &&
                            touched.passwordConfirmation
                            ? "has-error"
                            : undefined,
                          "my-1 pl-3 relative w-full pr-10"
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="******"
                        value={values.passwordConfirmation}
                      />
                      <span className="mt-3 absolute bg-transparent items-center justify-center w-8 right-0 pr-3 py-5">
                        {isPasswordShown ? (
                          <EyeOffIcon
                            className="h-5 w-5"
                            onClick={showPasswordHandler}
                          />
                        ) : (
                          <EyeIcon
                            className="h-5 w-5"
                            onClick={showPasswordHandler}
                          />
                        )}
                      </span>
                      {errors.passwordConfirmation &&
                      touched.passwordConfirmation ? (
                        <span className="error absolute mt-16">
                          * {errors.passwordConfirmation}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  {Object.keys(Structures.types.permissions).includes(
                    values.role
                  ) && (
                    <div className="w-full">
                      <label>{t('permissions')}</label>
                      <div className="w-full classic-btn mt-1">
                        <FieldArray
                          name="permissions"
                          render={(array) => {
                            const selectPermission =
                              Structures.types.permissions[
                                values.role as keyof typeof Structures.types.permissions
                              ];

                            return (
                              <div className="w-full flex flex-wrap -my-1 -mx-4">
                                {selectPermission.map((permission) => (
                                  <div
                                    className="flex items-start my-1 px-4"
                                    key={permission.id}
                                  >
                                    <div className="flex items-center h-5">
                                      <input
                                        id={`permissions.[${permission.id}]`}
                                        name={`permissions.[${permission.id}]`}
                                        type="checkbox"
                                        value={permission.id}
                                        checked={permission.id == 507 || permission.id ==  512 ? values.permissions.includes(permission.id) && values.permissions.includes(511) : values.permissions.includes(permission.id)}
                                        onChange={(event) => {
                                          if(event.target.checked) {
                                            array.push(permission.id);
                                          }else {
                                            array.remove(values.permissions.indexOf(permission.id));
                                            
                                          }
                                        }
                                        }
                                        disabled={!values.permissions.includes(511) && (permission.id == 507 || permission.id == 512)}
                                        className={(!values.permissions.includes(511) && (permission.id == 507 || permission.id == 512)) ? "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded-full" : "focus:ring-indigo-500 h-4 w-4 text-indigo-900 border-gray-600 rounded-full" }
                                      />
                                    </div>
                                    <div className="ml-2 text-sm select-none">
                                      <label
                                        htmlFor={`permissions.[${permission.id}]`}
                                        className="font-medium text-gray-700"
                                        
                                      >
                                        {
                                          selectPermission.find(
                                            (predicate) =>
                                              predicate.id === permission.id
                                          )?.label
                                        }
                                      </label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className="header-bottom">
        <button
          type="submit"
          disabled={Save.loading}
          className="ml-auto classic-btn"
          onClick={() => Form.current.handleSubmit()}
        >
          {Save.loading ? <Spinner className="h-5 m-auto" /> : t('save')}
        </button>
      </div>
    </React.Fragment>
  );
}
