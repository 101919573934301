import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Http from "services/http";
import Category from "services/category";
import Response from "services/response";

import Table from "components/table";
import Image from "components/image";
import Modal from "components/modal";
import Loading from "components/loading";
import NetworkError from "components/network-error";
import Spinner from "components/spinner";
import useCache from "services/useCache";
import { useSWRConfig } from "swr";

import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import axios from "axios";

export default function () {
  const CategoryService = new Category(useContext(Http.Context)!);
  // const Categories = new Response();
  const Delete = new Response();
  const { t } = useTranslation();
  const [modal, setModal] = useState<boolean>(false);
  const [category, setCategory] = useState<any | null>(null);

  const { mutate } = useSWRConfig();
  const Categories = useCache(CategoryService);
  //useEffect(() => (!Categories.data && Categories.handle(Service.get()), undefined), [Categories.data])
  const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
  const refreshToken = localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_NAME!);
  const [permission, setPermission] = useState(false);

  useEffect(() => {
    let getBranch = async () => {
      let branch: any = await axios.get('/manager/branches', {
        baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-refresh': refreshToken!
        }
      });
      if (branch.data[0].productPermission) setPermission(true);
    }
    getBranch();
  }, []);

  useEffect(() => {
    if (Delete.data && !Delete.error && !Delete.loading) {
      let newData = Categories.data.filter((d: any) => d.id !== category.id);
      mutate(CategoryService.endpoint, newData, {
        optimisticData: newData,
        rollbackOnError: true,
      });
      setModal(false);
    } else if (Delete.error && !Delete.loading) {
      toast.error(Delete.error?.response?.data);
      setModal(false);
    }
  }, [Delete.data, Delete.loading]);

  useEffect(() => setModal(Boolean(category)), [category]);
  useEffect(
    () => (
      !modal &&
      setTimeout(() => (setCategory(null), (Delete.data = null)), 200),
      undefined
    ),
    [modal]
  );

  return (
    <React.Fragment>
      <Modal
        onClose={() => !Delete.loading && setModal(false)}
        state={[modal, setModal]}
      >
        <React.Fragment>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 p-0.5">
                <Image
                  src={category?.image}
                  className="w-full h-full rounded-full object-cover"
                />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {category?.title}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {t('category-delete-information')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-actions">
            <button
              disabled={Delete.data || Delete.loading}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => Delete.handle(CategoryService.delete(category.id))}
            >
              {Delete.data ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : Delete.loading ? (
                <Spinner className="h-5 m-auto" />
              ) : (
                <span>{t('delete')}</span>
              )}
            </button>
            <button
              disabled={Delete.loading}
              type="button"
              className="mt-3 w-full classic-btn justify-center sm:mt-0 sm:ml-3 sm:w-auto"
              onClick={setModal.bind(null, false)}
            >
              {t('cancel')}
            </button>
          </div>
        </React.Fragment>
      </Modal>
      <div className="header-top">
        <h2 className="text-xl font-medium pl-2">{t('category-list')}</h2>
        {!permission ? null : (
          <Link
            to="/category/action"
            className="flex items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium ml-auto"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            <span>{t('add-category')}</span>
          </Link>
        )}
        <Link className="flex items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium ml-auto" to="/category/rank" style={{ position: "absolute", left: "72vw" }}>
          <svg style={{ marginRight: 3 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="square" stroke-linejoin="arcs"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
          <span >{t('edit-rank')}</span>
        </Link>
      </div>
      <div id="content">
        <Loading done={Categories.data} />
        {Categories.error ? (
          <NetworkError
            error={Categories.error}
            retry={() => {
              mutate(CategoryService.endpoint);
            }}
          />
        ) : null}
        {Categories.data && !Categories.error && (
          <Table
            data={{
              head: [
                { element: t('category'), props: { scope: "col" } },
                { element: t('created-at'), props: { scope: "col" } },
                {
                  element: t('actions'),
                  props: { scope: "col" },
                  screenReader: true,
                },
              ],
              body: Categories.data.map((category: any) => ({
                id: category.id,
                elements: [
                  {
                    element: (
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-12 w-12 rounded-full border border-gray-300 p-0.5">
                          <Image
                            className="h-full w-full rounded-full object-cover"
                            src={category.image}
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {category.title}
                          </div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    element: new Date(category.createdAt).toLocaleDateString(
                      "tr-TR",
                      {
                        minute: "numeric",
                        hour: "numeric",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    ),
                    props: { className: "text-left text-sm text-gray-900" },
                  },
                  {
                    element: (
                      <>
                        {!permission ? null : (
                          <>
                            <Link
                              to={`/category/action/${category.id}`}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              {t('edit')}
                            </Link>
                            <button
                              onClick={setCategory.bind(null, category)}
                              className="text-red-600 hover:text-red-900"
                            >
                              {t('delete')}
                            </button>
                          </>
                        )}
                      </>
                    ),
                    props: {
                      className: "text-right text-sm font-medium space-x-6",
                    },
                  },
                ],
              })),
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
