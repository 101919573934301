import { Transition } from "@headlessui/react"

import Spinner from "components/spinner"

import UString from "utilities/string"

export default function ({ props, duration = 2, done }: { props?: React.HTMLAttributes<HTMLDivElement>, duration?: string | number | undefined, done?: boolean | undefined }) {
    return (
        <Transition as="div" {...props} className={UString.concat_class_name(props?.className, "loading")} show={!done} leave="transition-opacity duration-150" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Spinner className="h-[50px] m-auto" duration={duration} />
        </Transition>
    );
}