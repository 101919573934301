import React, { Component, useState } from 'react'
import { Select, MenuItem, Box, Button, InputLabel, TextField, Dialog, DialogTitle, DialogContent, Grid, Paper, Collapse, Typography, Stack, Avatar, alpha } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Sector, Cell, BarChart, Bar } from 'recharts';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import axios from 'axios';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import RemoveIcon from '@mui/icons-material/Remove';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import { green, blue } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import clone from 'clone';
import CategoryIcon from '@mui/icons-material/Category';
import "./category.css"
import { Transition } from '@headlessui/react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import Loading from 'components/loading';
import NoReport from '../no-report';
import { withTranslation, WithTranslation } from 'react-i18next';
import ButtonGroup from '@mui/material/ButtonGroup';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { parseCategoryReport } from 'services/helpers/excel';
import { convertCurrency, defaultCurrency } from 'services/helpers/exchange';
import { t } from 'i18next';

type CategoryReportState = {
    chartType: string,
    startdate: any,
    enddate: any,
    per: number,
    userBranch: any,
    subBranches: any[],
    selectedBranches: string[],
    lineData: any,
    categories: any[],
    reportData: any[],
    branchDialog: boolean,
    comparisonMode: number,
    selectedCategory: string,
    loading: boolean,

}

interface CategoryReportProps extends WithTranslation { }

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            checked: string;
            unchecked: string;
        };
        default: {
            primary: string;
            secondary: string;
        },
        text: {
            primary: string;
        }
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            checked?: string;
            unchecked?: string;
        };
        default?: {
            primary?: string;
            secondary?: string;
        };
        text?: {
            primary?: string;
        }
    }
}

const COLORS = ["#FDEDD0", "#BCF1ED", "#FF634D", "#FD795B", "#028482", "#7ABA7A", "#B76EB8", "#FFEC94", "#FFAEAE", "#FFF0AA", "#B0E57C", "#B4D8E7", "#56BAEC"];
const LINE_COLORS = ["#333333", "#FF9900", "#336699", "#666666", "#AC54AA", "#374D3C", "#6600CC", "#FF6600", "#CC0000", "#000080"]

const theme = createTheme({
    status: {
        checked: "##003399",
        unchecked: "#0099CC"
    },
    default: {
        primary: "#717D8C",
        secondary: "#2F343A",
    },
    text: {
        primary: "#fff"
    }
})

const SubbranchButton = styled(Button)(({ theme }) => ({
    color: theme.default.secondary,
    backgroundColor: theme.default.primary,
    borderRadius: 10,
}));

const BranchButton = styled(Button)(({ theme }) => ({
    color: "#fff",
    backgroundColor: theme.default.primary,
    fontSize: "0.9vw",
    padding: "0.5vw",
    height: "8vh",
    width: "10vw",
    "&:hover": {
        color: "#66CCFF",
        backgroundColor: "#003399"
    }
}));

const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_NAME!);

class CategoryReport extends Component<CategoryReportProps, CategoryReportState> {
    state: CategoryReportState = {
        chartType: this.props.t('general'),
        startdate: new Date(Date.now() - 86400000),
        enddate: new Date(),
        reportData: [],
        categories: [],
        per: 86400000,
        userBranch: {},
        subBranches: [],
        selectedBranches: [],
        branchDialog: false,
        lineData: [],
        comparisonMode: 0,
        selectedCategory: "",
        loading: true
    }

    columns: GridColDef[] = [
        {
            field: "categoryTitle",
            headerName: this.props.t('category-name')!,
            width: 270,
            renderCell: (params) => (
                <strong>
                    {params.value}
                </strong>
            )
        },
        {
            field: "saleCount",
            headerName: this.props.t('sale-count')!,
            width: 160,
            renderCell: (params) => (
                <>{convertCurrency(params.value)}</>
            )
        },
        {
            field: "salePercent",
            headerName: this.props.t('sale-percent')!,
            width: 200,
            renderCell: (params) => (
                <>{convertCurrency(params.value)}%</>
            )
        },
        {
            field: "totalPrice",
            headerName: this.props.t('total-price')!,
            width: 200,
            renderCell: (params) => (
                <>{convertCurrency(params.value)} {defaultCurrency()}</>
            )
        },
        {
            field: "pricePercent",
            headerName: this.props.t('price-percent')!,
            width: 200,
            renderCell: (params) => (
                <>{new Intl.NumberFormat('de-DE').format(parseFloat(params.value))}%</>
            )
        },
        {
            field: "averagePrice",
            headerName: this.props.t('average-price')!,
            width: 200,
            renderCell: (params) => (
                <>{convertCurrency(params.value)} {defaultCurrency()}</>
            )
        }
    ]

    TableReport = (data: any) => {
        const [expanded, setExpanded] = useState(false);
        
        return (
            <Box sx={{ marginBottom: "5vw" }}>
                <Typography component="div" sx={{ cursor: "pointer" }} onClick={() => setExpanded(!expanded)}>
                    <Box sx={{ textAlign: "center", backgroundColor: "#2F343A", borderRadius: "10px 10px 0 0", color: "#fff", fontWeight: "bold", fontSize: "1.23vw" }}>{data.branchTitle} {expanded ? (<KeyboardArrowUpIcon fontSize='large' />) : (<KeyboardArrowDownIcon fontSize='large' />)}</Box>
                </Typography>
                <Collapse in={expanded}>
                    <Box>
                        <Box sx={{
                            height: 600,
                            width: "100%",
                            backgroundColor: "#2F343A",
                            color: "#fff",
                            '& .row-class': {
                                bgcolor: "#717D8C",
                                color: "#fff",
                                '&:hover': {
                                    color: "#fff"
                                },
                                '&:selected': {
                                    color: "#fff"
                                }
                            }
                        }}
                            key={data.id}
                        >
                            <DataGrid
                                rows={data.reports.filter((report: any) =>
                                    report.products.some((product: any) => product.saleCount > 0.0))||[]}
                                columns={this.columns}
                                getRowClassName={(params: any) => 'row-class'}
                                disableSelectionOnClick
                            />
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        )

    }

    renderCustomizedLabel = (props: any) => {
        const { cx, cy, midAngle, innerRadius, outerRadius, percent, index, value } = props;
        const RADIAN = Math.PI / 180;
        // eslint-disable-next-line
        const radius = 25 + innerRadius + (outerRadius - innerRadius);
        // eslint-disable-next-line
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        // eslint-disable-next-line
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x}
                y={y}
                fill="#015A09"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {`${convertCurrency(percent * 100)}%`}
            </text>
        );
    };

    PieReport = (data: any, index: number) => {
        const [expanded, setExpanded] = useState(false);

        return (
            <Box sx={{ marginBottom: "0vw" }}>
                <Typography component="div" sx={{ cursor: "pointer" }} onClick={() => setExpanded(!expanded)}>
                    <Box sx={{ textAlign: "center", backgroundColor: "#2F343A", borderRadius: "10px 10px 0 0", color: "#fff", fontWeight: "bold", fontSize: "1.23vw" }}>{data.branchTitle} {expanded ? (<KeyboardArrowUpIcon fontSize='large' />) : (<KeyboardArrowDownIcon fontSize='large' />)}</Box>
                </Typography>
                <Collapse in={expanded}>
                    <Box sx={{ display: "flex", flexDirection: "row", overflow: "auto", justifyContent: "space-around" }}>
                        <Box>
                            <h3 style={{ textAlign: "center", margin: "auto", fontWeight: "bold", fontSize: "1.3vw" }}>{this.props.t('sale-count')}</h3>
                            <PieChart width={450} height={450} title={this.props.t('sale-count')!}>
                                <Pie
                                    data={data?.reports.filter((data: any) => data.saleCount != 0)}
                                    labelLine={false}
                                    dataKey="saleCount"
                                    nameKey="categoryTitle"
                                    fill="#8884d8"
                                    label={this.renderCustomizedLabel}
                                    outerRadius={100}
                                >
                                    {data?.reports?.filter((data: any) => data.saleCount != 0)?.map((data: any, index: number) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip formatter={(value:any) => convertCurrency(value)}/>
                            </PieChart>
                        </Box>
                        <Box>
                            <h3 style={{ textAlign: "center", margin: "auto", fontWeight: "bold", fontSize: "1.3vw", marginTop: 3 }}>{this.props.t('total-price')}</h3>
                            <PieChart width={450} height={450} title={this.props.t('total-price')!}>
                                <Pie
                                    data={data?.reports.filter((data: any) => data.saleCount != 0)}
                                    labelLine={false}
                                    dataKey="totalPrice"
                                    nameKey="categoryTitle"
                                    fill="#8884d8"
                                    label={this.renderCustomizedLabel}
                                    outerRadius={100}
                                >
                                    {data?.reports?.filter((data: any) => data.saleCount != 0)?.map((data: any, index: number) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip formatter={(value:any) => convertCurrency(value)}/>
                            </PieChart>
                        </Box>
                        <Box>
                            <h3 style={{ textAlign: "center", margin: "auto", fontWeight: "bold", fontSize: "1.3vw", marginTop: 3 }}>{this.props.t('average-price')}</h3>
                            <PieChart width={450} height={450} title={this.props.t('average-price')!}>
                                <Pie
                                    data={data?.reports.filter((data: any) => data.saleCount != 0)}
                                    labelLine={false}
                                    dataKey="averagePrice"
                                    nameKey="categoryTitle"
                                    fill="#8884d8"
                                    label={this.renderCustomizedLabel}
                                    outerRadius={100}
                                >
                                    {data?.reports?.filter((data: any) => data.saleCount != 0)?.map((data: any, index: number) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                    
                                </Pie>
                                <Tooltip formatter={(value:any) => convertCurrency(value)}/>
                            </PieChart>
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        )
    }

    async componentDidMount() {
        let categories: any = await axios.get('manager/category', {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-refresh': refreshToken!
            }
        });
        let data: any = await axios.get(`manager/report/category`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-refresh': refreshToken!
            },
            params: {
                startdate: new Date(this.state.startdate).getTime(),
                enddate: new Date(this.state.enddate).getTime(),
            }
        });
        let branch: any = await axios.get(`manager/branches`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-refresh': refreshToken!
            }
        });
        this.setState({
            categories: categories.data,
            reportData: data.data,
            userBranch: branch?.data[0],
            subBranches: branch?.data[0]?.subBranch,
            selectedBranches: [branch?.data[0]?._id],
            selectedCategory: categories.data[0]?.id,
            loading: false
        });
    }

    BarReport = (data: any) => {
        const [expanded, setExpanded] = useState(false);
        return (
            <Box sx={{ marginBottom: "5vw" }}>
                <Typography component="div" sx={{ cursor: "pointer" }} onClick={() => setExpanded(!expanded)}>
                    <Box sx={{ textAlign: "center", backgroundColor: "#2F343A", borderRadius: "10px 10px 0 0", color: "#fff", fontWeight: "bold", fontSize: "1.23vw" }}>{data.branchTitle} {expanded ? (<KeyboardArrowUpIcon fontSize='large' />) : (<KeyboardArrowDownIcon fontSize='large' />)}</Box>
                </Typography>
                <Collapse in={expanded}>
                    <BarChart
                        width={800}
                        height={450}
                        data={data?.reports}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="categoryTitle" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="saleCount" name={this.props.t('sale-count')!} fill="#145F03" />
                        <Bar dataKey="totalPrice" name={this.props.t('total-price')!} fill="#03BCD5" />
                        <Bar dataKey="averagePrice" name={this.props.t('average-price')!} fill="#0326D5" />
                    </BarChart>
                </Collapse>
            </Box>
        )
    }

    syncData = async () => {
        if (!new Date(this.state.startdate)?.getTime() || !new Date(this.state.enddate)?.getTime()) {
            this.setState({
                lineData: [],
                reportData: []
            });
            return;
        }
        if (this.state.startdate > this.state.enddate) {
            toast.warning(this.props.t('date-warning'));
            return;
        }
        if (this.state.enddate >= this.state.startdate) {
            this.setState({ loading: true });
            if (this.state.chartType == this.props.t('line-chart')) {
                if (!this.state.selectedCategory) {
                    this.setState({
                        lineData: [],
                        loading: false
                    });
                    return;
                }
                let lineData: any = await axios.get(`manager/report/category/${this.state.selectedCategory}`, {
                    baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'x-refresh': refreshToken!
                    },
                    params: {
                        startdate: new Date(this.state.startdate).getTime(),
                        enddate: new Date(this.state.enddate).getTime(),
                        per: this.state.per
                    }
                });
                let products: any[] = [];
                lineData.data.reports.map((report: any) => {
                    report.products.map((product: any) => {
                        let index = products.findIndex((p: any) => p.id.toString() == product.id.toString());
                        if (index < 0) {
                            products.push({
                                id: product.id,
                                name: product.name,
                                reports: [{
                                    from: report.from,
                                    saleCount: product.saleCount,
                                    totalPrice: product.totalPrice,
                                    averagePrice: product.averagePrice
                                }]
                            });
                            return;
                        }
                        products[index].reports.push({
                            from: report.from,
                            saleCount: product.saleCount,
                            totalPrice: product.totalPrice,
                            averagePrice: product.averagePrice
                        });
                        return;
                    });
                });
                this.setState({
                    lineData: products,
                    loading: false
                })
                return;
            }
            let data: any = await axios.get(`manager/report/category`, {
                baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'x-refresh': refreshToken!
                },
                params: {
                    startdate: new Date(this.state.startdate).getTime(),
                    enddate: new Date(this.state.enddate).getTime(),
                }
            });
            this.setState({
                reportData: data.data,
                loading: false
            });
        }

    }

    BranchDialog = () => {
        const handleClick = async (value: string) => {
            if (value == "all") {
                if (this.state.selectedBranches.length == this.state.subBranches.length + 1) {
                    this.setState({
                        selectedBranches: []
                    });
                    return;
                }
                this.setState({
                    selectedBranches: this.state.subBranches.map((b: any) => (b._id)).concat(this.state.userBranch._id)
                })
                return;
            }
            let index: number = this.state.selectedBranches.findIndex((b: any) => b == value);
            if (index > -1) {
                let branches: any = clone(this.state.selectedBranches);
                branches.splice(index, 1);
                await this.setState({
                    selectedBranches: branches
                });
                return;
            }
            await this.setState({
                selectedBranches: this.state.selectedBranches.concat([value])
            });
        }
        return (
            <Dialog open={this.state.branchDialog} onClose={() => this.setState({ branchDialog: false })} fullWidth sx={{ overflow: "auto" }}>
                <ThemeProvider theme={theme}>
                    <DialogTitle>{this.props.t('select-branch-to-list-reports')}</DialogTitle>
                    <DialogContent>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6} key={0}>
                                <BranchButton onClick={(e: any) => handleClick('all')} startIcon={this.state.selectedBranches.length == this.state.subBranches.length + 1 ? (<DoneAllIcon />) : (<CloseIcon />)} value="all" sx={this.state.selectedBranches.length == this.state.subBranches.length + 1 ? { color: "#fff", backgroundColor: "#003399" } : {}}><span style={{ fontSize: "0.9vw" }}>{this.props.t("all")}</span></BranchButton>
                            </Grid>
                            <Grid item xs={6} key={1}>
                                <BranchButton onClick={(e: any) => handleClick(this.state.userBranch._id)} startIcon={this.state.selectedBranches.includes(this.state.userBranch._id) ? (<DoneIcon />) : (<CloseIcon />)} value={this.state.userBranch._id} sx={this.state.selectedBranches.includes(this.state.userBranch._id) ? { color: "#fff", backgroundColor: "#003399", fontSize: "1em" } : {}} style={{ fontSize: "0.9vw" }}><span>{this.state.userBranch.title}</span></BranchButton>
                            </Grid>
                            {this.state.subBranches.map((branch: any, index: number) => (
                                <Grid item xs={6} key={index + 2}>
                                    <BranchButton onClick={(e: any) => handleClick(branch._id)} startIcon={this.state.selectedBranches.includes(branch._id) ? (<DoneIcon />) : (<CloseIcon />)} value={branch._id} sx={this.state.selectedBranches.includes(branch._id) ? { color: "#fff", backgroundColor: "#003399" } : {}}><span style={{ fontSize: "0.9vw" }}>{branch.title}</span></BranchButton>
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContent>
                </ThemeProvider>
            </Dialog >
        )
    }

    GeneralReport = (data: any) => {
        const [expanded, setExpanded] = useState(false);
        const [detail, setDetail]: any = useState();
        const [comparisonMode, setComparsionMode] = useState(0);


        var handleCompare = async (id: string, firstDate: any, secondDate: any, branch: string) => {
            switch (comparisonMode) {
                case 2:
                    let data: any = await axios.get(`manager/report/advanced/category/${id}`, {
                        baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'x-refresh': refreshToken!
                        },
                        params: {
                            firstdate: new Date(firstDate.startdate).getTime(),
                            seconddate: new Date(secondDate.startdate).getTime(),
                            type: "basic",
                            branch: branch
                        }
                    });
                    return data.data;
                case 3:
                    let firststart = new Date(firstDate.startdate).getTime();
                    let firstend = new Date(firstDate.enddate).getTime();
                    let secondstart = new Date(secondDate.startdate).getTime();
                    let secondend = new Date(secondDate.enddate).getTime();

                    if (firstend <= firststart || secondend <= secondstart) {
                        toast.warning(this.props.t('report-date-warning'));
                        break;
                    }
                    let advancedData: any = await axios.get(`manager/report/advanced/category/${id}`, {
                        baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'x-refresh': refreshToken!
                        },
                        params: {
                            firststart: new Date(firstDate.startdate).getTime(),
                            firstend: new Date(firstDate.enddate).getTime(),
                            secondstart: new Date(secondDate.startdate).getTime(),
                            secondend: new Date(secondDate.enddate).getTime(),
                            type: "advanced",
                            branch: branch
                        }
                    });
                    return advancedData.data;
                default:
                    break;
            }
        }

        var ComparisonDialog = (branch: any) => {
            const [firstDate, setFirstDate] = useState({
                startdate: new Date(),
                enddate: new Date()
            });
            const [secondDate, setSecondDate] = useState({
                startdate: new Date(),
                enddate: new Date()
            });

            const [data, setData]: any = useState({})
            return (
                <React.Fragment>
                    <Transition
                        show={true}
                        as={React.Fragment}
                        enter="ease duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div style={{ zIndex: 100 }} className="bg-orange-400 bg-opacity-30 inset-0 absolute backdrop-blur-md flex items-center justify-center">
                            <Transition
                                as={React.Fragment}
                                enter="ease duration-500"
                                enterFrom="opacity-0"
                                leave="ease duration-500"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute inset-0 z-0" onClick={() => setComparsionMode(0)}></div>

                            </Transition>

                            <div className="z-50 flex items-center justify-center relative w-1/3 h-2/3">
                                <div className={`${comparisonMode == 1 ? 'translate-x-0' : '-translate-x-1/2'} transition-all duration-300 ease flex flex-col absolute bg-white rounded-xl w-full h-full z-50`}>
                                    <Typography variant="h6" sx={{ margin: "1.1vw", padding: "0.3vw", borderRadius: "6px", backgroundColor: COLORS[detail?.index % COLORS.length], textAlign: "center" }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                                            {comparisonMode == 2 ? (
                                                <Stack direction="row" spacing={2}>
                                                    <DateTimePicker
                                                        label={this.props.t('report-date')}
                                                        inputFormat="DD/MM/YYYY HH:mm"
                                                        value={firstDate.startdate}
                                                        ampm={false}
                                                        onChange={async (value: any) => {
                                                            await setFirstDate({ startdate: value, enddate: firstDate.enddate });
                                                        }}
                                                        renderInput={(params: any) => <TextField sx={{ width: "10vw" }} {...params} />}

                                                    />
                                                    <span>
                                                        {detail?.categoryTitle}
                                                    </span>
                                                    <div></div>
                                                </Stack>
                                            ) : comparisonMode == 3 ? (
                                                <Stack direction="row" spacing={2}>
                                                    <DateTimePicker
                                                        label={this.props.t('start-date')}
                                                        value={firstDate.startdate}
                                                        ampm={false}
                                                        onChange={async (value: any) => {
                                                            await setFirstDate({
                                                                startdate: value,
                                                                enddate: firstDate.enddate
                                                            });

                                                        }}
                                                        renderInput={(params: any) => <TextField sx={{ width: "10vw" }} {...params} />}
                                                    />
                                                    <span>
                                                        {detail?.categoryTitle}
                                                    </span>
                                                    <DateTimePicker
                                                        label={this.props.t('end-date')}
                                                        value={firstDate.enddate}
                                                        ampm={false}
                                                        onChange={async (value: any) => {
                                                            await setFirstDate({
                                                                startdate: firstDate.startdate,
                                                                enddate: value
                                                            });

                                                        }}
                                                        renderInput={(params: any) => <TextField sx={{ width: "10vw" }} {...params} />}

                                                    />
                                                </Stack>
                                            ) : (
                                                <span>{detail?.categoryTitle}</span>
                                            )}

                                        </LocalizationProvider>
                                    </Typography>
                                    {comparisonMode == 2 || comparisonMode == 3 ? (
                                        <>
                                            {!data?.first ? (
                                                <Typography sx={{ textAlign: "center", fontSize: "1.1vw" }}>{this.props.t('please-click-compare-button')}</Typography>
                                            ) : data?.first?.totalSale == 0 ? (
                                                comparisonMode == 2 ? (
                                                    <Typography sx={{ textAlign: "center", fontSize: "1.1vw" }}>{new Date(data?.first?.from).toLocaleDateString()} - {detail?.categoryTitle}: {this.props.t('no-sale-this-date')}</Typography>

                                                ) : (
                                                    <Typography sx={{ textAlign: "center", fontSize: "1.1vw" }}>{new Date(data?.first?.from).toLocaleString()} - {new Date(data?.first?.to).toLocaleString()} - {detail?.categoryTitle}: {this.props.t('no-sale-this-date')}</Typography>
                                                )
                                            ) : (
                                                <>
                                                    {comparisonMode == 2 ? (
                                                        <Typography sx={{ textAlign: "center", fontSize: "1.1vw" }}>{new Date(data?.first?.from).toLocaleDateString()} - {detail?.categoryTitle}</Typography>
                                                    ) : (
                                                        <Typography sx={{ textAlign: "center", fontSize: "1.1vw" }}>{new Date(data?.first?.from).toLocaleString()} - {new Date(data?.first?.to).toLocaleString()} - {detail?.categoryTitle}</Typography>
                                                    )}
                                                    <div style={{ overflowX: "auto", height: "40vh" }}>
                                                        <table style={{ margin: "auto", marginTop: "1.3vw", overflow: "auto" }}>
                                                            <tr className="caption">
                                                                <td colSpan={2}>{this.props.t('product-name')}</td>
                                                                <td colSpan={2}>{this.props.t('sale-count')}</td>
                                                                <td colSpan={2}>{this.props.t('sale-percent')}</td>
                                                                <td colSpan={4}>{this.props.t('total-income')}</td>
                                                                <td colSpan={2}>{this.props.t('price-percent')}</td>
                                                                <td colSpan={3}>{this.props.t('average-income')}</td>
                                                            </tr>
                                                            {data?.first?.result.map((product: any) => (
                                                                product.saleCount > 0.0 ?
                                                                <tr>
                                                                    <td colSpan={2}>{product.name}</td>
                                                                    <td colSpan={2}>{convertCurrency(product.saleCount)}</td>
                                                                    <td colSpan={2}>{convertCurrency((product.saleCount * 100) / data?.first?.totalSale)}%</td>
                                                                    <td colSpan={4}>{convertCurrency(product.totalPrice)} {defaultCurrency}</td>
                                                                    <td colSpan={2}>{convertCurrency(((product.totalPrice * 100) / data?.first?.totalPrice))}%</td>
                                                                    <td colSpan={3}>{convertCurrency(product.averagePrice)} {defaultCurrency()}</td>
                                                                </tr>
                                                                :null
                                                            ))}
                                                        </table>
                                                    </div>
                                                </>
                                            )}
                                        </>

                                    ) : (
                                        detail?.products.filter((product: any) => product.saleCount != 0).length == 0 ? (
                                            <Typography sx={{ textAlign: "center", marginTop: "0.5vw" }}>{this.props.t("categoryReport1")} {detail?.categoryTitle} {this.props.t("categoryReport2")}</Typography>
                                        ) : (
                                            <>
                                                <Typography sx={{ textAlign: "center", marginTop: "0.5vw" }}>{new Date(this.state.startdate).toLocaleDateString()} - {new Date(this.state.enddate).toLocaleDateString()} {this.props.t("categoryReport11")} {detail?.categoryTitle} {this.props.t("categoryReport12")}</Typography>
                                                <div style={{ overflowX: "auto", height: "40vh" }}>
                                                    <table style={{ margin: "auto", marginTop: "1.3vw", overflow: "auto" }}>
                                                        <tr className="caption">
                                                            <td colSpan={2}>{this.props.t('product-name')}</td>
                                                            <td colSpan={2}>{this.props.t('sale-count')}</td>
                                                            <td colSpan={2}>{this.props.t('sale-percent')}</td>
                                                            <td colSpan={4}>{this.props.t('total-income')}</td>
                                                            <td colSpan={2}>{this.props.t('price-percent')}</td>
                                                            <td colSpan={3}>{this.props.t('average-income')}</td>
                                                        </tr>
                                                        {detail?.products.map((product: any) => (
                                                            product.saleCount > 0.0 ?
                                                            <tr>
                                                                <td colSpan={2}>{product.name}</td>
                                                                <td colSpan={2}>{convertCurrency(product.saleCount)}</td>
                                                                <td colSpan={2}>%{convertCurrency(((product.saleCount * 100) / detail?.saleCount))}</td>
                                                                <td colSpan={4}>{convertCurrency(product.totalPrice)} {defaultCurrency()}</td>
                                                                <td colSpan={2}>%{convertCurrency((product.totalPrice * 100) / detail?.totalPrice)}</td>
                                                                <td colSpan={3}>{convertCurrency(product.averagePrice)} {defaultCurrency()}</td>
                                                            </tr>
                                                            :null
                                                        ))}
                                                    </table>
                                                </div>
                                            </>
                                        )
                                    )}

                                    <div style={{
                                        position: "absolute",
                                        bottom: 5,
                                        left: 10
                                    }}>
                                        <Stack direction="row" spacing={2}>
                                            <Button variant="contained" sx={{
                                                backgroundColor: COLORS[detail?.index % COLORS.length],
                                                color: "black"
                                            }} onClick={() => {
                                                switch (comparisonMode) {
                                                    case 1:
                                                        setComparsionMode(2);
                                                        break;
                                                    case 2:
                                                        setComparsionMode(3);
                                                        break;
                                                    case 3:
                                                        setComparsionMode(1);
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }}>{comparisonMode == 1 ? this.props.t('comparing') : comparisonMode == 2 ? this.props.t('advanced-comparing') : this.props.t('exit-comparing-mode')}</Button>
                                            {comparisonMode == 1 ? null : (
                                                <Button variant="contained" color="success" onClick={async () => {
                                                    let data = await handleCompare(detail?.id, firstDate, secondDate, branch.id);
                                                    setData(data);
                                                }}>
                                                    {this.props.t('compare')}
                                                </Button>
                                            )}
                                        </Stack>
                                    </div>
                                </div>
                                {comparisonMode == 1 ? null : (
                                    <div className={`ml-8 translate-x-1/2 transition-all duration-300 ease flex flex-col absolute bg-white rounded-xl w-full h-full z-50`}>
                                        <Typography variant="h6" sx={{ margin: "1.1vw", padding: "0.3vw", borderRadius: "6px", backgroundColor: COLORS[detail.index % COLORS.length], textAlign: "center" }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                                {comparisonMode == 2 ? (
                                                    <Stack direction="row" spacing={2}>
                                                        <DateTimePicker
                                                            label={this.props.t('report-date')}
                                                            inputFormat="DD/MM/YYYY HH:mm"
                                                            value={secondDate.startdate}
                                                            ampm={false}
                                                            onChange={async (value: any) => {
                                                                await setSecondDate({
                                                                    startdate: value,
                                                                    enddate: secondDate.enddate
                                                                });
                                                            }}
                                                            renderInput={(params: any) => <TextField sx={{ width: "10vw" }} {...params} />}

                                                        />
                                                        <span>
                                                            {detail?.categoryTitle}
                                                        </span>
                                                        <div></div>
                                                    </Stack>
                                                ) : comparisonMode == 3 ? (
                                                    <Stack direction="row" spacing={2}>
                                                        <DateTimePicker
                                                            label={this.props.t('start-date')}
                                                            value={secondDate.startdate}
                                                            ampm={false}
                                                            onChange={async (value: any) => {
                                                                await setSecondDate({
                                                                    startdate: value,
                                                                    enddate: secondDate.enddate
                                                                });
                                                            }}
                                                            renderInput={(params: any) => <TextField sx={{ width: "10vw" }} {...params} />}
                                                        />
                                                        <span>
                                                            {detail?.categoryTitle}
                                                        </span>
                                                        <DateTimePicker
                                                            label={this.props.t('end-date')}
                                                            value={secondDate.enddate}
                                                            ampm={false}
                                                            onChange={async (value: any) => {
                                                                await setSecondDate({
                                                                    startdate: secondDate.startdate,
                                                                    enddate: value
                                                                });
                                                            }}
                                                            renderInput={(params: any) => <TextField sx={{ width: "10vw" }} {...params} />}

                                                        />
                                                    </Stack>
                                                ) : (
                                                    <span>{detail?.categoryTitle}</span>
                                                )}

                                            </LocalizationProvider>
                                        </Typography>
                                        {!data?.second ? (
                                            <Typography sx={{ textAlign: "center", fontSize: "1.1vw" }}>{this.props.t('please-click-compare-button')}</Typography>
                                        ) : data?.second?.totalSale == 0 ? (
                                            comparisonMode == 2 ? (
                                                <Typography sx={{ textAlign: "center", fontSize: "1.1vw" }}>{new Date(data?.second?.from).toLocaleDateString()} - {detail?.categoryTitle}: {this.props.t('no-sale-this-date')}</Typography>

                                            ) : (
                                                <Typography sx={{ textAlign: "center", fontSize: "1.1vw" }}>{new Date(data?.second?.from).toLocaleString()} - {new Date(data?.second?.to).toLocaleString()} - {detail?.categoryTitle}: {this.props.t('no-sale-this-date')}</Typography>

                                            )
                                        ) : (
                                            <>
                                                {comparisonMode == 2 ? (
                                                    <Typography sx={{ textAlign: "center", fontSize: "1.1vw" }}>{new Date(data?.second?.from).toLocaleDateString()} - {detail?.categoryTitle}</Typography>

                                                ) : (
                                                    <Typography sx={{ textAlign: "center", fontSize: "1.1vw" }}>{new Date(data?.second?.from).toLocaleString()} - {new Date(data?.second?.to).toLocaleString()} - {detail?.categoryTitle}</Typography>

                                                )}
                                                <div style={{ overflowX: "auto", height: "40vh" }}>
                                                    <table style={{ margin: "auto", marginTop: "1.3vw", overflow: "auto" }}>
                                                        <tr className="caption">
                                                            <td colSpan={2}>{this.props.t('product-name')}</td>
                                                            <td colSpan={2}>{this.props.t('sale-count')}</td>
                                                            <td colSpan={2}>{this.props.t('sale-percent')}</td>
                                                            <td colSpan={4}>{this.props.t('total-income')}</td>
                                                            <td colSpan={2}>{this.props.t('price-percent')}</td>
                                                            <td colSpan={3}>{this.props.t('average-income')}</td>
                                                        </tr>
                                                        {data?.second?.result.map((product: any) => (
                                                             product.saleCount > 0.0 ?
                                                             <tr>
                                                                 <td colSpan={2}>{product.name}</td>
                                                                 <td colSpan={2}>{convertCurrency(product.saleCount)}</td>
                                                                 <td colSpan={2}>%{convertCurrency(((product.saleCount * 100) / detail?.saleCount))}</td>
                                                                 <td colSpan={4}>{convertCurrency(product.totalPrice)} {defaultCurrency()}</td>
                                                                 <td colSpan={2}>%{convertCurrency(((product.totalPrice * 100) / detail?.totalPrice))}</td>
                                                                 <td colSpan={3}>{convertCurrency(product.averagePrice)} {defaultCurrency()}</td>
                                                             </tr>
                                                             :null
                                                        ))}
                                                    </table>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}

                            </div>
                        </div>
                    </Transition>
                </React.Fragment >

            )
        }
        
        var grid =  () => {
            return <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 4, md: 5 }} sx={{ marginTop: "2vw" }}>

            {data.reports.map((report: any, index: number) => (
                report.saleCount>0?
                <Grid item onClick={async () => {
                    await setDetail({ ...report, index: index });
                    setComparsionMode(1);
                }} xs={3}>
                    <Paper className="card" component={Stack} direction="column" justifyContent="center" elevation={2} sx={{ backgroundColor: COLORS[index % COLORS.length], height: "18vh" }}>
                        <div className="layer"></div>
                        <Box className="effect" sx={{ display: "flex", flexDirection: "row", zIndex: 2 }}>
                            <Box>
                                <Avatar variant="rounded" src={report.image ? report.image : ""} sx={{ height: "100%", width: "5vw", marginLeft: "0.5vw" }}>{!report.image ? (<CategoryIcon fontSize='large' />) : null}</Avatar>
                            </Box>
                            <Box sx={{ marginLeft: "1.5vw" }}>
                                <Typography sx={{ textAlign: "center", fontWeight: "bold", fontSize: "1rem" }}>{report.categoryTitle}</Typography>
                                <Typography sx={{ textAlign: "center", fontSize: "0.9rem" }}>{this.props.t('total-income')}: {convertCurrency(report.totalPrice)} {defaultCurrency()}</Typography>
                                <Typography sx={{ textAlign: "center", fontSize: "0.9rem" }}>{this.props.t('price-percent')}: %{convertCurrency(report.pricePercent)}</Typography>
                                <Typography sx={{ textAlign: "center", fontSize: "0.9rem" }}>{this.props.t('total-sold')}: {convertCurrency(report.saleCount)}</Typography>
                                <Typography sx={{ textAlign: "center", fontSize: "0.9rem" }}>{this.props.t('sale-percent')}: %{convertCurrency(report.salePercent)}</Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>:<div></div>
            ))}
            </Grid>
        }
        return (
            <Box sx={{ marginBottom: "5vw", marginLeft:5, marginRight:5 }}>
                {
                    comparisonMode != 0 ? (
                        <ComparisonDialog {...data} />
                    ) : null
                }
                 {this.state.subBranches.length > 0 ? <>
                < Typography component="div" sx={{ cursor: "pointer" }} onClick={() => setExpanded(!expanded)}>
                <Box sx={{ textAlign: "center", backgroundColor: "#ededed", borderRadius: "10px 10px 0 0", color: "#000", fontWeight: "bold", fontSize: "1.23vw" }}>{data.branchTitle} {expanded ? (<KeyboardArrowUpIcon fontSize='large' />) : (<KeyboardArrowDownIcon fontSize='large' />)}</Box>
                </Typography >
                <Collapse in={expanded}>
                    {grid()}
                </Collapse></>:<>{grid()}</>}
            </Box >
        )
    }

    exportReports = () => {
        if (this.state.reportData?.length == 0) {
            toast.warning(this.props.t('no-ready-report'))
            return;
        }
        const filteredData = [];
        for (const item of this.state.reportData) {
          if (this.state.selectedBranches.includes(item.id)) {
            filteredData.push(item);
          }
        }
        parseCategoryReport(filteredData);
    }


    render() {
        if (this.state.loading) {
            return (
                <>
                    <Loading />
                </>
            )
        }
        
        return (
            <React.Fragment>
                <Box sx={{ overflow: "auto" }}>
                    <this.BranchDialog />
                    <Box>
                        <Stack direction="row" spacing={2} alignItems="center" sx={{ marginTop: "2vw" }}>
                            <Box sx={{
                                marginLeft: "0.5vw"
                            }}>
                                <InputLabel id="chartTypeLabel">{this.props.t("chart-type")}</InputLabel>
                                <Select value={this.state.chartType} onChange={async (e: any) => {
                                    await this.setState({
                                        chartType: e.target.value
                                    });
                                    this.syncData();
                                }} labelId='chartTypeLabel'
                                    sx={{
                                        minWidth: "9vw",
                                        textAlign: "center"
                                    }}
                                >
                                    {
                                        [this.props.t('general'), this.props.t('table-chart'), this.props.t('line-chart'), this.props.t('pie-chart'), this.props.t('bar-chart')].map((type: string) => (
                                            <MenuItem key={type} value={type}>{type}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Box>
                            <Grid container justifyContent="left">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Stack direction="row" spacing={2} sx={{marginTop:3}}>
                                        <DateTimePicker
                                            label={this.props.t('start-date')}
                                            inputFormat="DD/MM/YYYY HH:mm"
                                            value={this.state.startdate}
                                            ampm={false}
                                            onChange={async (value: any) => {
                                                await this.setState({
                                                    startdate: value
                                                });
                                            }}
                                            renderInput={(params: any) => <TextField {...params} />}
                                        />
                                        <DateTimePicker
                                            label={this.props.t('end-date')}
                                            inputFormat="DD/MM/YYYY HH:mm"
                                            value={this.state.enddate}
                                            ampm={false}
                                            onChange={async (value: any) => {
                                                await this.setState({
                                                    enddate: value
                                                });
                                            }}
                                            renderInput={(params: any) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                            <Grid container justifyContent="right">
                                <ButtonGroup orientation='vertical'>
                                    {this.state.chartType == this.props.t('line-chart') || this.state.subBranches.length < 1 ? null : (
                                        <ThemeProvider theme={theme}>
                                            <SubbranchButton sx={{
                                                "&:hover": {
                                                    color: theme.default.primary,
                                                    backgroundColor: theme.default.secondary
                                                }
                                            }} variant="contained" onClick={() => this.setState({ branchDialog: true })}>{this.props.t('selected-branches')} ({this.state.selectedBranches.length})</SubbranchButton>
                                        </ThemeProvider>
                                    )}
                                    <Button variant="contained" color="inherit" onClick={() => {
                                        this.syncData();
                                    }}>{this.props.t('get-reports')}
                                    </Button>
                                    {this.state.chartType == this.props.t('line-chart') ? null : (
                                        <Button variant="contained" startIcon={<FileDownloadIcon />} onClick={() => this.exportReports()} sx={{ backgroundColor: "#717D8C" }}>{this.props.t('export-reports')}</Button>
                                    )}
                                </ButtonGroup>
                            </Grid>
                        </Stack>
                    </Box>

                    <Box className="charts" sx={{ marginTop: "2vw" }}>
                        {this.state.chartType == this.props.t('general') ? (
                            <>
                                {this.state.reportData?.length == 0 ? (
                                    <>
                                        <NoReport />
                                    </>
                                ) : (
                                    <Box>
                                        {this.state.reportData?.filter((report: any) => this.state.selectedBranches.includes(report.id)).map((report: any) => (
                                            <this.GeneralReport {...report} />
                                        ))}
                                    </Box>
                                )}
                            </>

                        ) : this.state.chartType == this.props.t('table-chart') ? (
                            <>
                                {this.state.reportData?.length == 0 ? (
                                    <>
                                        <NoReport />
                                    </>
                                ) : (
                                    <Box>
                                        {this.state.reportData?.filter((report: any) => this.state.selectedBranches.includes(report.id)).map((report: any) => (
                                            <this.TableReport {...report} />
                                        ))}
                                    </Box>
                                )}
                            </>
                        ) : this.state.chartType == this.props.t('pie-chart') ? (
                            <>
                                {this.state.reportData?.length == 0 ? (
                                    <>
                                        <NoReport />
                                    </>
                                ) : (
                                    <Box>
                                        {this.state.reportData?.filter((report: any) => this.state.selectedBranches.includes(report.id)).map((report: any) => (
                                            <this.PieReport {...report} />
                                        ))}
                                    </Box>
                                )}
                            </>

                        ) : this.state.chartType == this.props.t('bar-chart') ? (
                            <>
                                {this.state.reportData?.length == 0 ? (
                                    <>
                                        <NoReport />
                                    </>
                                ) : (
                                    <Box>
                                        {this.state.reportData?.filter((report: any) => this.state.selectedBranches.includes(report.id)).map((report: any) => (
                                            <this.BarReport {...report} />
                                        ))}
                                    </Box>
                                )}
                            </>
                        ) : this.state.chartType == this.props.t('line-chart') ? (
                            <Box>
                                <Box sx={{ display: "flex", flexDirecton: "row" }}>
                                    <Box sx={{ marginLeft: "1vw" }}>
                                        <InputLabel id="category">{this.props.t('category')}</InputLabel>
                                        <Select labelId="category" sx={{ minWidth: "8vw", marginRight: "1.3vw" }} value={this.state.selectedCategory} onChange={async (e: any) => {
                                            await this.setState({
                                                selectedCategory: e.target.value
                                            });
                                            this.syncData();
                                        }}>
                                            {this.state.categories.map((category: any) => (
                                                <MenuItem value={category.id}>
                                                    {category.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                    <Box>
                                        <InputLabel id="per">{this.props.t('time-interval')}</InputLabel>
                                        <Select labelId="per" value={this.state.per} onChange={async (e: any) => {
                                            await this.setState({
                                                per: e.target.value
                                            });
                                            this.syncData();
                                        }}>
                                           {[{ name: this.props.t('daily'), value: 86400000 }, { name: this.props.t('3-daily'), value: 259200000 }, { name: this.props.t('weekly'), value: 604800000 }, { name: this.props.t('monthly'), value: 2629743000 }, { name: this.props.t('3-monthly'), value: 7889229000 }, { name: this.props.t('6-monthly'), value: 15778458000 }].map((p: any) => (
                                            <MenuItem key={p.value} value={p.value}>{p.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                </Box>
                                <Typography variant="h4" sx={{ fontWeight: "bold" }}>{this.props.t('sale-count')}</Typography>
                                <Box>
                                    <LineChart width={1100} height={600}>
                                        {this.state.lineData.map((data: any, index: number) => {
                                            return (
                                                <>
                                                    <Line data={data.reports} type="monotone" strokeWidth={3} legendType="circle" dataKey="saleCount" name={data.name} stroke={LINE_COLORS[index % LINE_COLORS.length]} />
                                                </>

                                            )
                                        })}
                                        <XAxis allowDuplicatedCategory={false} dataKey="from" name="Tarih" />
                                        <YAxis />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip />
                                        <Legend />
                                    </LineChart>
                                </Box>
                                <Typography variant="h4" sx={{ fontWeight: "bold" }}>{this.props.t('total-price')}</Typography>
                                <Box>
                                    <LineChart width={1100} height={600}>
                                        {this.state.lineData.map((data: any, index: number) => {
                                            return (
                                                <>
                                                    <Line data={data.reports} type="monotone" strokeWidth={3} legendType="circle" dataKey="totalPrice" name={data.name} stroke={LINE_COLORS[index % LINE_COLORS.length]} />
                                                </>

                                            )
                                        })}
                                        <XAxis allowDuplicatedCategory={false} dataKey="from" name="Tarih" />
                                        <YAxis />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip />
                                        <Legend />
                                    </LineChart>
                                </Box>
                                <Typography variant="h4" sx={{ fontWeight: "bold" }}>{this.props.t('average-price')}</Typography>
                                <Box>
                                    <LineChart width={1100} height={600}>
                                        {this.state.lineData.map((data: any, index: number) => {
                                            return (
                                                <>
                                                    <Line data={data.reports} type="monotone" strokeWidth={3} legendType="circle" dataKey="averagePrice" name={data.name} stroke={LINE_COLORS[index % LINE_COLORS.length]} />
                                                </>

                                            )
                                        })}
                                        <XAxis allowDuplicatedCategory={false} dataKey="from" name={this.props.t('date')!} />
                                        <YAxis />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip />
                                        <Legend />
                                    </LineChart>
                                </Box>
                            </Box>
                        ) : (
                            <>{this.props.t('invali-chart-type')}</>
                        )}
                    </Box>
                </Box>
            </React.Fragment>

        )
    }
}

export default withTranslation()(CategoryReport);
