import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  PlusIcon,
} from "@heroicons/react/solid";

import Modal from "components/modal";
import Spinner from "components/spinner";
import Table from "components/table";

import Response from "services/response";
import { useEffect, useState, useContext } from "react";

import UserService from "services/userService";
import Http from "services/http";
import useCache from "services/useCache";
import { useSWRConfig } from "swr";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function ServiceServeDisclosure() {
  const USService = new UserService(useContext(Http.Context)!);
  const UserServices = useCache(USService);
  const { mutate } = useSWRConfig();
  const { t } = useTranslation();

  const [delData, setDelData] = useState<any | null>(null);

  const [modal, setModal] = useState<boolean>(false);
  const Delete = new Response();

  useEffect(() => {
    if (Delete.data && !Delete.error && !Delete.loading) {
      let newData = UserServices.data.filter((d: any) => d._id !== delData._id);
      mutate(USService.endpoint, newData, {
        optimisticData: newData,
        rollbackOnError: true,
      });
      setModal(false);
    } else if (Delete.error && !Delete.loading) {
      toast.error(Delete.error?.response?.data);
      setModal(false);
    }
  }, [Delete.data, Delete.loading]);

  useEffect(() => setModal(Boolean(delData)), [delData]);
  useEffect(
    () => (
      !modal && setTimeout(() => (setDelData(null), (Delete.data = null)), 200),
      undefined
    ),
    [modal]
  );

  return (
    <>
      <Modal
        onClose={() => !Delete.loading && setModal(false)}
        state={[modal, setModal]}
      >
        <>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 p-0.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {delData?.description}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {t('service-delete-question')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-actions">
            <button
              disabled={Delete.data || Delete.loading}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => Delete.handle(USService.delete(delData._id))}
            >
              {Delete.data ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : Delete.loading ? (
                <Spinner className="h-5 m-auto" />
              ) : (
                <span>{t('delete')}</span>
              )}
            </button>
            <button
              disabled={Delete.loading}
              type="button"
              className="mt-3 w-full classic-btn justify-center sm:mt-0 sm:ml-3 sm:w-auto"
              onClick={setModal.bind(null, false)}
            >
              {t('cancel')}
            </button>
          </div>
        </>
      </Modal>
      <Disclosure as="div" className="mt-2">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between items-center w-full px-4 py-2 text-lg font-medium text-left text-black bg-white border-2 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-200 focus-visible:ring-opacity-75">
              <span>{t('service')}</span>
              {open ? (
                <Link
                  to="/settings/action/service"
                  className="space-x-1 flex items-center text-white bg-ebony py-1 px-4 rounded-lg text-sm font-medium ml-auto mr-1"
                >
                  <PlusIcon className="w-4 h-4" />
                  <span>{t('add-service')}</span>
                </Link>
              ) : null}
              {open ? (
                <ChevronDownIcon className="w-5 h-5" />
              ) : (
                <ChevronRightIcon className="w-5 h-5" />
              )}
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-4 pb-2 text-base text-gray-500">
              {UserServices.data && (
                <Table
                  data={{
                    head: [
                      {
                        element: t('service-description'),
                        props: { scope: "col" },
                      },
                      {
                        element: t('actions'),
                        props: { scope: "col" },
                        screenReader: true,
                      },
                    ],
                    body: UserServices.data.map((service: any) => ({
                      id: service._id,
                      elements: [
                        {
                          element: (
                            <div className="flex font-medium text-sm text-gray-900">
                              {service.description}
                            </div>
                          ),
                        },
                        {
                          element: (
                            <>
                              <Link
                                to={`/settings/action/service/${service._id}`}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                {t('edit')}
                              </Link>
                              <button
                                onClick={() => {
                                  setDelData(service);
                                }}
                                className="text-red-600 hover:text-red-900"
                              >
                                {t('delete')}
                              </button>
                            </>
                          ),
                          props: {
                            className:
                              "text-right text-sm font-medium space-x-6",
                          },
                        },
                      ],
                    })),
                  }}
                />
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
