
import IntegrationList from "components/integrationList";
import { useTranslation } from "react-i18next";
export default function () {
    const { t } = useTranslation();
    

    return (
      <>
        <div className="header-top">
          <h2 className="text-xl font-medium pl-2">
            <span>{t('can-add-integrations')}</span>
          </h2>
        </div>
        <div id="content" className="p-6 flex flex-col gap-y-4">

					<IntegrationList 
						linkBase="/integration"
            exclude={true} 
						emptyMessage={t('all-integrations-done')!}
					/>

        </div>
       
      </>
    );
}
