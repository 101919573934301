import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, FieldArray, FormikErrors, FormikTouched, insert } from "formik";
import { object, string, number, array, boolean, mixed } from "yup";
import { Listbox } from "@headlessui/react";

import Http from "services/http";
import Option from "services/option";
import Response from "services/response";
import Structures from "services/structures";
import category from "services/product";
import useCache from "services/useCache";
import Language from "services/language";

import Spinner from "components/spinner";
import { toast } from "react-toastify";

import UString from "utilities/string";
import product from "pages/product";
import clone from "clone";
import { useTranslation } from 'react-i18next';

interface IItem {
  id: string;
  item_name: string;
  price: number;
}
export interface Price {
  order_type: number[];
  _id: string;
  price_name: string;
  currency: string;
  amount: number;
  vat_rate: number;
  price: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IProduct {
  _id: string;
  active_list: number[];
  options: any[];
  favorite: boolean;
  opportunity: boolean;
  title: string;
  description: string;
  category: string;
  image: string;
  start_time: string;
  end_time: string;
  stock_code: string;
  sale_type: number;
  prices: Price[];
  branch: string;
  createdAt: Date;
  updatedAt: Date;
  slug: string;
  __v: number;
  id: string;
}

export default function () {
  const Service = new Option(useContext(Http.Context)!);
  const Fetch = new Response();
  const Save = new Response();

  const LService = new Language(useContext(Http.Context)!);
  const LFetch = new Response<any[]>();

  const Form = useRef<any>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [languages, setLanguages] = useState<any>(
    !id
      ? []
      : []
  );
  const [language, setLanguage] = useState<any>();

  const findLanguage = () => {
    let lang = language?.items?.find((item: any) => item != null ? item.itemId == id:null);
    return lang.locale;
  };

  useEffect(() => Save.data, [Save.data]);

  useEffect(() => (id && Fetch.handle(Service.getById(id!)), undefined), [id]);

  useEffect(
    () => (!LFetch.data && LFetch.handle(LService.get()), undefined),
    [LFetch.data]
  );
  useEffect(() => LFetch.data && setLanguage(LFetch.data), [LFetch.data]);
  useEffect(() => id && language && setLanguages(findLanguage()), [language]);

  useEffect(() => {
    if (Save.data && !Save.error) {
      toast.success(`${t('option')} ${id ? t('editing') : t('adding')} ${t('success')}.`);
      navigate("/option");
    }
  }, [Save.data]);


  useEffect(() => {
    if (Save.error) {
      toast.error(
        `${t('option')} ${id ? t('editing') : t('adding')} ${t('failure')}. (${Save.error.message
        })`
      );
    }
  }, [Save.error]);


  const CService = new category(useContext(Http.Context)!);
  const CFetch = useCache(CService);

  const [show, setShow] = useState(false)

  const [selectedItem, setSelectedItem] = useState<IItem>();
  const [items, setItems] = useState<IItem[]>();

  useEffect(() => {
    if (CFetch.data) {
      const itemList = Array<IItem>();
      CFetch.data.forEach((data: IProduct) => {
        const item = {
          id: data.id,
          item_name: data.title,
          price: data.prices[0].price
        }
        itemList.push(item);
      });
      setSelectedItem(itemList[0])
      setItems(itemList)
    }
  }, [CFetch.data])

  return (
    <React.Fragment>
      <div className="header-top">
        <h2 className="text-xl font-medium pl-2">
          {id ? "Opsiyon düzenle" : "Opsiyon ekle"}
        </h2>
      </div>
      <div id="content">
        <Formik
          innerRef={Form}
          enableReinitialize={Fetch.data?.name ? true : false}
          initialValues={{
            name: Fetch.data?.name || String(),
            special_name: Fetch.data?.special_name || String(),
            type:
              Fetch.data?.type ||
              Number(Object.keys(Structures.types.option)[0]),
            choose_limit: Fetch.data?.choose_limit || 1,
            state: Fetch.data?.state || 1, // Unknown
            unlimitedChoice: Fetch.data?.unlimitedChoice || false,
            productBased: Fetch.data?.state == 2 ? true : false,
            languages: [{
              lang: "tr",
              name: "",
              special_name: "",
              items: []
            }],
            items:
              (Fetch.data?.items as Array<IItem>) ||
              Array({
                item_name: String(),
                price: Number(),
              }),
          }}
          validationSchema={object({
            name: string().required(t('required')!),
            special_name: string().required(t('required')!),
            type: number().required(t('required')!),
            choose_limit: number().min(0).required(t('required')!),
            state: number().required(t('required')!),
            unlimitedChoice: boolean(),
            productBased: boolean(),
            languages: array().of(
              object({
                lang: string(),
                name: string(),
                special_name: string(),
                items: array().of(
                  object({
                    item_name: string()
                  })
                ),
              })
            ),
            items: array()
              .of(
                object({
                  item_name: string().required(t('required')!),
                  price: number().min(0),
                }).required()
              )
              .required(),
          })}
          onSubmit={(values) => {
            values.languages = values.languages.concat(languages);
            Save.handle(
              id && Fetch.data
                ? Service.update(id, values)
                : Service.create(values)
            )
          }

          }
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form className="action" onSubmit={handleSubmit}>
              <div className="wrapper">
                <div className="item">
                  <div className="w-full">
                    <label>{t('select-language')}</label>
                    <select className="form-select" defaultValue={values.languages[0].lang} onChange={(e: any) => values.languages[0].lang = e.target.value} style={{ marginBottom: "1vw" }}>
                      {Structures.types.lang?.map((item: any) => (
                        <option value={item.value}>{item.title}</option>
                      ))}
                    </select>
                    <label htmlFor="name">{t('name')}</label>
                    <input
                      type="text"
                      name="name"
                      className={UString.concat_class_name(
                        errors.name && touched.name ? "has-error" : undefined,
                        "my-1"
                      )}
                      onChange={(e: any) => {
                        handleChange(e);
                        values.languages[0].name = e.target.value;
                      }}
                      onBlur={handleBlur}
                      placeholder="coffee"
                      value={values.name}
                    />
                    {errors.name && touched.name ? (
                      <span className="error">* {errors.name}</span>
                    ) : null}
                  </div>
                  <div className="w-full">
                    <label htmlFor="special_name">{t('special-name')}</label>
                    <input
                      type="text"
                      name="special_name"
                      className={UString.concat_class_name(
                        errors.special_name && touched.special_name
                          ? "has-error"
                          : undefined,
                        "my-1"
                      )}
                      onChange={(e: any) => {
                        handleChange(e);
                        values.languages[0].special_name = e.target.value;
                      }}
                      onBlur={handleBlur}
                      placeholder={t('option-place-holder')!}
                      value={values.special_name}
                    />
                    {errors.special_name && touched.special_name ? (
                      <span className="error">* {errors.special_name}</span>
                    ) : null}
                  </div>
                  <div className="w-full xl:w-1/2">
                    <label>{t('product-based-check')}</label>
                    <input
                      type={"checkbox"}
                      name="productBased"
                      checked={values.productBased}
                      id="checkbox_xapose"
                      onChange={(e: any) => {
                        handleChange(e);
                        if (show) {
                          setFieldValue("items", [{ item_name: String(), price: Number() } as IItem])
                        }
                        else {
                          setFieldValue("items", [])
                        }
                        if (items) {
                          setSelectedItem(items[0]);
                        }
                        setShow(!show)
                      }
                      }
                    >
                    </input>
                  </div>
                  <div className="w-full xl:w-1/2">
                    <label>{t('unlimited-choice')}</label>
                    <input
                      type={"checkbox"}
                      checked={values.unlimitedChoice}
                      name="unlimitedChoice"
                      id="checkbox_xapose1"
                      onChange={handleChange}
                    >
                    </input>
                  </div>
                  <div className="w-full">
                    {values.unlimitedChoice ? null : (
                      <>
                        <label htmlFor="choose_limit">{t('limit')}</label>
                        <input
                          type="number"
                          name="choose_limit"
                          className={UString.concat_class_name(
                            errors.choose_limit && touched.choose_limit
                              ? "has-error"
                              : undefined,
                            "my-1"
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.choose_limit}
                        />
                        {errors.choose_limit && touched.choose_limit ? (
                          <span className="error">* {errors.choose_limit}</span>
                        ) : null}
                      </>

                    )}

                  </div>
                  <button
                    className="w-full xl:ml-auto classic-btn justify-center"
                    type="button" onClick={() => {
                      setLanguages(languages.concat({
                        lang: "en",
                        special_name: values.special_name ? values.special_name : "",
                        name: values.name ? values.name : "",
                        items: clone(values.items),
                      }))

                    }} >{t('add-lang')}</button>
                  <div style={{ marginTop: "1.5vw" }}>
                    {languages?.map((language: any, index: number) => {
                      return (
                        <div style={{ marginBottom: "1.5vw", display: "flex", flexDirection: "row" }}>
                          <div>
                            <div>
                              <label>{t('select-language')}</label>
                              <select className="form-select" value={languages[index].lang} onChange={(e: any) => {
                                let arr = [...languages];
                                arr[index].lang = e.target.value;
                                setLanguages(arr);
                              }}>
                                {Structures.types.lang.map((lang: any) => (
                                  <option value={lang.value}>{lang.title}</option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label>{t('name')}</label>
                              <input type="text" defaultValue={language.name || values.name} onChange={(e: any) => {
                                let arr = [...languages];
                                arr[index].name = e.target.value;
                                setLanguages(arr);
                              }} />
                            </div>
                            <div>
                              <label>{t('special-name')}</label>
                              <input type="text" defaultValue={language.special_name || values.special_name} onChange={(e: any) => {
                                let arr = [...languages];
                                arr[index].special_name = e.target.value;
                                setLanguages(arr);
                              }} />
                            </div>
                          </div>
                          <div style={{ display: "flex", flexDirection: "column", marginLeft: "2.3vw" }}>
                            {language?.items?.map((item: any, itemIndex: number) => {
                              return (
                                <>
                                  <label>İsim</label>
                                  <input defaultValue={languages[index].items[itemIndex]?.item_name} type="text" onChange={(e: any) => {
                                    let arr = [...languages];
                                    languages[index].items[itemIndex].item_name = e.target.value;
                                    setLanguages(arr);
                                  }} />
                                </>
                              )
                            })}
                          </div>
                          <div style={{ marginLeft: "1vw" }}>
                            <button
                              className="w-full xl:ml-auto classic-btn justify-center"
                              type="button" onClick={() => {
                                let arr = [...languages];
                                arr.splice(index, 1);
                                setLanguages(arr);
                              }}>
                              Dili Sil
                            </button>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="item list">
                  <FieldArray
                    name="items"
                    render={({ insert, remove }) => (
                      <>
                        <div className="flex flex-col space-y-4 divide-y divide-gray-200 xl:divide-y-0">
                          {values.items.map((item, index) => {
                            const touch = touched.items?.[index] as
                              | FormikTouched<IItem>
                              | undefined;
                            const error = errors.items?.[index] as
                              | FormikErrors<IItem>
                              | undefined;

                            return (
                              <div
                                className="flex flex-col xl:flex-row gap-4 w-full py-4 xl:py-0"
                                key={index}
                              >
                                <div className="w-full xl:w-1/2">
                                  <label htmlFor={`items.[${index}].item_name`}>
                                    {t('name')}
                                  </label>
                                  <input
                                    type="text"
                                    name={`items.[${index}].item_name`}
                                    className={UString.concat_class_name(
                                      error?.item_name && touch?.item_name
                                        ? "has-error"
                                        : undefined,
                                      "mt-1"
                                    )}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Örn: Evet ya da Hayır"
                                    value={item.item_name}
                                  />
                                  {error?.item_name && touch?.item_name ? (
                                    <span className="error">
                                      * {error?.item_name}
                                    </span>
                                  ) : null}
                                </div>
                                <div className="w-full xl:w-1/2">
                                  <label htmlFor={`items.[${index}].price`}>
                                    {t('price')}
                                  </label>
                                  <input
                                    type="number"
                                    name={`items.[${index}].price`}
                                    className={UString.concat_class_name(
                                      error?.price && touch?.price
                                        ? "has-error"
                                        : undefined,
                                      "mt-1"
                                    )}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={item.price}
                                  />
                                  {error?.price && touch?.price ? (
                                    <span className="error">
                                      * {error?.price}
                                    </span>
                                  ) : null}
                                </div>
                                <div className="xl:mt-6">
                                  <button
                                    className="classic-btn red w-full xl:w-auto"
                                    type="button"
                                    onClick={() =>
                                      values.items.length > 1 && remove(index)
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-5 w-5"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {show ? <div className="relative w-full">
                          <label>{t('products')}</label>
                          {items ? (
                            <Listbox
                              value={selectedItem}
                              onChange={(value) => setSelectedItem(value)}
                            >
                              <div className="relative mt-1">
                                <Listbox.Button
                                  className={UString.concat_class_name(
                                    "listbox-btn"
                                  )}
                                >
                                  <span className="listbox-title">
                                    {selectedItem ? selectedItem.item_name : "Ürünler"}
                                  </span>
                                  <span className="listbox-selector-icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="w-5 h-5 text-gray-400"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                </Listbox.Button>
                                <Listbox.Options className="listbox-options">

                                  {items &&
                                    items.map((product: any) => (
                                      <Listbox.Option
                                        className={UString.concat_class_name(
                                          product === product.id
                                            ? "bg-ebony texy-white"
                                            : "hover:bg-ebony hover:text-white",
                                          "flex items-center cursor-pointer px-6 py-2"
                                        )}
                                        key={product.id}
                                        value={product}
                                      >
                                        <span>{product.item_name}</span>
                                      </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                              </div>
                            </Listbox>
                          ) :
                            (
                              <div className="classic-btn w-full min-h-[38px] mt-1">
                                <Spinner className="w-5 m-auto" />
                              </div>
                            )}
                        </div> : null}
                        {<div className="w-full pt-4 border-t border-gray-200">
                          <button
                            className="w-full xl:ml-auto classic-btn justify-center"
                            type="button"
                            name="eklebutonuiste"
                            onClick={insert.bind(
                              null,
                              values.items.length + 1,
                              show && selectedItem ? selectedItem : { item_name: String(), price: Number() } as IItem,
                              () => {
                                let arr = [...languages];
                                arr.map((lang: any) => {
                                  lang.items.push({
                                    item_name: String()
                                  });
                                });
                                setLanguages(arr);
                              }
                            )}
                          >
                            Ekle
                          </button>
                        </div>}
                      </>
                    )}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className="header-bottom">
        <button
          type="submit"
          disabled={Save.loading}
          className="ml-auto classic-btn"
          onClick={() => {
            if (!Fetch.data?.name) {
              let error = false;
              languages?.map(async (lang: any) => {
                if (lang.name?.length == 0 || lang.special_name?.length == 0) {
                  error = true;
                  return;
                }
                await lang.items.map((item: any) => {
                  if (item.item_name.length == 0) {
                    error = true;
                    return;
                  }
                });
              });
              if (error) {
                toast.warning(t('please-fill-lang-fields'));
                return;
              }
            }

            Form.current.handleSubmit();
          }}
        >
          {Save.loading ? <Spinner className="h-5 m-auto" /> : t('save')}
        </button>
      </div>
    </React.Fragment>
  );
}
function setValue(value: any) {
  throw new Error("Function not implemented.");
}

