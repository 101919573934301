import HttpClient from "api";
import { AxiosRequestConfig } from "axios";

const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(
  process.env.REACT_APP_REFRESH_TOKEN_NAME!
);

class BranchService extends HttpClient {
  public constructor() {
    super(
      `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1/manager`
    );

    this._initializeRequestInterceptor();
  }

  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this._handleRequest,
      this._handleError
    );
  };

  private _handleRequest = (config: AxiosRequestConfig) => {
    config.headers = {
      Authorization: `Bearer ${token}`,
      "x-refresh": refreshToken!,
    };

    return config;
  };

  public getCurrencySettings = () => {
    return this.instance.get("/currency");
  };

  public getBranch = () => {
    return this.instance.get("/branches");
  };

  public getHuginDevices = () => {
    return this.instance.get("/hugin/get-devices");
  };

  public addHuginDevice = (okc_id: string) => {
    return this.instance.post("/hugin/add-device", { okc_id });
  };

  public deleteHuginDevice = (okc_id: string) => {
    return this.instance.delete(`/hugin/delete-device/${okc_id}`);
  };
}

export default BranchService;
