import React, { useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { object, string } from "yup";

import Http from "services/http";
import Section from "services/section";
import Response from "services/response";

import Spinner from "components/spinner";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import UString from "utilities/string";

export default function () {
  const Service = new Section(useContext(Http.Context)!);
  const Fetch = new Response();
  const Save = new Response();

  const Form = useRef<any>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => (id && Fetch.handle(Service.getById(id!)), undefined), [id]);

  useEffect(() => {
    if (Save.data && !Save.error) {
      toast.success(`${t('section')} ${id ? t('editing') : t('adding')} ${t('success')}.`);
      navigate("/section");
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      toast.error(
        `${t('section')} ${id ? t('editing') : t('adding')} ${t('failure')}. (${
          Save.error.message
        })`
      );
    }
  }, [Save.error]);

  return (
    <React.Fragment>
      <div className="header-top">
        <h2 className="text-xl font-medium pl-2">
          {t('section')} {id ? t('edit') : t('add')}
        </h2>
      </div>
      <div id="content">
        <Formik
          innerRef={Form}
          enableReinitialize={true}
          initialValues={{
            title: Fetch.data?.title || String(),
          }}
          validationSchema={object({
            title: string().required(t('required')!),
          })}
          onSubmit={(values) =>
            Save.handle(
              id && Fetch.data
                ? Service.update(id, values)
                : Service.create(values)
            )
          }
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form className="action" onSubmit={handleSubmit}>
              <div className="wrapper">
                <div className="item">
                  <div className="w-full">
                    <label htmlFor="title">{t('title')}</label>
                    <input
                      type="text"
                      name="title"
                      className={UString.concat_class_name(
                        errors.title && touched.title ? "has-error" : undefined,
                        "my-1"
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t('section-placeholder')!}
                      value={values.title}
                    />
                    {errors.title && touched.title ? (
                      <span className="error">* {errors.title}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className="header-bottom">
        <button
          type="submit"
          disabled={Save.loading}
          className="ml-auto classic-btn"
          onClick={() => Form.current.handleSubmit()}
        >
          {Save.loading ? <Spinner className="h-5 m-auto" /> : t('save')}
        </button>
      </div>
    </React.Fragment>
  );
}
