export interface IElement {
    element: React.ReactNode,
    props?: React.ThHTMLAttributes<HTMLTableCellElement>
}

export interface IHead extends IElement {
    screenReader?: boolean
}

export interface IBody {
    id: React.Key | null | undefined,
    elements: IElement[]
}

export interface ITable {
    head: IHead[],
    body: IBody[]
}

export default function ({ data }: { data: ITable }) {
    return <table className="table">
        <thead>
            <tr>
                {
                    data.head.map((head, index) =>
                        <th {...head.props} key={index}>
                            {head.screenReader ? <span className="sr-only">{head.element}</span> : head.element}
                        </th>
                    )
                }
            </tr>
        </thead>
        <tbody>
            {
                data.body?.map(body => (
                    <tr key={body.id}>
                        {
                            body.elements.map((element, index) =>
                                <td {...element.props} key={index}>
                                    {element.element}
                                </td>
                            )
                        }
                    </tr>
                ))
            }
        </tbody>
    </table>
}